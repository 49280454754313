import React from 'react';
import { Row, Col, Jumbotron, Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { translate } from 'react-switch-lang';

class Footer extends React.Component {
    constructor(props, context) {
      super(props, context);
      this.state = {
        isLogin:false,
        user:{},
      };
    }

    componentDidMount(){
        var islogin = JSON.parse(localStorage.getItem('islogin'));
        // console.log(islogin);
        if(islogin){
          this.setState({ isLogin: true,user:islogin});
        }else{
          this.setState({ isLogin: false,user:{}});
        }
    }

    componentWillReceiveProps(newProp) {
        //console.log(newProp);
        if(newProp.isLogOut){
            this.setState({ isLogin: false,user:{}});
        }
    }

    render() {
        const { t } = this.props;
        const url = 'https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2FContentCenter%2F&tabs&width=340&height=130&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=false&appId=526701520751932';
        return (
            <>
            <Row className="footer bg-light border-top">
                <Col>
                    <Container>
                        <Row className="mt-4">
                            <Jumbotron style={{width:"100%", backgroundImage:"url("+require("../img/downloadbg@2x.png")+")"}}>
                                <Container>
                                    <Row>
                                        <Col xs={12} className="text-center">
                                            <h1 style={{color:"white"}} id="dl-chn-head">{t("footer.app_download")}</h1>
                                            <a href="https://contentcenter.obec.go.th/download/" target="_blank">
                                                <img className="img-fluid img-margin-right img-w" src={require("../img/googleplay@2x.png")}/>
                                            </a>
                                            <a href="https://contentcenter.obec.go.th/download/" target="_blank">
                                                <img className="img-fluid img-margin-right img-w" src={require("../img/appstore@2x.png")}/>
                                            </a>
                                            <a href="https://contentcenter.obec.go.th/download/" target="_blank">
                                                <img className="img-fluid img-margin-right img-w" src={require("../img/windowstore@2x.png")}/>
                                            </a>
                                            <a href="http://app.contentcenter.obec.go.th/#/" target="_self">
                                                <img className="img-fluid img-margin-right img-w" src={require("../img/webapp@2x.png")}/>
                                            </a>
                                        </Col>
                                    </Row>
                                </Container>
                            </Jumbotron>

                            <Row className="about-us">
                                
                                <Col lg={5} md={9} sm={12} className="obec-info">
                                    <h3>{t("footer.obec")}</h3>
                                    <Row>
                                        <Col sm={3}><img src={require("../img/footlogo.png")} alt="obec logo" className="img-fluid" /></Col>
                                        <Col sm={9}>
                                        <ul className ="list-group">
                                            <li className="obec-brand">
                                                <div>
                                                    <span>{t("footer.office_name")}</span>
                                                </div>
                                            </li>
                                            <li>{t("footer.address")}</li>
                                            <li>{t("footer.tel")}</li>
                                            <li>{t("footer.email")} : info_contentcenter@obecmail.org</li>
                                        </ul>
                                        </Col>
                                    </Row>
                                </Col>

                                <Col lg={2} md={2} className="hiddenXs site-map" style={{ padding: 0 }}>
                                    <h3 className="mb-3">{t("footer.sitemap")}</h3>
                                    <ul className ="list-group">
                                        <li><Link to="/">{t("header.main")}</Link></li>
                                        <li><Link to="/store/type/1">{t("header.treasury")}</Link></li>
                                        {this.state.isLogin && <>
                                        <li><Link to="/bookshelf">{t("footer.myshelf")}</Link></li>
                                        <li><Link to="/favorite">{t("footer.myfavor")}</Link></li>
                                        </>}
                                        <li><a href="https://contentcenter.obec.go.th/tutorial/" target="_blank">{t("footer.faq")}</a></li>
                                    </ul>

                                </Col>

                                <Col lg={2} md={2} sm={12} className="contact-us" style={{ padding: 0 }}>
                                    <h3 className="mb-3">{t("footer.contact_us")}</h3>
                                    <ul className ="list-group">
                                        <li>
                                            <a href="https://www.facebook.com/ContentCenter/" target="_blank">
                                                <img src={require("../img/fb.png")} height="20" alt="" style={{ display:'inline-block'}} />
                                                <span style={{display:'inline-block'}}>Facebook</span>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="https://twitter.com/CenterObec" target="_blank">
                                                <img src={require("../img/twitter.png")} height="20" alt="" style={{ display:'inline-block'}} />
                                                <span style={{display:'inline-block'}}>Twitter</span>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="https://line.me/R/ti/p/%40039gfzwn" target="_blank">
                                                <img src={require("../img/line.png")} height="20" alt="" style={{display:'inline-block'}} />
                                                <span style={{display:'inline-block'}}>Line</span>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="https://www.youtube.com/channel/UCwyOaOqjxCbb9Eoyqp5t_Eg/featured?view_as=subscriber" target="_blank">
                                                <img src={require("../img/youtube.png")} height="20" alt="" style={{ display:'inline-block'}} />
                                                <span style={{display:'inline-block'}}>Youtube</span>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="https://contentcenter.obec.go.th/" target="_blank">
                                                <img src={require("../img/obeccc.png")} height="20" alt="" style={{ display:'inline-block'}} />
                                                <span style={{display:'inline-block'}}>Website</span>
                                            </a>
                                        </li>
                                    </ul>

                                </Col>

                                <Col lg={3} md={3} sm={12} className="facebook-fanpage" style={{ padding: 0 }}>
                                    <h3 className="mb-3">เฟสบุ๊คแฟนเพจ</h3>
                                    <iframe src={url} 
                                    width="100%" height="154" style={{border:"none",overflow:"hidden"}} 
                                    scrolling="no" frameBorder="0" allowtransparency="true" allow="encrypted-media">
                                        
                                    </iframe>
                                </Col>    
                                    
                            </Row>
                        </Row>
                    </Container>

                    <Row className="copyright">
                        <Col xs={12} className="text-center p-2">{t("footer.copyright")} {t("footer.version")}</Col>
                    </Row>
                </Col>
            </Row>
            </>
        );
    }
}

export default translate(Footer);