import React from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import Books from './books';
import axios from 'axios';
import Menu from '../../component/menu';
import MetaTags from 'react-meta-tags';

class Store extends React.Component {
  constructor(props, context) {
    super(props, context);

    // this.handleShow = this.handleShow.bind(this);
    // this.handleClose = this.handleClose.bind(this);
    // console.log(props.hits);
    // console.log(props.location.hashtag);

    this.state = {
      show: false,
      hit: props.hits,
      hitText: props.location.hashtag,
      tabCheck: props.tabCheck,
      isLogin:false,
      user:{},
      ctype: '',
      tagId: props.match.params.handle
    };
  }

  componentDidMount(){

    setTimeout(() => {
      window.scrollTo({ top: 0});
    }, 100);
    
    var islogin = JSON.parse(localStorage.getItem('islogin'));
    // var token = "1000000000"
    if(islogin){
        this.setState({ isLogin: true,user:islogin});
        // token = islogin.token;
    }else{
        this.setState({ isLogin: false,user:{}});
    }

    if(this.state.tabCheck){
      this.setState({ctype: this.props.match.params.handle})
    }else{
      this.setState({ctype: 1})
    }

    if(this.state.hit){
      this.getHashtag();
    }
  }

componentWillReceiveProps(newProps){

  // console.log("this.state.hit : " + this.state.hit);
    // console.log("this.state.hitText : " + this.state.hitText);

    // setTimeout(() => {
    //   window.scrollTo({ top: 0});
    // }, 500);
    // console.log(newProps, this.props );
    if(newProps.tabCheck){
      this.setState({ctype: newProps.match.params.handle})
    }else{
      this.setState({ctype: 1})
    }

    if(newProps.hit){
      // console.log("new props hits");
      this.setState({hitText:newProps.location.hashtag, hit:newProps.hit, tagId:newProps.match.params.handle})
      this.getHashtag();
    }else{
      // console.log("empty props hits");
      // this.setState({hitText:'', hit:'', tagId:''})
    }
    if(newProps.searchWord !== '' && newProps.searchWord !== this.props.searchWord){
      this.props.history.push('/search', newProps.searchWord);
    }
}

  // handleClose() {
  //   this.setState({ show: false });
  // }

  // handleShow() {
  //   this.setState({ show: true });
  // }

  setCType(i){
    if(i !== this.state.ctype){
      this.setState({ctype:i})
      // window.location.href = '/store/tab/'+i;
    }
  }

  getHashtag(){
    const api = axios.create({
      baseURL: this.props.mainpoint,
      timeout: 85000
    });
    api.get('/api/v1/hashtags/'+this.state.tagId).then(
        success => {
            const { status, data } = success
            if (status === 201 || status === 200) {
                // console.log(data);
                if(data.result.length > 0){
                  this.setState({hitText: data.result[0].name});
                }
            } else {
                console.error('Error',status)
            }
        },
        error => {
            this.setState({ loading: false });
            console.error('Error',error)
        }
    );
  }

  render() {
  return (
    <>
    <MetaTags>
          <title>{'OBEC Content Center'}</title>
    </MetaTags>

    <Row>
        {this.state.hit && 
        <Col xs={12} style={{padding:0}}>
          <img src={require("../../img/headerStore.png")} alt="" width="100%"/>
          <label style={{color: '#ffffff',fontSize: '5vmax',fontWeight:'bold',textAlign:'center', 
            position: 'absolute',top: '25%',left: 0,right: 0}}>
            {this.state.hitText}
          </label>
        </Col>
        }
    </Row>

    <Row className="bg-light mt-1">
      <Col>
        <Container>
          <Row className="my-3">
            <Col xs={12} className="text-center mt-3"><h1>ประเภทเนื้อหาทั้งหมด</h1></Col>
            <Col xs={12}>
              <Menu {...this.props} ct={this.state.ctype}/>
            </Col>
          </Row>
          <Row className="mt-3">
            <Col xs={12}>
              <Row className="px-2">
                <Col lg md xs={3} className="text-center px-1">
                  {parseInt(this.state.ctype) === 1 && <div className="contentActiveBar"></div>}
                </Col>
                <Col lg md xs={3} className="text-center px-1">
                  {parseInt(this.state.ctype) === 3 && <div className="contentActiveBar"></div>}
                </Col>
                <Col lg md xs={3} className="text-center px-1">
                  {parseInt(this.state.ctype) === 4 && <div className="contentActiveBar"></div>}
                </Col>
                <Col lg md xs={3} className="text-center px-1">
                  {parseInt(this.state.ctype) === 5 && <div className="contentActiveBar"></div>}
                </Col>
                <Col lg md xs={3} className="text-center px-1">
                  {parseInt(this.state.ctype) === 2 && <div className="contentActiveBar"></div>}
                </Col>
                <Col lg md xs={3} className="text-center px-1">
                  {parseInt(this.state.ctype) === 8 && <div className="contentActiveBar"></div>}
                </Col>
                <Col lg md xs={3} className="text-center px-1">
                  {parseInt(this.state.ctype) === 11 && <div className="contentActiveBar"></div>}
                </Col>
                <Col lg md xs={3} className="text-center px-1">
                  {parseInt(this.state.ctype) === 6 && <div className="contentActiveBar"></div>}
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </Col>
    </Row>

    <Row>
      <Col>
        <Container>
          <Books ctype={this.state.ctype} {...this.props}/>
        </Container>
      </Col>
    </Row>
    </>
  );
}
}

export default Store;
