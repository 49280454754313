import React from 'react';
import './profile.css';
import { Row,Col, Image, Media, Container } from 'react-bootstrap';
import { FaPowerOff,FaPencilAlt,FaCircle,FaHeart,FaRegClock, FaUserCircle } from 'react-icons/fa';
// import { Chart } from "react-charts";
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer} from 'recharts';
import axios from 'axios';
import Loader from '../component/loader';
import { Link } from 'react-router-dom';
import { Redirect } from 'react-router-dom';
import MetaTags from 'react-meta-tags';

class Profile extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.setLogOut=this.setLogOut.bind(this);
    this.state = {
      user: {},
      userRoleTypes: [],
      userStats: {},
      userRanking: [],
      graphData: [],
      rankingType: 1,
      loading: false,
      redirect: false
    };
  }

  async componentDidMount(){
    let items = JSON.parse(await localStorage.getItem('islogin'));
    if (items === null) {
      this.setState({redirect: true });
      return;
    }
    this.setState({user: items});
    if (items.userRole !== null){
        this.getUserTypes();
        this.getUserStats();
        this.getUserRanking(1);
    }
  }

  async componentWillReceiveProps(nextProps){
    if(nextProps.searchWord.trim() !== '' && nextProps.searchWord !== this.props.searchWord){
      await this.props.history.push('/search', nextProps.searchWord);
    }
  }

  getUserTypes = () => {
    axios.get(this.props.mainpoint+'/api/v1/usertypes').then(
      success => {
        //if (process.env.REACT_APP_ENV_MODE === "development") console.log(success);
        const { status, data } = success
        if (status === 201 || status === 200) {
          //if (process.env.REACT_APP_ENV_MODE === "development") console.log(data);
          let userTypesArray = [];
          data.result.forEach(element => {
            userTypesArray.push({ value: element.uRoleId, label: element.name });
          });
          this.setState({
            userRoleTypes: userTypesArray
          });
        }
      },
      error => {
        if (process.env.REACT_APP_ENV_MODE === "development") console.log(error)
      }
    );
  }

  getUserStats = () => {
    const api = axios.create({
      baseURL: this.props.mainpoint,
      headers: {
        "Content-Type": "application/json",
        "token":this.state.user.token
      },
      timeout: 85000
    });
    api.get('/api/v1/user/stats').then(
      success => {
        // if (process.env.REACT_APP_ENV_MODE === "development") console.log("stats",success);
        const { status, data } = success
        if (status === 201 || status === 200) {
          // if (process.env.REACT_APP_ENV_MODE === "development") console.log("stats",data);
          this.setState({
            userStats: data.result
          });
          this.settingGraph();
        }
      },
      error => {
        if (process.env.REACT_APP_ENV_MODE === "development") console.log(error)
      }
    );
  }

  getUserRanking = (type) => {
    const api = axios.create({
      baseURL: this.props.mainpoint,
      headers: {
        "Content-Type": "application/json",
        "token":this.state.user.token
      },
      timeout: 85000
    });
    api.get(`/api/v1/user/ranking/${type}/5`).then(
      success => {
        //if (process.env.REACT_APP_ENV_MODE === "development") console.log(success);
        this.setState({ loading: false });
        const { status, data } = success
        if (status === 201 || status === 200) {
          // if (process.env.REACT_APP_ENV_MODE === "development") console.log("Ranking",data);
          this.setState({
            userRanking: data.result
          });
        }
      },
      error => {
        if (process.env.REACT_APP_ENV_MODE === "development") console.log(error)
      }
    );
  }

  settingGraph = () => {
    const cType = [
      {id: 1, name: 'หนังสือ', en: 'book'},
      {id: 3, name: 'วีดีโอ', en: 'vdo'},
      {id: 4, name: 'รูปภาพ', en: 'images'},
      {id: 5, name: 'เสียง', en: 'sound'},
      {id: 8, name: 'ข้อสอบ', en: 'test'},
      {id: 2, name: 'แอปพลิเคชัน', en: 'application'},
      {id: 6, name: 'มัลติมีเดีย', en: 'Multimedia'},
      {id: 11, name: 'เทมเพลต', en: 'template'}
    ]
    let graphData = [];
    if (this.state.userStats.topGraphs) {
      cType.forEach(item => {
        let uCnt = this.state.userStats.userGraphs.filter(value => {
          return value.ctypeId === item.id;
        }).map(item => {
          if (item.cnt == null) {
            return 0;
          } else {
            return item.cnt;
          }
        })[0];
        uCnt = (uCnt === undefined)? 0 : uCnt;

        let topCnt = this.state.userStats.topGraphs.filter(value => {
          return value.ctypeId === item.id;
        }).map(item => {
          if (item.cnt == null) {
            return 0;
          } else {
            return item.cnt;
          }
        })[0];
        topCnt = (topCnt === undefined)? 0 : topCnt;
        graphData.push({name: item.name, topGraphs:topCnt, userGraphs:  uCnt});
      })
    }
    this.setState({ graphData: graphData });
  }

  setRoleType = (value) => {
    // return  this.state.userRoleTypes.filter(item => {
    //   return item.value == value;
    // }).map(item => item.label)[0];
    if (this.state.user.userRole) return(this.state.user.userRole.name);
  }

  setUserRanking = () => {
    let i = 1;
    let items = [];
      this.state.userRanking.forEach(item => {
        items.push(
          <Media className={i===this.state.userRanking.length?"":"border-at-bottom"} key={item.uId}>
            {i === 1 ?
              <h3 className="align-self-center text-right" style={{marginLeft:'.5em', marginRight:'.92em', color:'#07b169'}}>{i}</h3>
              :
              <h3 className="align-self-center text-right" style={{marginLeft:'.5em', marginRight:'.7em', color:'#07b169'}}>{i}</h3>
            }
            
            {item.imgUrl? 
              <Image className="align-self-center rounded-circle mr-3" src={item.imgUrl} style={{width:'3.0rem', height: '3.0rem'}}></Image>
              :
              <FaUserCircle style={{width:"3.5rem",height:"3.5rem",paddingRight:".75rem",color:"#ccc"}}/>
            }
            <Media.Body>
              <div>{item.firstname+' '+item.lastname}</div>
              <div>{item.caption}</div>
            </Media.Body>
          </Media>);
          i++;
      });
      return items;
    
  }

  topRank = (e) => {
    this.setState({rankingType: 1, loading: true });
    this.getUserRanking(1);
  }

  randomRank = (e) => {
    this.setState({rankingType: 2, loading: true });
    this.getUserRanking(2);
  }

  setLogOut(){
    localStorage.removeItem('islogin');
    this.props.logout(true);
  }

  renderRedirect = () => {
    if (this.state.redirect) {
      return <Redirect to='/' />
    }
  }

  render() {
  return (
    <>
    <MetaTags>
            <title>{'OBEC Content Center'}</title>
        </MetaTags>
    {this.renderRedirect()}

    <Row className="headerProfile">
          <Col xs={12} style={{textAlign:'center'}}>
          {this.state.user.imgUrl? 
            <img src={this.state.user.imgUrl} className="rounded-circle" alt="" style={{width:"7vmax",height:"7vmax",borderRadius:'5vmax'}}/>
            :
            <FaUserCircle style={{width:"7vmax",height:"7vmax"}}/>
          }
          </Col>
          <Col xs={12} style={{textAlign:'center'}}>{this.state.user.caption}</Col>
          <Col xs={12} style={{textAlign:'center'}}>สวัสดี</Col>
          <Col xs={12} style={{textAlign:'center'}}><h4>{this.state.user.firstname+' '+this.state.user.lastname}</h4></Col>
          <Col xs={12} style={{textAlign:'center'}}>{this.setRoleType(this.state.user.uRoleId)}</Col>
          <Col xs={12} style={{textAlign:'center'}}><h6>เลเวลของคุณ : {this.state.user.levelName}</h6></Col>
          <Col xs={12} style={{textAlign:'center'}}>
              <Link to="/" onClick={() => this.setLogOut()} className="buttonWhite"><FaPowerOff style={{marginRight:10}}/>ออกจากระบบ</Link>
          </Col>
          {(this.state.user.userRole !== null) && <>
          <Col xs={12} style={{textAlign:'center',marginBottom:10}}>
              <Link to="/profile/edit" className="buttonThin"><FaPencilAlt style={{marginRight:10}}/>แก้ไขข้อมูล</Link>
          </Col>
          </>}
      </Row>

    <Row>
      <Col>
        <Container>
      {(this.state.user.userRole !== null) && <>
      <Row className="graph-stat my-3">
          <Col sm={4} lg={6} className="d-none d-sm-block">ความคืบหน้าของฉัน</Col>
          <Col sm={8} lg={6} className="d-none d-sm-block" style={{textAlign:'right'}}>
              <label style={{marginRight:20}}><FaCircle style={{color:'#fd651c',marginRight:5}}/>คะแนนสูงสุด</label>
              <label><FaCircle style={{color:'#4ab5eb',marginRight:5}}/>คะแนนของฉัน</label>
          </Col>

          <Col xs={4} lg={6} className="d-block d-sm-none mt-2">สถิติ</Col>
          <Col xs={8} lg={6} className="d-block d-sm-none mt-2" style={{textAlign:'right', fontSize: '.8em'}}>
              <label style={{marginRight:20}}><FaCircle style={{color:'#4ab5eb',marginRight:5}}/>คะแนน
              <FaCircle style={{color:'#fd651c',marginLeft:5,marginRight:5}}/>คะแนน</label>
          </Col>

          
          <Col xs={12} style={{}}>
              <div className="graph-boder" style={{height:'35vmax'}}>
                <ResponsiveContainer width="100%" height="100%">
                  <LineChart
                    data={this.state.graphData}
                    margin={{
                      top: 5, right: 5, left: 5, bottom: 5,
                    }}
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="name" />
                    <YAxis />
                    <Tooltip />
                    
                    <Line type="monotone" dataKey="topGraphs" name="คะแนนสูงสุด" stroke="#fd651c" />
                    <Line type="monotone" dataKey="userGraphs" name="คะแนนของฉัน" stroke="#4ab5eb" />
                  </LineChart>
                </ResponsiveContainer>
              </div>
            
        </Col>

        <Col className="etc-stat" lg={6} xs={12} sm={6} style={{marginTop:20}}>
            <div style={{padding:'1vmax',boxShadow:'0 0 3px #e1e1e1',margin:0,height:'100%'}}>
              <h6 xs={12}>สถิติอื่นๆ</h6>
                <Col as="div" xs={12} style={{}}>
                  <Row>
                    <Col style={{alignSelf:'center'}}><h4>ดาวน์โหลด</h4></Col>
                    <Col style={{textAlign:'right',alignSelf:'center'}}><label style={{color:'#4891e3'}}><span style={{fontSize:30,fontWeight:'bold',marginRight:10}}>
                    {this.state.userStats.userStats? this.state.userStats.userStats.cntDlStr: ''}</span>ครั้ง</label></Col>
                  </Row>
                </Col>
                <Col as="div" xs={12} style={{}}>
                  <Row>
                    <Col style={{alignSelf:'center'}}><h4>ดูเนื้อหา</h4></Col>
                    <Col style={{textAlign:'right',alignSelf:'center'}}><label style={{color:'#ff8c8d'}}><span style={{fontSize:30,fontWeight:'bold',marginRight:10}}>
                    {this.state.userStats.userStats? this.state.userStats.userStats.cntView:''}
                    </span>ครั้ง</label></Col>
                  </Row>
                </Col>
                <Col as="div" xs={12} style={{}}>
                  <Row>
                    <Col style={{alignSelf:'center'}}><h4>การให้คะแนน</h4></Col>
                    <Col style={{textAlign:'right',alignSelf:'center'}}><label style={{color:'#ed91ff'}}><span style={{fontSize:30,fontWeight:'bold',marginRight:10}}>
                    {this.state.userStats.userStats? this.state.userStats.userStats.cntRating:''}
                    </span>ครั้ง</label></Col>
                  </Row>
                </Col>
                <Col as="div" xs={12} style={{}}>
                  <Row>
                    <Col style={{alignSelf:'center'}}><h4>ความคิดเห็น</h4></Col>
                    <Col style={{textAlign:'right',alignSelf:'center'}}><label style={{color:'#ffb73b'}}><span style={{fontSize:30,fontWeight:'bold',marginRight:10}}>
                    {this.state.userStats.userStats? this.state.userStats.userStats.cntComment:''}
                    </span>ครั้ง</label></Col>
                  </Row>
                </Col>
            </div>
        </Col>

        <Col lg={6} xs={12} sm={6} className="rating-info" style={{marginTop:20}}>
            <Loader loading={this.state.loading}/>
            <div style={{padding:'1vmax',boxShadow:'0 0 3px #e1e1e1',margin:0,height:'100%'}}>
              <div className="rating-info-header d-flex justify-content-between">
                <div style={{marginLeft: '.5em'}}>การจัดอันดับ</div>
                <div className="d-flex justify-content-end" style={{textAlign:'right'}}>
                    <label className={(this.state.rankingType == 1)?"mr-1 btHoverGreenActive":"mr-1 btHoverGreen"} onClick={e => this.topRank(e)}>อันดับสูงสุด</label>
                </div>
              </div>
              {(this.state.userRanking.length > 0)? 
                this.setUserRanking()
                : 
                null 
              }
            </div>
        </Col>

        <Col className="d-none d-lg-block d-xl-block" xs={12} style={{marginTop:20,textAlign:'center'}}>
          <Link to="/favorite">
            <label className="buttonWhite" style={{boxShadow:'0 0 10px #dcdcdc',width:250,paddingLeft:50,textAlign:'left'}}><FaHeart style={{marginRight:10}}/>รายการโปรด</label>
          </Link>
        </Col>
        <Col className="d-none d-lg-block d-xl-block" xs={12} style={{marginTop:10,textAlign:'center'}}>
          <Link to="/profile/history">
            <label className="buttonGreen" style={{boxShadow:'0 0 10px #dcdcdc',width:250,paddingLeft:50,textAlign:'left'}}><FaRegClock style={{marginRight:10}}/>ประวัติการทำข้อสอบ</label>
          </Link>
        </Col>

      </Row>
      </> }
          
        </Container>
      </Col>
    </Row>

    
    </>
  );
}
}

export default Profile;
