import React from 'react';
import './profile_edit.css';
import {Alert,Button,Row,Col,Modal, Container} from 'react-bootstrap';
import { FaUserCircle, FaCamera, FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import Select from 'react-select';
import DatePicker from "react-datepicker";
import Th from 'date-fns/locale/th';
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import axios from 'axios';
import Loader from '../component/loader';
import { Redirect } from 'react-router-dom';
import { Link } from 'react-router-dom';
import InputMask from 'react-input-mask';
import range from "lodash/range";
import getYear from "date-fns/getYear";
// import getMonth from "date-fns/getMonth";
// import getDate from "date-fns/getDate";
const MySwal = withReactContent(Swal);

const years = range(1950, getYear(new Date()) + 1, 1);
const months = [
  {en:"January",th:"มกราคม"},
  {en:"February",th:"กุมภาพันธ์"},
  {en:"March",th:"มีนาคม"},
  {en:"April",th:"เมษายน"},
  {en:"May",th:"พฤษภาคม"},
  {en:"June",th:"มิถุนายน"},
  {en:"July",th:"กรกฏาคม"},
  {en:"August",th:"สิงหาคม"},
  {en:"September",th:"กันยายน"},
  {en:"October",th:"ตุลาคม"},
  {en:"November",th:"พฤศจิกายน"},
  {en:"December",th:"ธันวาคม"}
];


class CustomInput extends React.Component {

  handleValue = (date) => {
    const d = date.split("-");
    const tDate = new Date(d[2]+'-'+d[1]+'-'+d[0]);
    let dd = ((tDate.getDate() < 10)? '0' : '').concat(tDate.getDate());
    let MM = (((tDate.getMonth() + 1) < 10)? '0' : '').concat(tDate.getMonth() + 1);
    let yyyy = Number(tDate.getFullYear());
    //if(process.env.REACT_APP_ENV_MODE === "development") console.log("input view date",date,dd+'-'+MM+'-'+yyyy);
    return dd+'-'+MM+'-'+yyyy;
  }

  render() {
    return (
      <input className={this.props.className} onClick={(e)=>this.props.onClick(e)} value={this.handleValue(this.props.value)} onChange={()=>{}}/>
    );
  }
}


class ProfileEdit extends React.Component {
  constructor(props, context) {
    super(props, context);

    this.setPassShow = this.setPassShow.bind(this);
    this.setPassClose = this.setPassClose.bind(this);

    this.state = {
        user: {
          title: '',
          firstname:'',
          lastname:'',
          gender:'',
          tel:'',
          birthday:'',
          pid:'',
          email:'',
          uRoleId:'',
          caption: '',
          address: '',
          provinceId: 0,
          amphurId: 0,
          tambolId: 0,
          obecAreaId: 0,
          obecSchoolId: 0,
          zipcode: '',
          gmailId: '',
          facebookId: ''
        },
        imgFile: [],
        uRoleName: '',
        showSetPass: false,
        startDate: null,
        provinceList: [],
        provinceSelectValue: [],
        amphurList: [],
        amphurSelectValue: [],
        tambolList: [],
        tambolSelectValue: [],
        areaList: [],
        areaSelectValue: [],
        areaId: null,
        schoolList: [],
        schoolSelectValue: [],
        schoolId: null,
        birthDate: new Date(),
        userTypes: [],
        userTypeValue: [],
        alertShow: false,
        alertPwd: false,
        selectError: false,
        variant: null,
        message: '',
        loading: false,
        redirect: false,
        guest: false,
        require13pid: false,
        isSocialLogin: false
    };
    this.dateChange = this.dateChange.bind(this);
  }

  async componentDidMount(){
    await this.getUserType();
    await this.getProvince();
    await this.getAreas();
    await this.setUserData();

    let goValidate = this.props.match.params.submit;
    console.log(goValidate);
    if(goValidate){
      this.validate();
    }
  }

  async componentWillReceiveProps(nextProps){
    if(nextProps.searchWord.trim() !== '' && nextProps.searchWord !== this.props.searchWord){
      await this.props.history.push('/search', nextProps.searchWord);
    }
  }

  setUserData = async () => {
    let items = JSON.parse(await localStorage.getItem('islogin'));

    if (items === null) {
      this.setState({redirect: true });
      return;
    }
    if (process.env.REACT_APP_ENV_MODE === "development") console.log(items);

    if (items.userRole === null) {
      this.setState({redirect: true });
      return;
    }

    if(items.gmailId !== null){
      this.setState({isSocialLogin : true})
    } else {
      items.gmailId = '';
    }

    if(items.facebookId !== null){
      this.setState({isSocialLogin : true})
    } else {
      items.facebookId = '';
    }

    if(items.uRoleId === "999999999"){
      this.setState({uRoleName: "ผู้ใช้งานทั่วไป"});
    } else {
      this.setState({uRoleName: items.userRole.name});
    }

    if(items.title === null){
      items.title = '';
    }

    if(items.address === null){
      items.address = '';
    }

    if(items.zipcode === null){
      items.zipcode = '';
    }
    if(items.caption === "" || items.caption === null){
      items.caption = "ตัวช่วยครูและนักเรียนมาแล้ว!";
    }

    this.setState({user: items});

    console.log(this.state);

    if (items.birthday){
      const d = items.birthday.split("-");
      this.setState({
        birthDate: new Date((d[2])+'-'+d[1]+'-'+d[0])
      });
    }

    if (items.userRole){
      const userRoleType = this.state.userTypes.filter(item => {
        return item.value === items.userRole.uRoleId;
      })[0];

      this.setState({
        userTypeValue: [{value:userRoleType.value, label:userRoleType.label}]
      });
    }

    if (typeof items.provinceId !== 'undefined'){
      const userProvince = this.state.provinceList.filter(item => {
        return item.value === items.provinceId;
      })[0];
      this.setState({
        provinceSelectValue: [{value:userProvince.value, label:userProvince.label}]
      });
    } else {
      this.setState({
        provinceSelectValue: [{value:0, label:"ไม่ระบุ"}]
      });
    }
    if (items.provinceId && items.amphurId){
      await this.getAmphur(items.provinceId);
      const userAmphur = await this.state.amphurList.filter(item => {
        return item.value == items.amphurId;
      })[0];
      this.setState({
        amphurSelectValue: [{value:userAmphur.value, label:userAmphur.label}]
      });
    }
    if (items.provinceId && items.amphurId && items.tambolId){
      await this.getTambol(items.amphurId);
      const userTambol = await this.state.tambolList.filter(item => {
        return item.value == items.tambolId;
      })[0];
      this.setState({
        tambolSelectValue: [{value:userTambol.value, label:userTambol.label}]
      });
    }

    if (typeof items.obecAreaId !== 'undefined'){
      const userArea = this.state.areaList.filter(item => {
        return item.value === items.obecAreaId;
      })[0];
      this.setState({
        areaSelectValue: [{value:userArea.value, label:userArea.label}]
      });
    } else {
      this.setState({
        areaSelectValue: [{value:0, label:"ไม่ระบุ"}]
      });
    }
    if (items.obecAreaId && items.obecSchoolId){
      await this.getSchools(items.obecAreaId);
      const userSchool = await this.state.schoolList.filter(item => {
        return item.value == items.obecSchoolId;
      })[0];
      if (userSchool) {
        this.setState({
          schoolSelectValue: [{value:userSchool.value, label:userSchool.label}]
        });
      }
    }
    // if (typeof items.obecSchoolId !== 'undefined'){
    //   const userSchool = this.state.schoolList.filter(item => {
    //     return item.value === items.obecSchoolId;
    //   })[0];
    //   this.setState({
    //     schoolSelectValue: [{value:userSchool.value, label:userSchool.label}]
    //   });
    // } else {
    //   this.setState({
    //     schoolSelectValue: [{value:0, label:"ไม่ระบุ"}]
    //   });
    // }
  }

  getUserType = () => {
    return axios.get(this.props.mainpoint+'/api/v1/usertypes').then(
      success  =>  {
        //if (process.env.REACT_APP_ENV_MODE === "development") console.log('setuserdata',success);
        const { status, data } = success
        if (status === 201 || status === 200) {
          if (process.env.REACT_APP_ENV_MODE === "development") console.log('userType', data);
          let userTypesArray = [];
          data.result.forEach(element => {
            userTypesArray.push({ value: element.uRoleId, label: element.name });
          });
          
          this.setState({
            userTypes: userTypesArray,
          });
        }
      },
      error => {
        if(process.env.REACT_APP_ENV_MODE === "development") console.log('Error', error);
      }
    );
  }

  getProvince = () => {
    return axios.get(this.props.mainpoint+'/api/v1/provinces/0').then(
      success => {
        //if (process.env.REACT_APP_ENV_MODE === "development") console.log('province',success);
        const { status, data } = success
        if (status === 201 || status === 200) {
          if (process.env.REACT_APP_ENV_MODE === "development") console.log('province',data);
          let provincesArray = [];
          data.result.forEach(element => {
            provincesArray.push({ value: element.provinceId, label: element.name });
          });
          this.setState({
            provinceList: provincesArray
          });
        }
      },
      error => {
        if(process.env.REACT_APP_ENV_MODE === "development") console.log('Error', error);
      }
    );
  }

  getAmphur = (id) => {
    return axios.get(this.props.mainpoint+'/api/v1/amphurs/0/provinceid/'+id).then(
      success => {
        const { status, data } = success
        if (status === 201 || status === 200) {
          if (process.env.REACT_APP_ENV_MODE === "development") console.log('amphur',data);
          let amphurArray = [];
          data.result.forEach(element => {
            amphurArray.push({ value: element.amphurId, label: element.name });
          });
          this.setState({
            amphurList: amphurArray
          });
        }
      },
      error => {
        if(process.env.REACT_APP_ENV_MODE === "development") console.log('Error', error);
      }
    );
  }

  getTambol = (id) => {
    return axios.get(this.props.mainpoint+'/api/v1/tambols/0/amphurid/'+id).then(
      success => {
        const { status, data } = success
        if (status === 201 || status === 200) {
          if (process.env.REACT_APP_ENV_MODE === "development") console.log('tambol',data);
          let tambolArray = [];
          data.result.forEach(element => {
            tambolArray.push({ value: element.tambolId, label: element.name });
          });
          this.setState({
            tambolList: tambolArray
          });
        }
      },
      error => {
        if(process.env.REACT_APP_ENV_MODE === "development") console.log('Error', error);
      }
    );
  }
  getAreas = () => {
    return axios.get(this.props.mainpoint + '/api/v1/obec/areas').then(
      success => {
        const {
          status,
          data
        } = success
        if (status === 201 || status === 200) {
          if (process.env.REACT_APP_ENV_MODE === "development") console.log('areas', data);
          let areaArray = [];
          data.result.forEach(element => {
            areaArray.push({
              value: element.areaId,
              label: element.abbreviation
            });
          });
          this.setState({
            areaList: areaArray
          });
        }
      },
      error => {
        if (process.env.REACT_APP_ENV_MODE === "development") console.log('Error', error);
      }
    );
  }
  getSchools = (areaId) => {
    // let schoolArray = [{
    //   value: "1",
    //   label: "สตรีวิทยา"
    // },
    // {
    //   value: "2",
    //   label: "มัธยมวัดเบญจมบพิตร"
    // },
    // {
    //   value: "3",
    //   label: "สวนกุหลาบวิทยาลัย"
    // }];
    // this.setState({
    //   schoolList: schoolArray
    // });
    return axios.get(this.props.mainpoint + '/api/v1/obec/areas/' + areaId + '/schools').then(
      success => {
        const {
          status,
          data
        } = success
        if (status === 201 || status === 200) {
          if (process.env.REACT_APP_ENV_MODE === "development") console.log('schools', data);
          let schoolArray = [];
          data.result.forEach(element => {
            schoolArray.push({
              value: element.id,
              label: element.name
            });
          });
          this.setState({
            schoolList: schoolArray
          });
        }
      },
      error => {
        if (process.env.REACT_APP_ENV_MODE === "development") console.log('Error', error);
      }
    );
  }
  selectUserType = (optionSelected) => {
    //if (process.env.REACT_APP_ENV_MODE === "development") console.log((optionSelected.value, optionSelected.label);
    let userType = this.state.user;
    userType.uRoleId = optionSelected.value;
    this.setState({ selectError: false,
      userType, userTypeValue: [{value:optionSelected.value,label:optionSelected.label }]
    });

    if(optionSelected.value >= 52 && optionSelected.value <= 54){
      this.setState({ require13pid: true });
    } else {
      this.setState({ require13pid: false });
    }
    this.setState({
      obecAreaId:0,
      areaSelectValue: [],
      obecSchoolId:0,
      schoolSelectValue: []
    });
  }

  selectProvince = (optionSelected) => {
    this.setState({amphurSelectValue: [], tambolSelectValue: []})
    if (process.env.REACT_APP_ENV_MODE === "development") console.log("changeProvince", optionSelected);
    let user = this.state.user;
    user.provinceId = optionSelected.value;
    user.amphurId = 0;
    user.tambolId = 0;
    this.setState({ selectError: false, user,
      provinceSelectValue: [{value:optionSelected.value, label:optionSelected.label }]
    });
    if (optionSelected.value == '0') {
      this.setState({amphurList: [], tambolList: []})
    } else {
      this.getAmphur(optionSelected.value)
    }
  }

  selectAmphur = (optionSelected) => {
    this.setState({tambolSelectValue: []})
    if (process.env.REACT_APP_ENV_MODE === "development") console.log("changeAmphur",optionSelected);
    let user = this.state.user;
    user.amphurId = optionSelected.value;
    user.tambolId = 0;
    this.setState({ selectError: false, user,
      amphurSelectValue: [{value:optionSelected.value, label:optionSelected.label }]
    });
    this.getTambol(optionSelected.value);
  }

  selectTambol = (optionSelected) => {
    if (process.env.REACT_APP_ENV_MODE === "development") console.log("changeTambol", optionSelected);
    let user = this.state.user;
    user.tambolId = optionSelected.value;
    this.setState({ selectError: false, user,
      tambolSelectValue: [{value:optionSelected.value, label:optionSelected.label }]
    });
  }
  selectArea = (optionSelected) => {
    this.setState({ schoolSelectValue: []})
    if (process.env.REACT_APP_ENV_MODE === "development") console.log("changeArea", optionSelected);
    let user = this.state.user;
    user.obecAreaId = optionSelected.value;
    user.obecSchoolId = 0
    this.setState({
      areaId: optionSelected.value,
      areaSelectValue: [{ value: optionSelected.value, label: optionSelected.label }]
    });
    if (optionSelected.value == '0') {
      this.setState({ schoolList: []})
    } else {
      this.getSchools(optionSelected.value)
    }
  }
  selectSchool = (optionSelected) => {
    if (process.env.REACT_APP_ENV_MODE === "development") console.log("changeSchool", optionSelected);
    let user = this.state.user;
    user.obecSchoolId = optionSelected.value;
    this.setState({
      schoolId: optionSelected.value,
      schoolSelectValue: [{ value: optionSelected.value, label: optionSelected.label }]
    });
  }
  modelData = (e, name) => {
    let changeData = this.state.user;
    changeData[name] = e.target.value;
    this.setState({ changeData });
  };

  onDatepickerRef = (el) => {
    if (el && el.input) {
      el.input.readOnly = true;
    }
  }

  dateChange(date) {
    let dd = (moment(date).get("date") < 10 ? '0' : '') + moment(date).get("date");
    let MM = ((moment(date).get("month") + 1) < 10 ? '0' : '') + (moment(date).get("month") + 1);
    let yyyy = moment(date).get("year");
    let user = this.state.user;
    user.birthday = dd+'-'+MM+'-'+yyyy;
    if(process.env.REACT_APP_ENV_MODE === "development") console.log("date Change Event",date,dd+'-'+MM+'-'+yyyy);
    this.setState({
      birthDate: date,
      user
    });
  }

  handleMonth = (date) => {
    return months.filter((item,index) => {
        return index === moment(date).get("month");
      }).map(ele => ele.th)[0];
  }

  setAlert = (name, type, msg) => {
    this.setState({ selectError: false});
    document.getElementById('ugender').style.borderColor = '#e1e1e1';
    let x = document.getElementsByClassName('is-invalid');
    for (let i = 0; i < x.length; i++) {
      x[i].classList.remove('is-invalid');
    }
    let y = document.getElementById(name);

    if (name == 'uusertype') {
      this.setState({ selectError: true});
    } else if (name == 'ugender') {
      y.style.borderColor = '#dc3545';
    } else {
      y.classList.add('is-invalid');
    }

    this.setState({ 
      alertShow: true,
      variant: type,
      message: msg
    });
  }

  checkPid = (id) => {
    if(id.length != 13) return false;
    let sum = 0;
    for(let i=0; i < 12; i++) {
      sum += parseFloat(id.charAt(i))*(13-i);
    }
    if((11-sum%11)%10 != parseFloat(id.charAt(12))) {
      return false;
    } else {
      return true;
    }
  }

  validate = () => {
    let x = document.getElementsByClassName('is-invalid');
    for (let i = 0; i < x.length; i++) {
      x[i].classList.remove('is-invalid');
    }
    const {uRoleId, firstname, lastname, gender, tel, birthday, pid, email, address, 
      provinceId, amphurId, tambolId, zipcode , obecAreaId ,obecSchoolId} = this.state.user;
    let emailPattern = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    
    if(!this.state.isSocialLogin){
      if (!email || (email.trim() == '')) return this.setAlert('uemail','danger','กรุณากรอกอีเมล');
      if (!emailPattern.test(email)) return this.setAlert('uemail','danger','กรุณากรอกรูปแบบอีเมลให้ถูกต้อง');
    }
    
    if (!uRoleId) return this.setAlert('uusertype','warning','กรุณาเลือกประเภทผู้ใช้งาน');
    // if (!title || (title.trim() == '')) return this.setAlert('utitle','danger','กรุณากรอกคำนำหน้าชื่อ');
    if (!firstname || (firstname.trim() == '')) return this.setAlert('ufname','danger','กรุณากรอกชื่อ');
    if (!lastname || (lastname.trim() == '')) return this.setAlert('ulname','danger','กรุณากรอกนามสกุล');

    if(this.state.require13pid){
      if (!pid || (pid.trim() == '')) return this.setAlert('ucid','danger','กรุณากรอกเลขประจำตัวประชาชน');
    }

    if(pid !== null){
      if (pid.replace(/ /gi, '').length < 13) return this.setAlert('ucid','danger','กรุณาระบุเลขประจำตัวประชาชนให้ครบ 13 หลัก');
      if (!this.checkPid(pid.replace(/ /gi, ''))) return this.setAlert('ucid','danger','กรุณาระบุเลขประจำตัวประชาชนให้ถูกต้อง');
    }
    console.log('====================================');
    console.log(uRoleId,obecSchoolId);
    console.log('====================================');
    if (uRoleId && uRoleId != "50" && uRoleId != "51") {
      if (uRoleId == "52") {
        if (obecAreaId ==0) return this.setAlert('obecAreaId', 'danger', 'กรุณาเลือกเขตพื้นที่การศึกษา');
        if (obecSchoolId ==0) return this.setAlert('obecSchoolId', 'danger', 'กรุณาเลือกโรงเรียน');
      }else{
        if (obecAreaId ==0) return this.setAlert('obecAreaId', 'danger', 'กรุณาเลือกเขตพื้นที่การศึกษา');
       
      }
    }
    // if (!gender || (gender.trim() == '')) return this.setAlert('ugender','danger','กรุณาระบุเพศ');
    // if (!tel || (tel.trim() == '')) return this.setAlert('utel','danger','กรุณากรอกเบอร์โทรศัพท์');
    // if (tel.replace(/-/gi, '').length < 9) return this.setAlert('utel','danger','กรุณาระบุหมายเลขโทรศัพท์ให้ครบจำนวน');
    // if (!birthday || (birthday.trim() == '')) return this.setAlert('ubirthdate','danger','กรุณาระบุวัน เดือน ปีเกิด');
    
    // if (!address || (address.trim() == '')) return this.setAlert('address','danger','กรุณาระบุที่อยู่');
    if (!provinceId) return this.setAlert('provinceId','danger','กรุณาระบุจังหวัด');
    // if (!amphurId) return this.setAlert('amphurId','danger','กรุณาระบุอำเภอ');
    // if (!tambolId) return this.setAlert('tambolId','danger','กรุณาระบุตำบล');    
    // if (zipcode.replace(/_/gi, '').length < 5) return this.setAlert('zipcode','danger','กรุณาระบุรหัสไปรษณีย์ให้ครบ 5 หลัก');

    this.editSubmit();
  }

  editSubmit = () => {
    const {title, firstname, lastname, gender, tel, birthday, pid, email, uRoleId, caption, address, provinceId, amphurId, tambolId, obecAreaId, obecSchoolId, zipcode} = this.state.user;
    const formData = {
      uRoleId: uRoleId,
      title: title,
      firstname: firstname,
      lastname: lastname,
      gender: gender,
      tel: (tel !== null)? tel.replace(/-|_|\./gi, '') : "",
      birthday: birthday,
      pid: (pid !== null)? pid.replace(/ /gi, '') : "",
      email: email,
      caption: caption,
      address: address,
      provinceId: provinceId,
      amphurId: amphurId,
      tambolId: tambolId,
      obecAreaId:obecAreaId,
      obecSchoolId:obecSchoolId,
      zipcode: zipcode
    }

    if (process.env.REACT_APP_ENV_MODE === "development") console.log("formData", formData);
    this.setState({ loading: true });
    let items = JSON.parse(localStorage.getItem('islogin'));

    const api = axios.create({
      baseURL: this.props.mainpoint,
      headers: {
        "Content-Type": "application/json",
        "token": items.token
      },
      timeout: 85000
    });

    api.put(`/api/v1/user/profile`, formData).then(
      success => {
        //if (process.env.REACT_APP_ENV_MODE === "development") console.log(success);
        this.setState({ loading: false });
        const { status, data } = success
        if (status === 201 || status === 200) {
          if (process.env.REACT_APP_ENV_MODE === "development") console.log("Edited Data ",data);
          const {title, firstname, lastname, gender, tel, birthday, pid, email, userRole, address, provinceId, amphurId, tambolId, obecAreaId, obecSchoolId, zipcode } = data.result;
          const returnedData = Object.assign(this.state.user, {
            title:title,
            firstname:firstname,
            lastname:lastname,
            gender:gender,
            tel:tel,
            birthday:birthday,
            pid:pid,
            email:email,
            uRoleId:(userRole)? userRole.uRoleId:null,
            userRole: userRole,
            address:address,
            provinceId: provinceId,
            amphurId: amphurId,
            tambolId: tambolId,
            obecAreaId:obecAreaId,
            obecSchoolId:obecSchoolId,
            zipcode:zipcode,
            imgUrl: this.checkAvatar(data.result)
          });
          localStorage.setItem('islogin', JSON.stringify(returnedData));
          this.setState({user:returnedData});

          Swal.fire({
            type: 'success',
            text: data.message,
            onClose: () => {
              this.props.changeProfiles(returnedData);
              this.setState({redirect: true });
              window.location.reload();
            }
          });
        }
      },
      error => {
        if (process.env.REACT_APP_ENV_MODE === "production") console.log(error);
        const failure = JSON.parse(JSON.stringify(error));
        console.log(failure)
        const data = failure.response;
        //this.swalFire(data.message);
        this.setState({ loading: false });
      }
    );
  }

  checkAvatar = (formData) => {
    if(formData.imgUrl === "" || formData.imgUrl === null){
      let roleId = formData.userRole.uRoleId;
      return require("../img/avatar/role_" + roleId + ".png");
    } else {
      return formData.imgUrl;
    }
  }
  setAvatar=()=>{
    let items = JSON.parse(localStorage.getItem('islogin'));
    return require("../img/avatar/role_" + items.uRoleId + ".png");
  }
  cancel = () => {
    let x = document.getElementsByClassName('is-invalid');
    for (let i = 0; i < x.length; i++) {
      x[i].classList.remove('is-invalid');
    }
    this.setUserData();
    this.setState({
      redirect: true
    });
  }

  renderRedirect = () => {
    if (this.state.redirect) {
      return <Redirect to='/profile' />
    }
  }

  renderGuest = () => {
    if (this.state.guest) {
      return <Redirect to='/' />
    }
  }

  setPassClose() {
    this.setState({ 
      showSetPass: false,
      oldpassword:"",
      newpassword:"",
      cfpassword:"",
      alertPwd: false
     });
  }

  setPassShow() {
    this.setState({ showSetPass: true });
  }

  pwdValidate = () => {
    const {oldpassword, newpassword, cfpassword} = this.state;
    if (!oldpassword || (oldpassword.trim() == "")) return this.setPwdAlert('oldpassword','กรุณาระบุรหัสผ่านเก่า');
    if (!newpassword || (newpassword.trim() == "")) return this.setPwdAlert('newpassword','กรุณาระบุรหัสผ่านใหม่');
    if (!cfpassword || (cfpassword.trim() == "")) return this.setPwdAlert('cfpassword','กรุณายืนยันรหัสผ่านใหม่');
    if (newpassword !== cfpassword) return this.setPwdAlert('cfpassword','กรุณาระบุรหัสผ่านใหม่กับยืนยันรหัสผ่านใหม่ให้ตรงกัน');
    this.changePwd();
  }

  setPwdAlert = (name,msg) => {
    let x = document.getElementsByClassName('is-invalid');
    for (let i = 0; i < x.length; i++) {
      x[i].classList.remove('is-invalid');
    }
    let y = document.getElementById(name);
    y.classList.add('is-invalid');
    this.setState({ 
      alertPwd: true,
      message: msg
    });
  }

  swalFire = (msg) => {
    MySwal.fire({
      type: 'warning',
      confirmButtonText: 'ตกลง',
      text: msg
    });
  }

  changePwd = () => {

    const {oldpassword, newpassword} = this.state;
    this.setState({ loading: true });
    const api = axios.create({
      baseURL: this.props.mainpoint,
      headers: {
        "Content-Type": "application/json",
        "token":this.state.user.token
      },
      timeout: 85000
    });
    const stringQuery = `?oldPwd=${oldpassword}&newPwd=${newpassword}`;
    api.post(`/api/v1/user/profile/pwd`+stringQuery).then(
      success => {
        if (process.env.REACT_APP_ENV_MODE === "development") console.log(success);
        this.setState({ loading: false });
        const { status, data } = success
        if (status === 201 || status === 200) {
          if (process.env.REACT_APP_ENV_MODE === "development") console.log("Changed Password", data);
          Swal.fire({
            type: 'success',
            text: data.message,
            onClose: () => {
              this.setPassClose();
            }
          });
        }
      },
      error => {
        if (process.env.REACT_APP_ENV_MODE === "development") console.log('Error', error);
        const failure = JSON.parse(JSON.stringify(error));
        const {status, data} = failure.response;
        this.swalFire(data.message);
        this.setState({ loading: false });
      }
    );
  }

  uploadImage = (e) => {
    this.setState({ 
      alertShow: false,
      variant: "warning",
      message: ""
    });
    const imgType = ["image/png","image/jpeg","image/jpg"];
    const files = document.getElementById("imgfile").files[0];
    if (process.env.REACT_APP_ENV_MODE === "development") console.log(files);
    if (!imgType.includes(files.type)) return this.setState({ alertShow: true, variant: "warning", message: "กรุณาเลือกรูปที่เป็น png, jpg หรือ jpeg"});
    const formData = new FormData();
    formData.append("file", files);
    const api = axios.create({
      baseURL: this.props.mainpoint,
      headers: {
        "Content-Type": "application/json",
        "token":this.state.user.token
      },
      timeout: 85000
    });
    this.setState({ loading: true });
    api.post(`/api/v1/user/profile/img`, formData).then(
      success => {
        if (process.env.REACT_APP_ENV_MODE === "development") console.log(success);
        this.setState({ loading: false });
        const { status, data } = success
        if (status === 201 || status === 200) {
          if (process.env.REACT_APP_ENV_MODE === "development") console.log("Change User Image", data);
          const {imgLocation, imgUrl} = data.result;
          const returnedData = Object.assign(this.state.user, {
            imgLocation:imgLocation,
            imgUrl:imgUrl,
            rdNum: Math.random()
          });
          localStorage.setItem('islogin', JSON.stringify(returnedData));
          this.setState({user:returnedData});
          Swal.fire({
            type: 'success',
            text: data.message,
            onClose: () => {
              this.props.changeUserImg(returnedData);
              window.location.reload();
            }
          });
        }
      },
      error => {
        if (process.env.REACT_APP_ENV_MODE === "development") console.log(JSON.parse(JSON.stringify(error)));
        // const failure = JSON.parse(JSON.stringify(error));
        // const {status, data} = failure.response;
        // this.swalFire(data.message);
        this.setState({ loading: false });
      }
    );
  }

  render() {
  return (
    <>
    <Row className="user-profile bg-light py-3">
      <Col>
        <Container>
          <Row>
              <Loader loading={this.state.loading}/>
              {this.renderGuest()}
              <Col xs={12} style={{color: '#9e9f9e',padding:0}}><Link to="/profile">ข้อมูลของฉัน</Link> / แก้ไขโปรไฟล์</Col>
              <Col xs={12} style={{backgroundColor:'#ffffff',border:'1px solid #e1e1e1',padding:'10px 10px 80px 10px'}}>
                  <Row>
                      <Col xs={12}>ข้อมูลของฉัน</Col>
                      <Col xs={12} style={{color: '#9e9f9e'}}>จัดการข้อมูลส่วนตัวคุณเพื่อความปลอดภัยของบัญชีผู้ใช้นี้</Col>
                  </Row>
                  <Row style={{marginTop:10}} className="">
                      <Col lg={2} xs={12} sm={12} className="d-flex justify-content-sm-center justify-content-md-center user-image">
                      <div className="upload-btn-wrapper">
                        {this.state.user.imgUrl? 
                          <img  src={this.state.user.imgUrl} className="img-fluid rounded-circle profile-pic" alt="User Image" style={{width:"10vmax",height:"10vmax",backgroundColor:'gray'} } />
                          :
                          <img src={this.setAvatar()} className="img-fluid rounded-circle profile-pic" alt="User Image" style={{width:"10vmax",height:"10vmax",backgroundColor:'gray'}}/>
                        }
                          <div className="upload-btn" style={{cursor:'pointer'}}><FaCamera/></div>
                          <input type="file" id="imgfile" accept="image/png,image/jpeg,image/jpg" onChange={e => this.uploadImage(e)} style={{cursor:'pointer'}}/>
                      </div>
                          
                      </Col>
                      <Col lg={10} xs={12} sm={12} style={{padding:0}}>
                          <form>
                          <Row style={{margin:0}}>

                              {(this.state.isSocialLogin)?
                                (this.state.user.facebookId !== "")? 
                                <Col lg={4} xs={12} style={{marginTop:10}}>
                                    <label style={{color:'#a7a6a7'}}>Facebook Id <span className="required">*</span></label>
                                    <input type="text" style={{cursor:"no-drop"}} className="bg-white inputProfile" disabled
                                    value={this.state.user.facebookId} 
                                    />
                                </Col>
                                : 
                                <Col lg={4} xs={12} style={{marginTop:10}}>
                                    <label style={{color:'#a7a6a7'}}>Google Id <span className="required">*</span></label>
                                    <input type="text" style={{cursor:"no-drop"}} className="bg-white inputProfile" disabled
                                    value={this.state.user.gmailId} 
                                    />
                                </Col>
                                :
                                <Col lg={4} xs={12} style={{marginTop:10}}>
                                    <label style={{color:'#a7a6a7'}}>อีเมล <span className="required">*</span></label>
                                    <input type="text" style={{cursor:"no-drop"}} className="bg-white inputProfile" disabled
                                    value={this.state.user.email} 
                                    />
                                </Col>
                              }

                              

                              <Col lg={4} xs={12} style={{marginTop:10}}>
                                  <label style={{color:'#a7a6a7'}}>ประเภทผู้ใช้งาน <span className="required">*</span></label>
                                  <Select 
                                      placeholder={''}
                                      id="uusertype"
                                      styles={
                                          {
                                          control: (styles, state) => (this.state.selectError?{ ...styles,
                                              backgroundColor: '#f9f9f9',
                                              borderColor:  state.isFocused? '#e1e1e1' : '#dc3545',
                                              '&:hover':   '#dc3545',
                                              boxShadow: state.isFocused? '0 0 0 0.1rem rgba(77, 144, 254,.25)': null,
                                              padding:5,
                                              border: '1px solid #e1e1e1',
                                              borderRadius:0
                                          } : { ...styles,
                                              backgroundColor: '#f9f9f9',
                                              borderColor: state.isFocused? '#e1e1e1': '#ced4da',
                                              boxShadow: state.isFocused? '0 0 0 0.1rem rgba(77, 144, 254,.25)': null,
                                              padding:".25em",
                                              border: '1px solid #e1e1e1',
                                              borderRadius:0
                                          })
                                          }
                                      }
                                      value={this.state.userTypeValue}
                                      options={this.state.userTypes}
                                      onChange={this.selectUserType}
                                      isSearchable={false}
                                    />
                                    {/* <input type="text" style={{cursor:"no-drop"}} className="bg-white inputProfile" disabled
                                      value={this.state.uRoleName}
                                    /> */}
                              </Col>

                              <Col lg={4} xs={12} style={{marginTop:10}}>
                                
                                  <label style={{color:'#a7a6a7'}}>รหัสผ่าน</label>
                                  <div style={{width:'100%',padding:5,backgroundColor:'#e7fff6',textAlign:'center'}}>
                                      <Button onClick={e => this.setPassShow()} className="forgotPwd-btn"><u>เปลี่ยนรหัสผ่าน</u></Button>
                                  </div>
                                
                              </Col>

                          </Row>

                          <Row style={{margin:0}}>

                              <Col lg={4} xs={12} style={{marginTop:10}}>
                                <label style={{color:'#a7a6a7'}}>คำนำหน้าชื่อ</label>
                                <input type="text" placeholder="" id="utitle" className="inputProfile"
                                  value={this.state.user.title} 
                                  onChange={e => this.modelData(e,"title")}
                                  />
                              </Col>

                              <Col lg={4} xs={12} style={{marginTop:10}}>
                                  <label style={{color:'#a7a6a7'}}>ชื่อ <span className="required">*</span></label>
                                  <input type="text" placeholder="" id="ufname" className="inputProfile"
                                  value={this.state.user.firstname} 
                                  onChange={e => this.modelData(e,"firstname")}
                                  />
                              </Col>

                              <Col lg={4} xs={12} style={{marginTop:10}}>
                                  <label style={{color:'#a7a6a7'}}>นามสกุล <span className="required">*</span></label>
                                  <input type="text" placeholder="" id="ulname" className="inputProfile"
                                  value={this.state.user.lastname} 
                                  onChange={e => this.modelData(e,"lastname")}
                                  />
                              </Col>

                          </Row>

                          <Row style={{margin:0}}>

                              <Col lg={4} xs={12} style={{marginTop:10}}>
                                  <label style={{color:'#a7a6a7'}}>รหัสประจำตัวประชาชน {(this.state.require13pid)? <span className="required">*</span> : ''} </label>
                                  <InputMask mask="9 9999 99999 99 9" maskChar={null} 
                                    placeholder="" id="ucid" className="inputProfile"
                                    value={this.state.user.pid} 
                                    onChange={e => this.modelData(e,"pid")}/>
                              </Col>

                              <Col lg={4} xs={12} style={{marginTop:10}}>
                                  <label style={{color:'#a7a6a7'}}>เพศ </label>
                                  <div className="inputProfile checkBox" id="ugender">
                                      <label style={{display:'inline',padding:10}}>
                                          <input type="radio" name="gender" value="m" style={{marginRight:10}} 
                                          checked={this.state.user.gender == "m"}
                                          onChange={e => this.modelData(e,"gender")}/>
                                          ชาย
                                      </label>
                                      <label style={{display:'inline',padding:10,marginLeft:20}}>
                                          <input type="radio" name="gender" value="f" style={{marginRight:10}}
                                          checked={this.state.user.gender == "f"}
                                          onChange={e => this.modelData(e,"gender")}/>
                                          หญิง
                                      </label>
                                  </div>
                              </Col>

                              <Col lg={4} xs={12} style={{marginTop:10}}>
                                  <label style={{color:'#a7a6a7'}}>เบอร์โทรศัพท์ </label>
                                  <InputMask mask="999-999-9999" maskChar={null} 
                                    placeholder="" id="utel"
                                    className="inputProfile"
                                    value={this.state.user.tel} 
                                    onChange={e => this.modelData(e,"tel")}/>
                              </Col>

                          </Row>

                          <Row style={{margin:0}}>

                              <Col lg={4} xs={12} style={{marginTop:10}}>
                                  <label style={{color:'#a7a6a7'}}>วัน เดือน ปีเกิด </label>
                                  <DatePicker
                                      placeholderText="" 
                                      id="ubirthdate"
                                      maxDate={new Date()}
                                      onChange={this.dateChange}
                                      selected={this.state.birthDate} 
                                      className="inputProfile"
                                      dateFormat="dd-MM-yyyy"
                                      locale={Th}
                                      ref={el => this.onDatepickerRef(el)} 
                                      customInput={ <CustomInput/> }
                                      renderCustomHeader={({
                                        date,
                                        changeYear,
                                        changeMonth,
                                        decreaseMonth,
                                        increaseMonth,
                                        prevMonthButtonDisabled,
                                        nextMonthButtonDisabled
                                      }) => (
                                        <div
                                          style={{
                                            margin: 10,
                                            display: "flex",
                                            justifyContent: "space-between"
                                          }}
                                        >
                                          <Button style={{height:"2em",padding:"0 .5em"}}
                                            onClick={decreaseMonth}
                                            disabled={prevMonthButtonDisabled}
                                          >
                                            <FaChevronLeft/>
                                          </Button>
                                          <h4>{this.handleMonth(date)}</h4>
                                          <select style={{borderRadius:"5px", padding:"0 .5em"}}
                                            value={getYear(date)}
                                            onChange={({ target: { value } }) => changeYear(value)}
                                          >
                                            {years.map(option => (
                                              <option key={option} value={option}>
                                                {option+543}
                                              </option>
                                            ))}
                                          </select>
                                          {/* {getYear(date)+543} */}
                          
                                          
                                          <Button style={{height:"2em",padding:"0 .5em"}}
                                            onClick={increaseMonth}
                                            disabled={nextMonthButtonDisabled}
                                          >
                                            <FaChevronRight/>
                                          </Button>
                                        </div>
                                      )}
                                    />
                              </Col>
                              <Col lg={4} xs={12} style={ !this.state.user.uRoleId || this.state.user.uRoleId == 50 || this.state.user.uRoleId == 51   ?{display:'none',marginTop:10}:{display:'block',marginTop:10} }>
                                  <label style={{color:'#a7a6a7'}}>เขตพื้นที่การศึกษา <span className="required">*</span></label>
                                  <Select 
                                      placeholder={''}
                                      id="obecAreaId"
                                      styles={
                                          {
                                          control: (styles, state) => (this.state.selectError?{ ...styles,
                                              backgroundColor: '#f9f9f9',
                                              borderColor:  state.isFocused? '#e1e1e1' : '#dc3545',
                                              '&:hover':   '#dc3545',
                                              boxShadow: state.isFocused? '0 0 0 0.1rem rgba(77, 144, 254,.25)': null,
                                              padding:5,
                                              border: '1px solid #e1e1e1',
                                              borderRadius:0
                                          } : { ...styles,
                                              backgroundColor: '#f9f9f9',
                                              borderColor: state.isFocused? '#e1e1e1': '#ced4da',
                                              boxShadow: state.isFocused? '0 0 0 0.1rem rgba(77, 144, 254,.25)': null,
                                              padding:".25em",
                                              border: '1px solid #e1e1e1',
                                              borderRadius:0
                                          })
                                          }
                                      }
                                      value={this.state.areaSelectValue}
                                      options={this.state.areaList}
                                      onChange={this.selectArea}
                                      isSearchable={false}
                                    />
                                    {/* <input type="text" style={{cursor:"no-drop"}} className="bg-white inputProfile" disabled
                                      value={this.state.uRoleName}
                                    /> */}
                              </Col>
                              <Col lg={4} xs={12} style={ !this.state.user.uRoleId || this.state.user.uRoleId == 50 || this.state.user.uRoleId == 51   ?{display:'none',marginTop:10}:{display:'block',marginTop:10} }>
                                  <label style={{color:'#a7a6a7'}}>โรงเรียน {(this.state.user.uRoleId == 52 ) ? <span className="required">*</span> : ''}</label>
                                  <Select 
                                      placeholder={''}
                                      id="obecSchoolId"
                                      styles={
                                          {
                                          control: (styles, state) => (this.state.selectError?{ ...styles,
                                              backgroundColor: '#f9f9f9',
                                              borderColor:  state.isFocused? '#e1e1e1' : '#dc3545',
                                              '&:hover':   '#dc3545',
                                              boxShadow: state.isFocused? '0 0 0 0.1rem rgba(77, 144, 254,.25)': null,
                                              padding:5,
                                              border: '1px solid #e1e1e1',
                                              borderRadius:0
                                          } : { ...styles,
                                              backgroundColor: '#f9f9f9',
                                              borderColor: state.isFocused? '#e1e1e1': '#ced4da',
                                              boxShadow: state.isFocused? '0 0 0 0.1rem rgba(77, 144, 254,.25)': null,
                                              padding:".25em",
                                              border: '1px solid #e1e1e1',
                                              borderRadius:0
                                          })
                                          }
                                      }
                                      value={this.state.schoolSelectValue}
                                      options={this.state.schoolList}
                                      onChange={this.selectSchool}
                                      isSearchable={false}
                                    />
                                    {/* <input type="text" style={{cursor:"no-drop"}} className="bg-white inputProfile" disabled
                                      value={this.state.uRoleName}
                                    /> */}
                              </Col>
                          </Row>

                          <Row style={{margin: '15px 0 0'}}>
                            <Col xs={12} style={{margin: 0}}>
                              <hr />
                            </Col>
                          </Row>

                          <Row style={{margin:0}}>
                            <Col xs={12} style={{marginTop:10}}>
                                <label style={{color:'#a7a6a7'}}>ที่อยู่ </label>
                                <input type="text" placeholder="" id="address" className="inputProfile" value={this.state.user.address}
                                onChange={e => this.modelData(e,"address")} />
                            </Col>
                          </Row>

                          <Row style={{margin:0}}>

                              <Col lg={4} xs={12} style={{marginTop:10}}>
                                  <label style={{color:'#a7a6a7'}}>จังหวัด <span className="required">*</span></label>
                                  <Select 
                                      placeholder={''}
                                      id="provinceId"
                                      styles={
                                          {
                                          control: (styles, state) => (this.state.selectError?{ ...styles,
                                              backgroundColor: '#f9f9f9',
                                              borderColor:  state.isFocused? '#e1e1e1' : '#dc3545',
                                              '&:hover':   '#dc3545',
                                              boxShadow: state.isFocused? '0 0 0 0.1rem rgba(77, 144, 254,.25)': null,
                                              padding:5,
                                              border: '1px solid #e1e1e1',
                                              borderRadius:0
                                          } : { ...styles,
                                              backgroundColor: '#f9f9f9',
                                              borderColor: state.isFocused? '#e1e1e1': '#ced4da',
                                              boxShadow: state.isFocused? '0 0 0 0.1rem rgba(77, 144, 254,.25)': null,
                                              padding:".25em",
                                              border: '1px solid #e1e1e1',
                                              borderRadius:0
                                          })
                                          }
                                      }
                                      value={this.state.provinceSelectValue}
                                      options={this.state.provinceList}
                                      onChange={this.selectProvince}
                                      isSearchable={false}
                                      isDisabled ={false}
                                    />
                                    
                              </Col>

                              <Col lg={4} xs={12} style={{marginTop:10}}>
                                  <label style={{color:'#a7a6a7'}}>อำเภอ </label>
                                  <Select 
                                      placeholder={''}
                                      id="amphurId"
                                      styles={
                                          {
                                          control: (styles, state) => (this.state.selectError?{ ...styles,
                                              backgroundColor: '#f9f9f9',
                                              borderColor:  state.isFocused? '#e1e1e1' : '#dc3545',
                                              '&:hover':   '#dc3545',
                                              boxShadow: state.isFocused? '0 0 0 0.1rem rgba(77, 144, 254,.25)': null,
                                              padding:5,
                                              border: '1px solid #e1e1e1',
                                              borderRadius:0
                                          } : { ...styles,
                                              backgroundColor: '#f9f9f9',
                                              borderColor: state.isFocused? '#e1e1e1': '#ced4da',
                                              boxShadow: state.isFocused? '0 0 0 0.1rem rgba(77, 144, 254,.25)': null,
                                              padding:".25em",
                                              border: '1px solid #e1e1e1',
                                              borderRadius:0
                                          })
                                          }
                                      }
                                      value={this.state.amphurSelectValue}
                                      options={this.state.amphurList}
                                      onChange={this.selectAmphur}
                                      isSearchable={false}
                                      isDisabled ={false}
                                    />
                                    
                              </Col>

                              <Col lg={4} xs={12} style={{marginTop:10}}>
                                  <label style={{color:'#a7a6a7'}}>ตำบล </label>
                                  <Select 
                                      placeholder={''}
                                      id="tamboId"
                                      styles={
                                          {
                                          control: (styles, state) => (this.state.selectError?{ ...styles,
                                              backgroundColor: '#f9f9f9',
                                              borderColor:  state.isFocused? '#e1e1e1' : '#dc3545',
                                              '&:hover':   '#dc3545',
                                              boxShadow: state.isFocused? '0 0 0 0.1rem rgba(77, 144, 254,.25)': null,
                                              padding:5,
                                              border: '1px solid #e1e1e1',
                                              borderRadius:0
                                          } : { ...styles,
                                              backgroundColor: '#f9f9f9',
                                              borderColor: state.isFocused? '#e1e1e1': '#ced4da',
                                              boxShadow: state.isFocused? '0 0 0 0.1rem rgba(77, 144, 254,.25)': null,
                                              padding:".25em",
                                              border: '1px solid #e1e1e1',
                                              borderRadius:0
                                          })
                                          }
                                      }
                                      value={this.state.tambolSelectValue}
                                      options={this.state.tambolList}
                                      onChange={this.selectTambol}
                                      isSearchable={false}
                                      isDisabled ={false}
                                    />
                                    
                              </Col>

                              <Col lg={4} xs={12} style={{marginTop:10}}>
                                <label style={{color:'#a7a6a7'}}>รหัสไปรษณีย์ </label>
                                <InputMask mask="99999" type="text" placeholder="" id="zipcode" className="inputProfile"
                                  value={this.state.user.zipcode} 
                                  onChange={e => this.modelData(e,"zipcode")}
                                  />
                              
                              </Col>
                          </Row>

                          <Row style={{margin: '15px 0 0'}}>
                            <Col xs={12} style={{margin: 0}}>
                              <hr />
                            </Col>
                          </Row>

                          <Row style={{margin:0}}>
                            <Col xs={12} style={{marginTop:10}}>
                                <label style={{color:'#a7a6a7'}}>คำแนะนำตัว </label>
                                <textarea rows="3" placeholder="" id="ucaption" className="form-control inputProfile"
                                value={this.state.user.caption}
                                onChange={e => this.modelData(e,"caption")}>
                                </textarea>
                            </Col>
                          </Row>
                          </form>
                      </Col>

                      
                      <Col xs={2} style={{padding:20,marginTop:10}}></Col>
                      <Col xs={4} sm={4} style={{padding:20,marginTop:10,float:'right'}} className="edit-btn-group">
                      {this.renderRedirect()}

                      

                      <Button className="buttonGray" 
                          style={{boxShadow:'0 0 10px #dcdcdc',paddingLeft:30,paddingRight:30,paddingTop:10,paddingBottom:10}}
                          onClick={e => this.cancel()}>
                          ยกเลิก
                      </Button>

                      <Button className="buttonGreen" 
                          style={{boxShadow:'0 0 10px #dcdcdc',paddingLeft:30,paddingRight:30,paddingTop:10,paddingBottom:10,marginLeft:20}}
                          onClick={e => this.validate()}>
                          บันทึก
                      </Button>
                      </Col>
                      <Col xs={12} sm={4} style={{padding:20,marginTop:10}}>
                      <Alert style={{marginBottom:'.5rem'}}
                        show={this.state.alertShow} variant={this.state.variant}>
                        {this.state.message}
                      </Alert>
                      </Col>            
                  </Row>
            </Col>
          </Row>
          </Container>
      </Col>
    </Row>

    <Modal show={this.state.showSetPass} onHide={this.setPassClose} size="sm" id="change-password" aria-labelledby="contained-modal-title-vcenter" centered>
        <button onClick={this.setPassClose} type="button" className="close" style={{position:'absolute',right:0,top:-50,color:'#ffffff',fontSize: '3em'}}><span>×</span></button>
        <Modal.Body>
            <h5 style={{textAlign:'center'}}>เปลี่ยนรหัสผ่าน</h5>
            <Row style={{ marginTop: 10, padding: 10 }}>
                <Col xs={12} style={{ marginTop: 10 }}>
                    <Alert style={{marginBottom:'.5rem'}}
                      show={this.state.alertPwd} variant="warning">
                      {this.state.message}
                    </Alert>
                    <label style={{ color: '#a7a6a7' }}>รหัสผ่านเก่า</label>
                    <input type="password" name="oldpassword" id="oldpassword" placeholder="ระบุรหัสผ่านเก่า" className="inputProfile" 
                    onChange={e => this.setState({ [e.target.name]: e.target.value.trim() })}/>
                </Col>
                <Col xs={12} style={{ marginTop: 10 }}>
                    <label style={{ color: '#a7a6a7' }}>รหัสผ่านใหม่</label>
                    <input type="password" name="newpassword" id="newpassword" placeholder="ระบุรหัสผ่านใหม่" className="inputProfile" 
                    onChange={e => this.setState({ [e.target.name]: e.target.value.trim() })}/>
                </Col>
                <Col xs={12} style={{ marginTop: 10 }}>
                    <label style={{ color: '#a7a6a7' }}>ยืนยันรหัสผ่านใหม่</label>
                    <input type="password" name="cfpassword" id="cfpassword" placeholder="ระบุรหัสผ่านใหม่อีกครั้ง" className="inputProfile" 
                    onChange={e => this.setState({ [e.target.name]: e.target.value.trim() })}/>
                </Col>
            </Row>
        </Modal.Body>
        <Modal.Footer style={{justifyContent: 'center',padding:0}}>
            <Button variant="success" onClick={this.pwdValidate} style={{width:'100%',paddingTop:10,paddingBottom:10,borderRadius:0}}>ยืนยัน</Button>
        </Modal.Footer>
    </Modal>
    </>
  );
}
}

export default ProfileEdit;
