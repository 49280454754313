import React from 'react';
import { Button,Row,Col,Modal,Tabs,Tab } from 'react-bootstrap';
import axios from 'axios';
import Loader from '../../component/loader';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';

class Books extends React.Component {
    constructor(props, context) {
        super(props, context);
        // console.log(props);
        this.handleShow = this.handleShow.bind(this);
        this.handleClose = this.handleClose.bind(this);

        this.state = {
            loading: false,
            isLogin:false,
            user:{},
            show: false,
            checked: false,
            cType:'',
            contentList:[],
            cateClass:[],
            cateDewey:[],
            cateSubject:[],
            cateKnowledges:[],
            cateIndicator:[],
            catePublish:[],
            sortBy:4,
            asc:false,
            page:0,
            perpage : 24,
            totalPage:0,
            classCheck:[],
            subjectCheck:[],
            knowledgesCheck:[],
            indicatorCheck:[],
            deweyCheck:[],
            publishCheck:[],
            categorys:{},
            hits: props.hits,
            tagId: props.match.params.handle,
            filterName:[],
        };
    }

    componentDidMount(){
        var islogin = JSON.parse(localStorage.getItem('islogin'));
        var token = "1000000000"
        if(islogin){
            this.setState({ isLogin: true,user:islogin});
            token = islogin.token
        }else{
            this.setState({ isLogin: false,user:{}});
        }

        // console.log(this.props.match.params.handle);
        if(this.props.tabCheck){
            this.setState({cType:this.props.match.params.handle});
            this.getContents(this.props.match.params.handle, this.state.asc, this.state.sortBy, this.state.page,token);
        }else{
            this.setState({cType:1});
            this.getContents(1, this.state.asc, this.state.sortBy, this.state.page,token);
        }
        this.getCategory();
    }
  
    componentWillReceiveProps(newProps){
        if(newProps.match.params.handle != this.props.match.params.handle){
            // console.log(newProps.match.params.handle+'!='+this.props.match.params.handle);
            this.setState({
                cType:newProps.match.params.handle, asc:false, sortBy: 4, page: 0,
                categorys:{}, 
                filterName:[],
                classCheck:[],
                subjectCheck:[],
                knowledgesCheck:[],
                indicatorCheck:[],
                deweyCheck:[],
                publishCheck:[],
            });
            this.getContents(newProps.match.params.handle, false, 4, 0,this.state.user.token);
        }
        if(newProps.searchWord !== '' && newProps.searchWord !== this.props.searchWord){
            this.props.history.push('/search', newProps.searchWord);
          }
    }

    handleClose() {
      this.setState({ show: false });
    }
  
    handleShow() {
      this.setState({ show: true });
    }

    setSortBy(sort){
        let cdata = this.state.categorys;
        cdata = (cdata.length > 0)? cdata : null;

        if(sort != this.state.sortBy){
            if(sort != 0){
                this.setState({sortBy:sort,asc:false});
                this.getContents(this.state.cType, false, sort, this.state.page,this.state.user.token,cdata);
            }else{
                this.setState({sortBy:sort,asc:true});
                this.getContents(this.state.cType, true, sort, this.state.page,this.state.user.token,cdata);
            }
        }  
    }

    sortChange = (e) => {
        let {name, value} = e.target;
        this.setSortBy(value)
      }

    getContents(ct,asc,sortBy,page,token,data) {
        // console.log(data);
        this.setState({ loading: true });
        this.setState({contentList:[],totalPage:0})
        const api = axios.create({
            baseURL: this.props.mainpoint,
            headers: {
              "Content-Type": "application/json",
              "token":token
            },
            timeout: 85000
          });

        // console.log(this.state.hits)

        if(this.state.hits){
            api.get('/api/v1/contents/tag/'+this.state.tagId+'/ctypes/'+ct+'/page/'+page+'/limit/'+this.state.perpage+'/asc/'+asc+'/sortBy/'+sortBy,data).then(
                success => {
                    this.setState({ loading: false });
                    const { status, data } = success
                    // console.log(status);
                    if (status === 201 || status === 200) {
                        // console.log(data);
                        this.setState({contentList:data.result})
                        if(page == this.state.page){
                            this.setState({totalPage:data.pagination.totalPage})
                        }
                    } else {
                        console.error('Error',status)
                    }
                },
                error => {
                    this.setState({ loading: false });
                    console.error('Error',error)
                }
            );
        }else{
            // console.log(data);
            if(data){
                // console.log('has data');
                // console.log(data);
                // console.log('/api/v1/filter/ctypes/'+ct+'/page/'+page+'/limit/'+this.state.perpage+'/asc/'+asc+'/sortBy/'+sortBy);
                api.post('/api/v1/filter/ctypes/'+ct+'/page/'+page+'/limit/'+this.state.perpage+'/asc/'+asc+'/sortBy/'+sortBy,data).then(
                    success => {
                        this.setState({ loading: false });
                        const { status, data } = success
                        // console.log(success);
                        if (status === 201 || status === 200) {
                            // console.log(data);
                            this.setState({contentList:data.result})
                            if(page == this.state.page){
                                this.setState({totalPage:data.pagination.totalPage})
                            }
                        } else {
                            console.error('Error',status)
                        }
                    },
                    error => {
                        this.setState({ loading: false });
                        console.error('Error',error)
                    }
                );
            }else{
                // console.log('no data');
                // console.log(token);
                // console.log('/api/v1/contents/ctypes/'+ct+'/ids/0/page/'+page+'/limit/'+this.state.perpage+'/asc/'+asc+'/sortBy/'+sortBy);
                api.get('/api/v1/contents/ctypes/'+ct+'/ids/0/page/'+page+'/limit/'+this.state.perpage+'/asc/'+asc+'/sortBy/'+sortBy).then(
                    success => {
                        this.setState({ loading: false });
                        const { status, data } = success
                        // console.log(status);
                        if (status === 201 || status === 200) {
                            // console.log(data);
                            this.setState({contentList:data.result})
                            if(page == this.state.page){
                                this.setState({totalPage:data.pagination.totalPage})
                            }
                        } else {
                            console.error('Error',status)
                        }
                    },
                    error => {
                        this.setState({ loading: false });
                        console.error('Error',error)
                    }
                );
            }
        }
    }

    setContents(){
        if (this.state.contentList.length > 0) {
            const items = [];
            var maxHtype = "imgOrg imgType" + this.state.cType;
            var maxH = '';
            // console.log(this.state.cType);
            if(this.state.cType === '1'){ maxH = '207px'} else 
            if(this.state.cType === '3'){maxH = '116px'} else
            {maxH = '155px'}

            // console.log(this.state.contentList);
            for (var i = 0; i < this.state.contentList.length; i++) {
                let cid = this.state.contentList[i].cId+'0'+i
                items.push(<Col className="mb-2" key={cid}>
                    <div className="card border-0 mb-2">
                    <Link to={"/detail/"+this.ctypeName(this.state.contentList[i].ctypeId)+"/"+this.state.contentList[i].cId} title={this.state.contentList[i].name}>
                        {/* <div style={{display: 'block', width:'100%',height:'100%', minHeight: maxH, overflow:'hidden',
                        backgroundImage: "url('"+this.state.contentList[i].imgUrl+"')",backgroundRepeat:'no-repeat',
                        backgroundSize:'cover',backgroundPosition:'center',position:'relative'
                        }}>
                        </div> */}

                        <img src={this.state.contentList[i].imgUrl ? this.state.contentList[i].imgUrl : require("../../img/no_image.png")} alt=""
                         className={maxHtype}
                        />
                        {this.state.cType == 3 && <div className="playVdo"><img src={require("../../img/video_ic.png")} alt=""/></div>}

                        <p className="pt-1 pb-4 card-text">
                            {this.state.contentList[i].name}
                        </p>
                    </Link>
                    </div>
                </Col>)
            }
            return (
                <Row className="row-cols-2 row-cols-sm-4 row-cols-md-6 my-3 contents-list">
                    {items}
                </Row>
            )
        }else{
            return (
                <Row style={{marginTop: 10,marginLeft:0,marginRight:0}}>
                  <Col xs={12} style={{textAlign:'center',padding:20}}>ไม่มีเนื้อหา</Col>
                </Row>
              )
        }
    }

    setPageNumb(){
        if (this.state.totalPage > 1) {
            const paging = this.pagination(this.state.page+1, this.state.totalPage, 2);
            const items = [];
            for (var i = 0; i < paging.length; i++) {
                let pageNum = paging[i]-1;
                if(paging[i] != '...'){
                    items.push(
                        <Button  onClick={() => this.onclickPage(pageNum)} key={"page"+pageNum}
                        className={this.state.page == pageNum ? "buttonPageActive" : "buttonPage"} 
                        style={{ marginLeft: 5, flex: 1 }}>{paging[i]}</Button>
                    )
                }else{

                    items.push(
                        <label key={"pageNan"+i}style={{ marginLeft: 5,flex:1,padding:5}}>{paging[i]}</label>
                    )
                }
            }
            return (
                <>
                <Button onClick={() => this.onclickPage(this.state.page - 1)} className="buttonPage" style={this.state.page === 0 ? {marginLeft: 5, flex: 1,backgroundColor: '#e1e1e1', border: 'none'} : {marginLeft: 5, flex: 1}} disabled={this.state.page === 0 ? true : false}>ก่อนหน้า</Button>
                 {items}
                 <Button onClick={() => this.onclickPage(this.state.page + 1)} className="buttonPage" style={this.state.page === this.state.totalPage-1 ? {marginLeft: 5, flex: 1,backgroundColor: '#e1e1e1', border: 'none'} : {marginLeft: 5, flex: 1}} disabled={this.state.page === this.state.totalPage-1 ? true : false}>ถัดไป</Button>
                </>
            )
        }
    }

    onclickPage(p){
        let cdata = this.state.categorys;
        if(p > -1 && p < this.state.totalPage){
            if(p != this.state.page){
                this.setState({page:p});
                if(cdata){
                    this.getContents(this.state.cType, this.state.asc, this.state.sortBy, p, this.state.user.token, cdata);
                } else {
                    this.getContents(this.state.cType, this.state.asc, this.state.sortBy, p, this.state.user.token);
                }
            }
        }else{
            this.getContents(this.state.cType, this.state.asc, this.state.sortBy, p, this.state.user.token);
        }
    }

    getCategory(){
        axios.get(this.props.mainpoint+'/api/v1/categories/3').then(
            success => {
                const { status, data } = success
                // console.log(status);
                if (status === 201 || status === 200) {
                    // console.log(data);
                    this.setState({cateClass:data.result})
                } else {
                    console.error('Error',status)
                }
            },
            error => {
                console.error('Error',error)
            }
        );
        axios.get(this.props.mainpoint+'/api/v1/categories/1').then(
            success => {
                const { status, data } = success
                // console.log(status);
                if (status === 201 || status === 200) {
                    // console.log(data);
                    this.setState({cateDewey:data.result})
                } else {
                    console.error('Error',status)
                }
            },
            error => {
                console.error('Error',error)
            }
        );
        axios.get(this.props.mainpoint+'/api/v1/categories/2').then(
            success => {
                const { status, data } = success
                // console.log(status);
                if (status === 201 || status === 200) {
                    // console.log(data);
                    this.setState({cateSubject:data.result})
                } else {
                    console.error('Error',status)
                }
            },
            error => {
                console.error('Error',error)
            }
        );
        axios.get(this.props.mainpoint+'/api/v1/publishers/0').then(
            success => {
                const { status, data } = success
                // console.log(status);
                if (status === 201 || status === 200) {
                    // console.log(data);
                    this.setState({catePublish:data.result})
                } else {
                    console.error('Error',status)
                }
            },
            error => {
                console.error('Error',error)
            }
        ); 
    }

    clearChecked(){
        // console.log('clear checked');
        this.setState({ classCheck:[], subjectCheck:[], deweyCheck:[], publishCheck:[], categorys:{}});
    }

    setCateClass(){
        // console.log(this.state.cateClass);
        if (this.state.cateClass.length > 0) {
            const items = [];
            for (var i = 0; i < this.state.cateClass.length; i++) {
              if(this.state.cateClass[i].parentId != 0){
                let cId = this.state.cateClass[i].id;
                let cName = this.state.cateClass[i].name;
                items.push(<Col lg={3} xs={6} key={this.state.cateClass[i].id}>
                    <div className="checkBox">
                        <label>
                            <input type="checkbox" name={"cateClass"+this.state.cateClass[i].id} value={this.state.cateClass[i].id} 
                            onChange={() => this.onCheckCate(cId,"c",cName)}  checked={this.checkedCate(cId,"c")} style={{borderRadius:0}}/>
                            {this.state.cateClass[i].name}
                        </label>
                    </div>
                </Col>)
              }
            }
            return (
              <>
                <Row style={{marginTop:10,padding:10,minHeight:250}}>
                    {items}          
                </Row>
              </>
            )
          }
    }

    setCateSubject(){
        if (this.state.cateSubject.length > 0) {
            const items = [];
            for (var i = 0; i < this.state.cateSubject.length; i++) {
              if(this.state.cateSubject[i].parentId != 0){
                let cId = this.state.cateSubject[i].id;
                let cName = this.state.cateSubject[i].name;
                items.push(<Col lg={6} xs={12} key={this.state.cateSubject[i].id}>
                    <div className="checkBox">
                        <label>
                            <input type="checkbox" name={"cateSubject"+this.state.cateSubject[i].id} value={this.state.cateSubject[i].id}
                            onChange={() => this.onCheckCate(cId,"s",cName)}  checked={this.checkedCate(cId,"s")} style={{borderRadius:0}}/>
                            {this.state.cateSubject[i].name }
                        </label>
                    </div>
                </Col>)
              }
            }
            return (
              <>
                <Row style={{marginTop:10,padding:10,minHeight:250}}>
                    {items}          
                </Row>
              </>
            )
          }
    }

    setCateKnowledges(){
        if (this.state.cateKnowledges.length > 0) {
            const items = [];
            for (var i = 0; i < this.state.cateKnowledges.length; i++) {
                let cId = this.state.cateKnowledges[i].id;
                let cName = this.state.cateKnowledges[i].name;
                let subjectId = this.state.cateKnowledges[i].subjectId
                items.push(<Col lg={6} xs={12} key={this.state.cateKnowledges[i].id} key={i}>
                    <div className="checkBox" style={{whiteSpace: 'normal'}}>
                        <label>
                            <input type="checkbox" name={"cateKnowledges"+cId} value={cId}
                            onChange={() => this.onCheckCate(cId,"k",cName, subjectId)}  checked={this.checkedCate(cId,"k")} style={{borderRadius:0}}/>
                            {cName}
                        </label>
                    </div>
                </Col>)
            }
            return (
                <Row style={{marginTop:10,padding:10,minHeight:250}}>
                    {items}          
                </Row>
            )
        }
    }

    setCateIndicator(){
        if (this.state.cateIndicator.length > 0) {
            const items = [];
            for (var i = 0; i < this.state.cateIndicator.length; i++) {
              if(this.state.cateIndicator[i].parentId != 0){
                let cId = this.state.cateIndicator[i].indId;
                let cName = this.state.cateIndicator[i].name;
                items.push(<Col lg={6} xs={12} key={this.state.cateIndicator[i].id} key={i}>
                    <div className="checkBox">
                        <label>
                            <input type="checkbox" name={"cateIndicator"+this.state.cateIndicator[i].id} value={this.state.cateIndicator[i].id}
                            onChange={() => this.onCheckCate(cId,"i",cName)}  checked={this.checkedCate(cId,"i")} style={{borderRadius:0}}/>
                            {this.state.cateIndicator[i].name }
                        </label>
                    </div>
                </Col>)
              }
            }
            return (
              <>
                <Row style={{marginTop:10,padding:10,minHeight:250}}>
                    {items}          
                </Row>
              </>
            )
          }
    }

    setCateDewey(){
        if (this.state.cateDewey.length > 0) {
            const items = [];
            for (var i = 0; i < this.state.cateDewey.length; i++) {
              if(this.state.cateDewey[i].parentId != 0){
                  if(this.state.cateDewey[i].parentId == 1){
                    let parent = this.state.cateDewey[i].id;
                    const cildItem = []
                    for (var l = 0; l < this.state.cateDewey.length; l++) {
                        if(this.state.cateDewey[l].parentId == parent){
                            let cId = this.state.cateDewey[l].id;
                            let cName = this.state.cateDewey[l].name;
                            cildItem.push(
                                <Col lg={6} xs={12} key={this.state.cateDewey[l].id}>
                                    <div className="checkBox">
                                        <label>
                                            <input type="checkbox" name={"cateDewey"+this.state.cateDewey[l].id} value={this.state.cateDewey[l].id}
                                            onChange={() => this.onCheckCate(cId,"d",cName)}  checked={this.checkedCate(cId,"d")} style={{borderRadius:0}} />
                                            {this.state.cateDewey[l].name }
                                        </label>
                                    </div>
                                </Col>
                            )
                        }
                    }
                    items.push(<Row key={this.state.cateDewey[i].id} style={{margin:0}}>
                        <Col xs={12}>
                            <label>{this.state.cateDewey[i].name }</label>
                        </Col>
                        {cildItem}
                    </Row>)
                  }
              }
            }
            return (
              <>
                <Row style={{marginTop:10,padding:10,minHeight:250}}>
                    <Col style={{padding:0}}>
                    {items}     
                    </Col>   
                </Row>
              </>
            )
          }
    }

    setCatePublish(){
        if (this.state.catePublish.length > 0) {
            const items = [];
            for (var i = 0; i < this.state.catePublish.length; i++) {
                let cId = this.state.catePublish[i].pubId;
                let cName = this.state.catePublish[i].name;
                items.push(<Col xs={12} key={this.state.catePublish[i].pubId}>
                    <div className="checkBox">
                        <label>
                            <input type="checkbox" name={"catePublish"+this.state.catePublish[i].pubId} value={this.state.catePublish[i].pubId}
                            onChange={() => this.onCheckCate(cId,"p",cName)}  checked={this.checkedCate(cId,"p")} style={{borderRadius:0}} />
                            <span>{this.state.catePublish[i].name }</span>
                        </label>
                    </div>
                </Col>)
            }
            return (
              <>
                <Row style={{marginTop:10,padding:10,minHeight:250}}>
                    {items}          
                </Row>
              </>
            )
          }
    }

    checkedCate(ck,t){
        if(t === "c"){
            for(let i=0;i<this.state.classCheck.length;i++){
                if(ck == this.state.classCheck[i].id){
                    return true;
                    break;
                }
            }
            return false;
        }

        if(t === "s"){
            for(let i=0;i<this.state.subjectCheck.length;i++){
                if(ck == this.state.subjectCheck[i].id){
                    return true;
                    break;
                }
            }
            return false;
        }

        if(t === "k"){
            for(let i=0;i<this.state.knowledgesCheck.length;i++){
                if(ck == this.state.knowledgesCheck[i].id){
                    return true;
                    break;
                }
            }
            return false;
        }

        if(t === "i"){
            for(let i=0;i<this.state.indicatorCheck.length;i++){
                if(ck == this.state.indicatorCheck[i].id){
                    return true;
                    break;
                }
            }
            return false;
        }

        if(t === "d"){
            for(let i=0;i<this.state.deweyCheck.length;i++){
                if(ck == this.state.deweyCheck[i].id){
                    return true;
                    break;
                }
            }
            return false;
        }

        if(t === "p"){
            for(let i=0;i<this.state.publishCheck.length;i++){
                if(ck == this.state.publishCheck[i].id){
                    return true;
                    break;
                }
            }
            return false;
        }
    }

    onCheckCate(checked,t,cName,subjectId){
        if(t === "c"){
            const check = this.state.classCheck;
            if(this.state.classCheck.length > 0){
                var has;
                var index;
                for(let i=0;i<check.length;i++){
                    if(checked == check[i].id){
                        has = true;
                        index = i;
                        break;
                    }
                    else{has = false;}
                }
                if(has){check.splice(index,1);}
                else{check.push({id:checked,name:cName});}
            }else{
                check.push({id:checked,name:cName});
            }
            this.setState({classCheck:check});
            this.getKnowledges()
        }
        
        if(t === "s"){
            const check = this.state.subjectCheck;
            if(this.state.subjectCheck.length > 0){
                var has;
                var index;
                for(let i=0;i<check.length;i++){
                    if(checked == check[i].id){
                        has = true;
                        index = i;
                        break;
                    }
                    else{has = false;}
                }
                if(has){check.splice(index,1);}
                else{check.push({id:checked,name:cName,});}
            }else{
                check.push({id:checked,name:cName});
            }
            this.setState({subjectCheck:check});
            this.getKnowledges()
        }

        if(t === "k"){
            const check = this.state.knowledgesCheck;
            if(this.state.knowledgesCheck.length > 0){
                var has;
                var index;
                for(let i=0;i<check.length;i++){
                    if(checked == check[i].id){
                        has = true;
                        index = i;
                        break;
                    }
                    else{has = false;}
                }
                if(has){check.splice(index,1);}
                else{check.push({id:checked, name:cName, subjectId:subjectId});}
            }else{
                check.push({id:checked, name:cName, subjectId:subjectId});
            }
            this.setState({knowledgesCheck:check});
            this.getIndicator();
        }

        if(t === "i"){
            const check = this.state.indicatorCheck;
            if(this.state.indicatorCheck.length > 0){
                var has;
                var index;
                for(let i=0;i<check.length;i++){
                    if(checked == check[i].id){
                        has = true;
                        index = i;
                        break;
                    }
                    else{has = false;}
                }
                if(has){check.splice(index,1);}
                else{check.push({id:checked,name:cName});}
            }else{
                check.push({id:checked,name:cName});
            }
            this.setState({indicatorCheck:check});
        }

        if(t === "d"){
            const check = this.state.deweyCheck;
            if(this.state.deweyCheck.length > 0){
                var has;
                var index;
                for(let i=0;i<check.length;i++){
                    if(checked == check[i].id){
                        has = true;
                        index = i;
                        break;
                    }
                    else{has = false;}
                }
                if(has){check.splice(index,1);}
                else{check.push({id:checked,name:cName});}
            }else{
                check.push({id:checked,name:cName});
            }
            this.setState({deweyCheck:check});
        }

        if(t === "p"){
            const check = this.state.publishCheck;
            if(this.state.publishCheck.length > 0){
                var has;
                var index;
                for(let i=0;i<check.length;i++){
                    if(checked == check[i].id){
                        has = true;
                        index = i;
                        break;
                    }
                    else{has = false;}
                }
                if(has){check.splice(index,1);}
                else{check.push({id:checked,name:cName});}
            }else{
                check.push({id:checked,name:cName});
            }
            this.setState({publishCheck:check});
        }
    }

    onCateSubmit=async()=>{
        var ctClass = '';
        var ctSubject = '';
        var ctDewey = '';
        var ctPublish = '';
        var ctKnowledges = '';
        var ctIndicator = '';
        var filterName = [];
        if(this.state.classCheck.length > 0 || 
           this.state.subjectCheck.length > 0 || 
           this.state.deweyCheck.length > 0 || 
           this.state.publishCheck.length > 0 ){
            
            for(var i=0;i < this.state.classCheck.length;i++){
                if(i == 0){
                    ctClass = this.state.classCheck[i].id;
                }else{
                    ctClass = ctClass+','+this.state.classCheck[i].id;
                }
                filterName.push(this.state.classCheck[i].name);
            }

            for(var i=0;i < this.state.subjectCheck.length;i++){
                if(i == 0){
                    ctSubject = this.state.subjectCheck[i].id;
                }else{
                    ctSubject = ctSubject+','+this.state.subjectCheck[i].id;   
                }
                filterName.push(this.state.subjectCheck[i].name);
            }

            for(var i=0;i < this.state.deweyCheck.length;i++){
                if(i == 0){
                    ctDewey = this.state.deweyCheck[i].id;
                }else{
                    ctDewey = ctDewey+','+this.state.deweyCheck[i].id;
                }
                filterName.push(this.state.deweyCheck[i].name);
            }

            for(var i=0;i < this.state.publishCheck.length;i++){
                if(i == 0){
                    ctPublish = this.state.publishCheck[i].id;
                }else{
                    ctPublish = ctPublish+','+this.state.publishCheck[i].id;
                }
                filterName.push(this.state.publishCheck[i].name);
            }

            for(var i=0;i < this.state.knowledgesCheck.length;i++){
                if(i == 0){
                    ctKnowledges = this.state.knowledgesCheck[i].id;
                }else{
                    ctKnowledges = ctKnowledges+','+this.state.knowledgesCheck[i].id;
                }
                filterName.push(this.state.knowledgesCheck[i].name);
            }

            for(var i=0;i < this.state.indicatorCheck.length;i++){
                if(i == 0){
                    ctIndicator = this.state.indicatorCheck[i].id;
                }else{
                    ctIndicator = ctIndicator+','+this.state.indicatorCheck[i].id;
                }
                filterName.push(this.state.indicatorCheck[i].name);
            }

            var data = {
                "classes": ""+ctClass,
                "deweies": ""+ctDewey,
                "publishers": ""+ctPublish,
                "subjects": ""+ctSubject,
                "stdKnowledgeIds": ""+ctKnowledges,
                "indicatorIds": ""+ctIndicator
            }

            await this.setState({categorys:data,filterName:filterName, page: 0});
            this.getContents(this.state.cType,this.state.asc,this.state.sortBy,this.state.page,this.state.user.token,data);
            this.handleClose();
            // this.clearChecked();
        }else{
            await this.setState({categorys:{},filterName:[], page: 0});
            this.getContents(this.state.cType,this.state.asc,this.state.sortBy,this.state.page,this.state.user.token);
            this.handleClose();
        }
        
    }

    setFilterName(){
        if(this.state.filterName.length > 0){
            const items = [];
            for (var i = 0; i < this.state.filterName.length; i++) {
                items.push(<label key={i} className="bg-light p-2 mr-1">
                    {this.state.filterName[i]}
                </label>)
            }
            return (
                <>
                    {items}          
                </>
            )
        }
    }

    getKnowledges=async()=>{
        const {classCheck, subjectCheck} = this.state
        if(classCheck.length > 0 && subjectCheck.length > 0){
            let knowledge = []
            for(var i=0;i<subjectCheck.length;i++){
                await axios.get(this.props.mainpoint+'/api/v1/stdknowledges/'+subjectCheck[i].id).then(
                    success => {
                        const { status, data } = success
                        // console.log(status);
                        if (status === 201 || status === 200) {
                            let res = data.result
                            // if(knowledge.length > 0){
                                for(var l=0;l<res.length;l++){
                                    const a = res[l]
                                    knowledge.push({
                                        id: a.stdKnowledgeId,
                                        name: a.name,
                                        subjectId: subjectCheck[i].id
                                    })
                                }
                            // }else{
                            //     knowledge = res
                            // }   
                        } else {
                            console.error('Error',status)
                        }
                    },
                    error => {
                        console.error('Error',error)
                    }
                );
            }
            this.setState({cateKnowledges: knowledge})
        }else{
            this.setState({cateKnowledges:[], knowledgesCheck: []})
        }
    }

    getIndicator=async()=>{
        const {classCheck, subjectCheck, knowledgesCheck} = this.state
        if(knowledgesCheck.length > 0){
            let indicator = []
            for(var i=0;i<knowledgesCheck.length;i++){
                for(var l=0;l<classCheck.length;l++){
                    await axios.get(this.props.mainpoint+'/api/v1/indicators/'+knowledgesCheck[i].id+'/subject/'+knowledgesCheck[i].subjectId+'/class/'+classCheck[l].id).then(
                        success => {
                            const { status, data } = success
                            // console.log(status);
                            if (status === 201 || status === 200) {
                                let res = data.result
                                // console.log(res)
                                if(indicator.length > 0){
                                    for(var l=0;l<res.length;l++){
                                        indicator.push(res[l])
                                    }
                                }else{
                                    indicator = res
                                }   
                            } else {
                                console.error('Error',status)
                            }
                        },
                        error => {
                            console.error('Error',error)
                        }
                    );
                }
            }
            this.setState({cateIndicator: indicator})
        }else{
            this.setState({cateIndicator:[], indicatorCheck: []})
        }
    }

    render() {
      return (
        <>
        <Loader loading={this.state.loading}/>
        <Row className="mt-3">
            <Col sm={12}>
                <Row>
                    <Col xs={6}>
                        {this.state.cType == 1 && <label style={{ marginTop: 5, flex: 1 }}>หนังสือ</label>}
                        {this.state.cType == 2 && <label style={{ marginTop: 5, flex: 1 }}>แอปพลิเคชัน</label>}
                        {this.state.cType == 3 && <label style={{ marginTop: 5, flex: 1 }}>วีดีโอ</label>}
                        {this.state.cType == 4 && <label style={{ marginTop: 5, flex: 1 }}>รูปภาพ</label>}
                        {this.state.cType == 5 && <label style={{ marginTop: 5, flex: 1 }}>เสียง</label>}
                        {this.state.cType == 6 && <label style={{ marginTop: 5, flex: 1 }}>มัลติมีเดีย</label>}
                        {this.state.cType == 8 && <label style={{ marginTop: 5, flex: 1 }}>ข้อสอบ</label>}
                        <Button onClick={this.handleShow} className="buttonFilter" style={{ marginLeft: 10,flex:1,marginRight:10}}>
                            <img src={require("../../img/play.png")} alt="" />ตัวกรอง
                        </Button>
                    </Col>
                    <Col xs={6} style={{ textAlign: 'right' }} className="hiddenXs">
                        <label>เรียงตาม</label>
                        <Button onClick={() => this.setSortBy(4)} className={this.state.sortBy == 4 ? "buttonSortActive" : "buttonSort"} style={{ marginLeft: 10, flex: 1 }}>มาใหม่</Button>
                        <Button onClick={() => this.setSortBy(2)} className={this.state.sortBy == 2 ? "buttonSortActive" : "buttonSort"} style={{ marginLeft: 10, flex: 1 }}>ดาวน์โหลด</Button>
                        <Button onClick={() => this.setSortBy(3)} className={this.state.sortBy == 3 ? "buttonSortActive" : "buttonSort"} style={{ marginLeft: 10, flex: 1 }}>ยอดนิยม</Button>
                    </Col>
                    <Col xs={6} style={{textAlign:'right'}} className="showXs">
                        <label>เรียงตาม</label>
                        <select className="buttonSortActive" 
                        style={{ marginLeft:10,flex:1,paddingLeft:15,paddingRight:0,paddingBottom:5,paddingTop:5,marginTop:3,color: 'white'}}
                        value={this.state.sortBy}
                        onChange={this.sortChange}
                        >
                            <option value='4'>มาใหม่</option>
                            <option value='2'>ดาวน์โหลด</option>
                            <option value='3'>ยอดนิยม</option>
                        </select>
                    </Col>
                    {this.state.filterName.length > 0 && <div className="mt-3"><Col xs={12}>
                        {this.setFilterName()}
                    </Col></div>}
                </Row>
                {this.setContents()}
                <Row className="my-3">
                    <Col xs={12} className="text-center">
                        {/* <Button onClick={() => this.onclickPage(this.state.page - 1)} className="buttonPage" style={{ marginLeft: 5, flex: 1 }}>ก่อนหน้า</Button> */}
                        {this.setPageNumb()}
                        {/* <Button onClick={() => this.onclickPage(this.state.page + 1)} className="buttonPage" style={{ marginLeft: 5, flex: 1 }}>ถัดไป</Button> */}
                    </Col>
                </Row>
            </Col>
        </Row>

        <Modal show={this.state.show} onHide={this.handleClose} size="lg"aria-labelledby="contained-modal-title-vcenter" centered >
            <Modal.Header>
                <Modal.Title>ตัวกรองหมวดหมู่</Modal.Title>
                <a onClick={() => this.clearChecked()} className='outlineGreen' style={{borderRadius:0,position:'absolute',right:10,color:'#07b169',cursor:'pointer'}}>ล้าง</a>
                <button onClick={this.handleClose} type="button" className="close" style={{position:'absolute',right:0,top:-50,color:'#ffffff',fontSize: '3em'}}><span>×</span></button>
            </Modal.Header>
            <Modal.Body>
                <Tabs defaultActiveKey="tab1" id="uncontrolled-tab-example">
                    <Tab eventKey="tab1" title="ระดับชั้น">
                        {this.setCateClass()}
                    </Tab>
                    <Tab eventKey="tab2" title="กลุ่มสาระการเรียนรู้">
                        {this.setCateSubject()}
                    </Tab>

                    {this.state.classCheck.length > 0 && this.state.subjectCheck.length > 0 &&
                    <Tab eventKey="tab3" title="มาตราฐานการเรียนรู้">
                        {this.setCateKnowledges()}
                    </Tab>
                    }

                    {this.state.knowledgesCheck.length > 0 &&
                    <Tab eventKey="tab4" title="ดัชนีชี้วัด">
                        {this.setCateIndicator()}
                    </Tab>
                    }
    
                    <Tab eventKey="tab5" title="ทศนิยมดิวอี้">
                        {this.setCateDewey()}
                    </Tab>
                    {/* <Tab eventKey="tab6" title="หน่วยงาน/สำนักพิมพ์">
                        {this.setCatePublish()}
                    </Tab> */}
                </Tabs>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="danger" onClick={this.handleClose}>
                    ยกเลิก
                </Button>
                <Button variant="success" onClick={() => this.onCateSubmit()}>
                    ยืนยัน
                </Button>
            </Modal.Footer>
        </Modal>
        </>
      );
    }

    pagination(c, m, r=2) {  //c หน้าปัจจุบัน  m จำนวนหน้าทั้งหมด r  ระยะของช่วงปุ่มเลขหน้า
        let current = c,
            last = m,
            delta = r,
            left = current - delta,
            right = current + delta + 1,
            range = [],
            rangeWithDots = [],
            l;
     
        for (let i = 1; i <= last; i++) {
            if (i == 1 || i == last || i >= left && i < right) {
                range.push(i);
            }
        }
     
        for (let i of range) {
            if (l) {
                if (i - l === 2) {
                    rangeWithDots.push(l + 1);
                } else if (i - l !== 1) {
                    rangeWithDots.push('...');
                }
            }
            rangeWithDots.push(i);
            l = i;
        }
     
        return rangeWithDots;
     }

     ctypeName(cId){
        if(cId == 1){return 'book'}
        else if(cId == 2){return 'application'}
        else if(cId == 3){return 'vdo'}
        else if(cId == 4){return 'images'}
        else if(cId == 5){return 'sound'}
        else if(cId == 6){return 'multimedia'}
        else if(cId == 8){return 'test'}
        else if(cId == 11){return 'template'}
        else {return 'book'}
      }

  }

export default Books;
