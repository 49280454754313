import React from 'react';
import './history.css';
import { Button, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import axios from 'axios';

class History extends React.Component {
    constructor(props, context) {
        super(props, context);

        this.state = {
            user:'',
            token:'',
            examList:[],
            page:0,
            totalPage:0,
        };
    }

    componentDidMount() {
        this.setState({imgShow: require("../img/no_image.png")});
        var islogin = JSON.parse(localStorage.getItem('islogin'));
        var token = ""
        if(islogin){
            this.setState({user:islogin,token:islogin.token});
            token = islogin.token;
        }else{
            this.setState({ isLogin: false,user:'',token:''});
        }
        this.getExam(token,this.state.page);
    }

    async componentWillReceiveProps(nextProps){
        if(nextProps.searchWord.trim() !== '' && nextProps.searchWord !== this.props.searchWord){
          await this.props.history.push('/search', nextProps.searchWord);
        }
      }

    getExam(token,page){
        const api = axios.create({
            baseURL: this.props.mainpoint,
            headers: {
              "Content-Type": "application/json",
              "token":token
            },
            timeout: 85000
        });
        api.post('/api/v1/exams/history/page/'+page+'/limit/10/asc/false/sortBy/1').then(
            success => {
                const { status, data } = success
                if (status === 201 || status === 200) {
                    // console.log(data);
                    this.setState({examList:data.result,totalPage:data.pagination.totalPage})
                } else {
                    console.error('Error',status)
                }
            },
            error => {
                this.setState({ loading: false });
                console.clear();
            }
        );
    }

    setExam(){
        if (this.state.examList.length > 0) {
            const items = [];
            for (var i = 0; i < this.state.examList.length; i++) {
                items.push(<Col xs={12} className="exam-score" style={{ backgroundColor: '#f9f9f9'}} key={this.state.examList[i].cId+i}>
                    <Row style={{margin:0}}>
                        <Col xs={4} sm={2} md={2} lg={2} xl={2} style={{textAlign:'center'}}>
                            <img src={this.state.examList[i].imgUrl? this.state.examList[i].imgUrl:require("../img/no_image.png")} alt="" className="rounded-circle" style={{}}
                            onError={({nativeEvent:{error}})=>console.clear()}
                            />
                        </Col>
                        <Col className="exam-detail" xs={8} sm={5} md={5} lg={6} xl={5} style={{color:'#b2b2b2'}}>
                            <div style={{color:'#1d1d1d',fontSize:18}}>{this.state.examList[i].name}</div>
                            <div>{this.state.examList[i].subjects}</div>
                            <div>{this.state.examList[i].classes}</div>
                            <div>{this.state.examList[i].finishQuizDate}</div>
                        </Col>
                        <Col className="exam-grade" xs={12} sm={5} md={5} lg={4} xl={5} style={{ alignSelf: 'center' }}>
                            คะแนน
                            <label style={{marginLeft:10,backgroundColor:'#07b16929',borderRadius:10}}>
                                <span style={{ color: '#07b169',fontWeight: 'bold', marginRight: 10 }}>{this.state.examList[i].score}</span>/{this.state.examList[i].totalScore}
                            </label>
                        </Col>
                    </Row>
                </Col>)
            }
            return (
              <>
                {items}
              </>
            )
          }
    }

    setPageNumb(){
        if (this.state.totalPage > 1) {
            const paging = this.pagination(this.state.page+1, this.state.totalPage, 2);
            const items = [];
            for (var i = 0; i < paging.length; i++) {
                let pageNum = paging[i]-1;
                if(paging[i] !== '...'){
                    items.push(
                        <Button  onClick={() => this.onclickPage(pageNum)} key={"page"+pageNum}
                        className={this.state.page === pageNum ? "buttonPageActive" : "buttonPage"} 
                        style={{ marginLeft: 5, flex: 1 }}>{paging[i]}</Button>
                    )
                }else{

                    items.push(
                        <label key={"pageNan"+i}style={{ marginLeft: 5,flex:1,padding:5}}>{paging[i]}</label>
                    )
                }
            }
            return (
                <>
                <Button onClick={() => this.onclickPage(this.state.page - 1)} className="buttonPage" style={{ marginLeft: 5, flex: 1 }}>ก่อนหน้า</Button>
                 {items}
                 <Button onClick={() => this.onclickPage(this.state.page + 1)} className="buttonPage" style={{ marginLeft: 5, flex: 1 }}>ถัดไป</Button>
                </>
            )
        }
    }

    onclickPage(p){
        // console.log(p);
        if(p > -1 && p < this.state.totalPage){
            if(p !== this.state.page){
                this.setState({page:p});
                this.getExam(this.state.token, p);
            }
        }else{
            console.log("out of page");
        }
    }

    render() {
        return (
            <>
                <Row className="content exam-history" style={{ backgroundColor: '#f3f2f3', paddingBottom: 80 }}>
                    <Col xs={12} style={{ color: '#9e9f9e', padding: 0 }}><Link to="/profile">ข้อมูลของฉัน</Link> / ประวัติการทำข้อสอบ</Col>
                    <Col xs={12} style={{ backgroundColor: '#ffffff', border: '1px solid #e1e1e1'}}>
                        <Row style={{margin:0}} className="history-body">
                            <Col xs={12}>ประวัติการทำข้อสอบ</Col>
                            {this.setExam()}
                        </Row>
                        <Row style={{ marginTop: 10,marginLeft:0,marginRight:0}}>
                            <Col xs={12} style={{textAlign: 'right',flexDirection:'row'}}>
                                {this.setPageNumb()}
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </>
        );
    }

    pagination(c, m, r=2) {  //c หน้าปัจจุบัน  m จำนวนหน้าทั้งหมด r  ระยะของช่วงปุ่มเลขหน้า
        let current = c,
            last = m,
            delta = r,
            left = current - delta,
            right = current + delta + 1,
            range = [],
            rangeWithDots = [],
            l;
     
        for (let i = 1; i <= last; i++) {
            if (i === 1 || i === last || (i >= left && i < right)) {
                range.push(i);
            }
        }
     
        for (let i of range) {
            if (l) {
                if (i - l === 2) {
                    rangeWithDots.push(l + 1);
                } else if (i - l !== 1) {
                    rangeWithDots.push('...');
                }
            }
            rangeWithDots.push(i);
            l = i;
        }
     
        return rangeWithDots;
     }

}

export default History;
