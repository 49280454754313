import React from 'react';
import { Button, Row, Col, Container } from 'react-bootstrap';
import Loader from '../component/loader';
import axios from 'axios';
import { Link } from 'react-router-dom';
import Tag from '../component/tag'
import MetaTags from 'react-meta-tags';

class Favorite extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
        favoriteList:'',
        page: 0,
        totalPage: 0,
    };
  }

  componentDidMount() {
    setTimeout(() => {
      window.scrollTo({ top: 0});
    }, 500);
    var islogin = JSON.parse(localStorage.getItem('islogin'));
    var token = ""
    if (islogin) {
      this.setState({ isLogin: true, user: islogin });
      token = islogin.token
    } else {
      this.setState({ isLogin: false, user: {} });
    }

    this.getFavorite(token,this.state.page);
  }

  async componentWillReceiveProps(nextProps){
    if(nextProps.searchWord.trim() !== '' && nextProps.searchWord !== this.props.searchWord){
      await this.props.history.push('/search', nextProps.searchWord);
    }
  }

  getFavorite(token, page) {
    this.setState({ loading: true });
    const api = axios.create({
      baseURL: this.props.mainpoint,
      headers: {
        "Content-Type": "application/json",
        "token": token
      },
      timeout: 85000
    });
    api.get('/api/v1/contents/favorite/page/'+page+'/limit/12/asc/false/sortBy/4').then(
        success => {
        this.setState({ loading: false });
        const { status, data } = success
        if (status === 201 || status === 200) {
            // console.log(data);
            this.setState({ favoriteList: data.result })
            if (page === this.state.page) {
                this.setState({ totalPage: data.pagination.totalPage })
              }
        } else {
            console.error('Error', status)
        }
        },
        error => {
        this.setState({ loading: false });
        // console.clear();
        }
    );
  }

  setFavorite() {
    if (this.state.favoriteList.length > 0) {
      const items = [];
      for (var i = 0; i < this.state.favoriteList.length; i++) {
        let cId = this.state.favoriteList[i].cId;
        items.push(<Col lg={4} md={6} xs={12} style={{ padding: 10 }} key={cId+'0'+i}>
          <Row style={{ margin: 0, backgroundColor: '#f9f9f9', borderRadius: 10, border: '1px solid #efefef', padding: 10, height: '100%' }}>
            <Col lg={5} xs={3} style={{ padding: 0, textAlign: 'center' }}>
            <Link to={"/detail/"+this.ctypeName(this.state.favoriteList[i].ctypeId)+"/"+cId}>
              <div style={{ maxHeight: 150, width: '100%', overflow: 'hidden' }}>
                <img src={this.state.favoriteList[i].imgUrl} alt="" width="100%" 
                onError={({ nativeEvent: {error} }) => console.clear()}
                />
              </div>
              </Link>
            </Col>
            <Col lg={5} xs={8} style={{}}>
              <Tag ctype ={this.state.favoriteList[i].ctypeId}/>
              {/* {this.tagIcon(this.ctypeName(this.state.favoriteList[i].ctypeId))} */}
              <Col xs={12} style={{ padding: 0 }}>{this.state.favoriteList[i].name}</Col>
            </Col>
          </Row>
        </Col>)
      }
      return (
        <>
          {items}
        </>
      )
    } else {
      return (
        <>
          <Col xs={12} style={{ textAlign: 'center', padding: 20 }}>ไม่มีเนื้อหา</Col>
        </>
      )
    }
  }

  setPageNumb() {
    if (this.state.totalPage > 1) {
      const paging = this.pagination(this.state.page + 1, this.state.totalPage, 2);
      const items = [];
      for (var i = 0; i < paging.length; i++) {
        let pageNum = paging[i] - 1;
        if (paging[i] !== '...') {
          items.push(
            <Button onClick={() => this.onclickPage(pageNum)} key={"page" + pageNum}
              className={this.state.page === pageNum ? "buttonPageActive" : "buttonPage"}
              style={{ marginLeft: 5, flex: 1 }}>{paging[i]}</Button>
          )
        } else {

          items.push(
            <label key={"pageNan" + i} style={{ marginLeft: 5, flex: 1, padding: 5 }}>{paging[i]}</label>
          )
        }
      }
      return (
        <>
          <Button onClick={() => this.onclickPage(this.state.page - 1)} className="buttonPage" style={{ marginLeft: 5, flex: 1 }}>ก่อนหน้า</Button>
          {items}
          <Button onClick={() => this.onclickPage(this.state.page + 1)} className="buttonPage" style={{ marginLeft: 5, flex: 1 }}>ถัดไป</Button>
        </>
      )
    }
  }

  onclickPage(p) {
    // console.log(p);
    if (p > -1 && p < this.state.totalPage) {
      if (p !== this.state.page) {
        this.setState({ page: p });
        this.getFavorite(this.state.user.token, p);
      }
    } else {
      console.log("out of page");
    }
  }

  render() {
    return (
      <>
      <MetaTags>
            <title>{'OBEC Content Center'}</title>
        </MetaTags>
        <Loader loading={this.state.loading} />

        <Row>
          <Col xs={12} className="p-0">
            <img src={require("../img/headerStore.png")} alt="" width="100%" />
            <label style={{ color: '#ffffff', fontSize: '5vmax', fontWeight: 'bold', textAlign: 'center', position: 'absolute', top: '25%', left: 0, right: 0 }}>
              รายการโปรด
            </label>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <Container>
            <Row>
              <Col xs={12}>
                <Row>
                  {this.setFavorite()}
                </Row>

                <Row>
                  <Col xs={12} className="text-center">
                    {this.setPageNumb()}
                  </Col>
                </Row>

              </Col>
            </Row>
            </Container>
          </Col>
        </Row>

      </>
    );
  }

  pagination(c, m, r = 2) {  //c หน้าปัจจุบัน  m จำนวนหน้าทั้งหมด r  ระยะของช่วงปุ่มเลขหน้า
    let current = c,
      last = m,
      delta = r,
      left = current - delta,
      right = current + delta + 1,
      range = [],
      rangeWithDots = [],
      l;

    for (let i = 1; i <= last; i++) {
      if (i === 1 || i === last || (i >= left && i < right)) {
        range.push(i);
      }
    }

    for (let i of range) {
      if (l) {
        if (i - l === 2) {
          rangeWithDots.push(l + 1);
        } else if (i - l !== 1) {
          rangeWithDots.push('...');
        }
      }
      rangeWithDots.push(i);
      l = i;
    }

    return rangeWithDots;
  }

  ctypeName(cId) {
    if (cId === 1) { return 'book' }
    else if (cId === 2) { return 'application' }
    else if (cId === 3) { return 'vdo' }
    else if (cId === 4) { return 'images' }
    else if (cId === 5) { return 'sound' }
    else if (cId === 6) { return 'Multimedia' }
    else if (cId === 8) { return 'test' }
    else if(cId === 11){return 'template'}
    else { return 'book' }
  }

}

export default Favorite;
