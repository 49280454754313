import React from 'react';
import { Button,Row,Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { translate } from 'react-switch-lang';

class Menu extends React.Component {
    constructor(props, context) {
      super(props, context);
    //   console.log(props);
      this.state = {
          cType:"1"
      };
    }

    componentWillReceiveProps(newProps){
        // console.log(newProps);
        if(newProps.ct){
            this.setState({cType:newProps.ct+''});
        }else{
            this.setState({cType:'999'});
        }
    }

    render(){
        const { t } = this.props;
        return (
        <Row className="px-2 justify-content-center">
            <Col lg md={2} xs={3} className="text-center p-1" >
                <Link to={'/store/type/1'}>
                    <Button className={this.state.cType === "1" || this.state.cType === "999" ?"contentButton active":"contentButton"}>
                        <img src={require("../img/menu_icon/book_ic.png")} alt="" />
                        <div>{t('tag.book')}</div>
                    </Button>
                </Link>
            </Col>
            <Col lg md={2} xs={3} className="text-center p-1">
                <Link to={'/store/type/3'}>
                    <Button className={this.state.cType === "3" || this.state.cType === "999" ?"contentButton active":"contentButton"}>
                        <img src={require("../img/menu_icon/video_ic.png")} alt="" />
                        <div>{t('tag.video')}</div>
                    </Button>
                </Link>
            </Col>
            <Col lg md={2} xs={3} className="text-center p-1">
                <Link to={'/store/type/4'}>
                    <Button className={this.state.cType === "4" || this.state.cType === "999" ?"contentButton active":"contentButton"}>
                        <img src={require("../img/menu_icon/pic_ic.png")} alt="" />
                        <div>{t('tag.photo')}</div>
                    </Button>
                </Link>
            </Col>
            <Col lg md={2} xs={3} className="text-center p-1">
                <Link to={'/store/type/5'}>
                    <Button className={this.state.cType === "5" || this.state.cType === "999" ?"contentButton active":"contentButton"}>
                        <img src={require("../img/menu_icon/sound_ic.png")} alt="" />
                        <div>{t('tag.sound')}</div>
                    </Button>
                </Link>
            </Col>
            <Col lg md={2} xs={3} className="text-center p-1">
                <Link to={'/store/type/2'}>
                    <Button className={this.state.cType === "2" || this.state.cType === "999" ?"contentButton active":"contentButton"}>
                        <img src={require("../img/menu_icon/app_ic.png")} alt="" />
                        <div>{t('tag.application')}</div>
                    </Button>
                </Link>
            </Col>
            <Col lg md={2} xs={3} className="text-center p-1">
                <Link to={'/store/type/8'}>
                    <Button className={this.state.cType === "8" || this.state.cType === "999" ?"contentButton active":"contentButton"}>
                        <img src={require("../img/menu_icon/test_ic.png")} alt="" />
                        <div>{t('tag.exam')}</div>
                    </Button>
                </Link>
            </Col>
            <Col lg md={2} xs={3} className="text-center p-1">
                <Link to={'/store/type/11'}>
                    <Button className={this.state.cType === "11" || this.state.cType === "999" ?"contentButton active":"contentButton"}>
                        <img src={require("../img/menu_icon/template_ic.png")} alt="" />
                        <div>{t('tag.template')}</div>
                    </Button>
                </Link>
            </Col>
            <Col lg md={2} xs={3} className="text-center p-1">
                <Link to={'/store/type/6'}>
                    <Button className={this.state.cType === "6" || this.state.cType === "999" ?"contentButton active":"contentButton"}>
                        <img src={require("../img/menu_icon/multimedia.png")} alt="" />
                        <div>{t('tag.multimedia')}</div>
                    </Button>
                </Link>
            </Col>
        </Row>
        );
    }
}

export default translate(Menu);