import React from 'react';
import './register.css';
import {Alert,Button,Row,Col,Modal,Form } from 'react-bootstrap';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import axios from 'axios';
import Loader from '../component/loader';
import { Redirect } from 'react-router-dom';
import FacebookLogin from 'react-facebook-login';
import GoogleLogin from 'react-google-login';
import MicrosoftLogin from "react-microsoft-login";
import { FaFacebookF, FaGoogle , FaMicrosoft } from "react-icons/fa";
const MySwal = withReactContent(Swal);

class Login extends React.Component {

  constructor(props){ 
    super(props);
    this.setLoginState=this.setLoginState.bind(this);
    this.toLogin=this.toLogin.bind(this);
    this.toForgotPwd=this.toForgotPwd.bind(this);
    this.state = {
      username: '',
      password: '',
      facebookId: '',
      gmailId: '',
      alertShow: false,
      message: '',
      loading: false,
      redirect: false
    };
  }

  componentDidMount(){
    this.setState({ alertShow: false, message: ""});
  }

  renderRedirect = () => {
    if (this.state.redirect) {
      return <Redirect to='/profile/edit/true'/>
    }
  }

  swalFire = (msg) => {
    MySwal.fire({
      type: 'warning',
      confirmButtonText: 'ตกลง',
      text: msg
    });
  }

  swalConfirmProfile = () => {
    MySwal.fire({
      title: 'ข้อมูลของคุณยังไม่ครบถ้วน',
      text: 'กรุณาอัพเดทข้อมูลได้ที่เมนูผู้ใช้งาน',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'ตกลง',
      cancelButtonText: 'ยกเลิก'
    }).then((result) => {
      if (result.value) {
        this.setState({
          redirect: true
        });

        this.renderRedirect();
        window.location.reload();
      }
    });
  }

  swalLoading = () => {
    MySwal.fire({
      title: 'รอสักครู่...',
      timer: 2000,
      onBeforeOpen: () => {
        Swal.showLoading()
      }
    });
  }

  setLoginState(value){
    this.setState({ alertShow: false, message: ""});
    this.props.toregis(true);
  }

  toForgotPwd(value){
    this.setState({ alertShow: false, message: ""});
    this.props.toforgotpwd(true);
  }

  toLogin(value){
    if (this.state.alertShow) this.setState({ alertShow: false});
    if (this.state.username.trim() == '' || this.state.password.trim() == '') {
      this.setState({ alertShow: true, message: "กรุณากรอกชื่อผู้ใช้หรือรหัสผ่าน"});
      return;
    }
    this.setState({ loading: true });
    let data = {
      username: this.state.username,
      password: this.state.password
    }
    const stringQuery = `?username=${data.username}&password=${data.password}`;
    //if (process.env.REACT_APP_ENV_MODE === "development") console.log(data, stringQuery);
    this.setState({ loading: true });
    const api = axios.create({
      baseURL: this.props.mainpoint,
      headers: {
        "Content-Type": "application/json",
        "latitude": "",
        "longitude": "",
        "deviceId": 4
      },
      timeout: 85000
    });
    
    api.post(this.props.mainpoint+'/api/v1/user/login'+stringQuery).then(
      success => {
        this.setState({ loading: false });
        const { status, data } = success
        if (process.env.REACT_APP_ENV_MODE === "development") console.log(success);
        if (status === 201 || status === 200) {
          if (process.env.REACT_APP_ENV_MODE === "development") console.log(data);
          if (data.code == 200) {
            // Swal.fire({
            //   type: 'success',
            //   text: data.message,
            //   onClose: () => {
            //     this.props.islogin(data.result);
            //     window.location.href = "/";
            //   }
            // });

            data.result.imgUrl = this.checkAvatar(data.result);
            this.props.islogin(data.result);
            this.redirectCheckProfile(data.result);
            
          } else if (data.code == 500) {
            this.swalFire(data.message);
          }
        } else {
          this.swalFire(data.message);
        }
      },
      error => {
        console.log(error);
        if (process.env.REACT_APP_ENV_MODE === "development") console.log(JSON.parse(JSON.stringify(error)));
        // const failure = JSON.parse(JSON.stringify(error));
        const {status, data} = error.response;
        this.swalFire(data.message);
        this.setState({ loading: false });
      }
    );
  }

  responseFacebook = async (response) => {
    if (process.env.REACT_APP_ENV_MODE === "development") console.log("facebook",response);

    if (Object.keys(response).length && response.status === "unknown") return;
    if (Object.keys(response).length && response.status !== "unknown") {
      const {name, first_name, last_name, email, userID, picture } = response;
      const formData = {
          firstName: first_name,
          lastName: last_name,
          name: name,
          email: email,
          profileId: userID,
          imageURL: (Object.keys(picture.data).length)? picture.data.url:'',
          type: "facebook"
      }
      if (process.env.REACT_APP_ENV_MODE === "development") console.log(formData);
      await this.sendToLogin(formData);
    }
  }
  authHandler = async (response) => {
    console.log(response);
  }
  facebookClicked = () => {
    if (this.state.alertShow) this.setState({ alertShow: false, message: ""});
  }

  googleSuccess = async (response) => {
    if (process.env.REACT_APP_ENV_MODE === "development") console.log("Google success", response);
    const {name, givenName, familyName, email, googleId, imageUrl } = response.profileObj;
    const formData = {
        firstName: givenName,
        lastName: familyName,
        name: name,
        email: email,
        profileId: googleId,
        imageURL: imageUrl,
        type: "gmail"
    }
    if (process.env.REACT_APP_ENV_MODE === "development") console.log(formData);
    await this.sendToLogin(formData);
  }
  googleFailure = (response) => {
    if (process.env.REACT_APP_ENV_MODE === "development") console.log("Google Failure", response);
  }

  sendToLogin = (formData) => {
    this.setState({ loading: true });
    const api = axios.create({
      baseURL: this.props.mainpoint,
      headers: {
        "Content-Type": "application/json",
        "latitude": "",
        "longitude": "",
        "deviceId": 4
      },
      timeout: 85000
    });
    api.post(`/api/v1/user/login/3rdparty`, formData).then(
      success => {
        if (process.env.REACT_APP_ENV_MODE === "development") console.log(success);
        this.setState({ loading: false });
        const { status, data } = success
        if (status === 201 || status === 200) {
          if (process.env.REACT_APP_ENV_MODE === "development") console.log("log in google ",data);
            if (data.code == 200) {
              
              this.props.islogin(data.result);
              this.redirectCheckProfile(data.result)

            } else if (data.code == 500) {
              this.swalFire(data.message);
            }
        }
      },
      error => {
        if (process.env.REACT_APP_ENV_MODE === "development") console.log(error);
        this.setState({ loading: false });
        // const failure = JSON.parse(JSON.stringify(error));
        // const {status, data} = failure.response;
        // this.swalFire(data.message);
        // this.setState({ loading: false });
      }
    );
  }

  checkAvatar = (formData) => {
    if(formData.imgUrl === "" || formData.imgUrl === null){
      let roleId = formData.userRole.uRoleId;
      return require("../img/avatar/role_" + roleId + ".png");
    } else {
      return formData.imgUrl;
    }
  }

  redirectCheckProfile = (formData) => {
    const {firstname, lastname, provinceId, zipcode} = formData;
    let checkProfile = 0;

    checkProfile += (firstname === "" || firstname === null)? 1:0;
    checkProfile += (lastname === "" || lastname === null)? 1:0;
    // checkProfile += (email === "" || email === null)? 1:0;
    checkProfile += (provinceId === 0 || provinceId === null)? 1:0;
    checkProfile += (zipcode === "" || zipcode === null)? 1:0;
    
    if(checkProfile > 0){
      this.swalConfirmProfile();
    } else {
      this.swalLoading();
      window.location.reload();
    }
  }
    
    render() {
      return (
        <>
        {this.renderRedirect()}
        <Modal
        {...this.props}
        //size="sm"
        id="login-form"
        aria-labelledby="contained-modal-title-vcenter-l"
        centered>
        {/* <Modal.Header closeButton={false} className="" style={{borderBottom: 'none'}}>
        </Modal.Header> */}
        <Modal.Body className="p-5">
          <Col xs={8} sm={6} md={6} style={{paddingLeft:'0', left: '0'}}>
            <Modal.Title id="contained-modal-title-vcenter-l"  className="mb-4"
              style={{paddingBottom: '0.3em', borderBottom: '3px solid #0eb06a'}}>
              <strong>เข้าสู่ระบบ</strong>
            </Modal.Title>
          </Col>
          <Row>
            <Col md={12}>
              <Alert show={this.state.alertShow} variant="warning">
                {this.state.message}
              </Alert>
              <Form.Group>
                <Form.Label>ชื่อผู้ใช้งาน</Form.Label>
                <Form.Control type="email" className="borderRadius background-gray" placeholder=""
                  onChange={e => this.setState({ username: e.target.value.trim() })}/>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <Form.Group>
                <Form.Label>รหัสผ่าน</Form.Label>
                <Form.Control type="password" className="borderRadius background-gray"
                  onChange={e => this.setState({ password: e.target.value.trim() })}/>
              </Form.Group>
            </Col>
          </Row>
          <Row className="justify-content-md-center">
            <Col md={12} className="text-center mb-3">
              <a className="text-warning" style={{fontSize: '0.9em',cursor:"pointer"}} onClick={this.toForgotPwd}><u>ลืมรหัสผ่าน?</u></a>
            </Col>
          </Row>
          <Row>
            <Col md={12} className="text-center mb-2">
            <Button className="borderRadius bg-success border-success w-100" onClick={this.toLogin} style={{}}>
              เข้าสู่ระบบ
            </Button>
            </Col>
          </Row>
          {/* <Row className="justify-content-md-center">
            <Col md={12} className="text-center mb-2">
              <span className="text-muted" style={{fontSize: '0.9em'}}>หรือ</span>
            </Col>
          </Row>
          <Row>
            <Col md={12} className="text-center mb-3">
              <FacebookLogin
                appId={this.props.facebook_id}
                autoLoad={false}
                fields="id,email,name,first_name,last_name,picture "
                cssClass="borderRadius w-100 facebook-login"
                onClick={this.facebookClicked}
                callback={this.responseFacebook}
                icon={<FaFacebookF/>} 
              />
            </Col>
          </Row>
          <Row>
            <Col md={12} className="text-center mb-3">
            <MicrosoftLogin clientId={this.props.microsoft_id}  authCallback={this.authHandler} >
                <Button className="borderRadius w-100 microsoft-login">  <FaMicrosoft/><span>&nbsp;&nbsp;&nbsp; Login with microsoft</span></Button>
                </MicrosoftLogin>
            </Col>
          </Row>
          <Row>
            <Col md={12} className="text-center mb-3">
              <GoogleLogin
                clientId={this.props.google_id}
                onSuccess={this.googleSuccess}
                onFailure={this.googleFailure}
                cookiePolicy={'single_host_origin'}
                render={renderProps => (
                  <Button className="borderRadius w-100 google-login" onClick={renderProps.onClick} style={{}}>
                    <FaGoogle/><span>Login with Google&nbsp;&nbsp;&nbsp;</span>
                  </Button>
                )}
              />
            </Col>
          </Row> */}
          <Row className="justify-content-md-center">
            <Col md={12} className="text-center mb-2" style={{fontSize: '0.9em'}}>
              ยังไม่มีบัญชีสมาชิก? <a className="text-success" style={{cursor:"pointer"}} onClick={this.setLoginState}><u>สมัครสมาชิก</u></a>
            </Col>
          </Row>

        </Modal.Body>
        <Modal.Footer className="justify-content-md-center" style={{borderTop: 'none'}} >
          
        </Modal.Footer>
      </Modal>
      <Loader loading={this.state.loading}/>
      </>
      );
    }
  }

export default Login;
