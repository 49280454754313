import React from 'react';
// import { Router,Route,browserHistory } from 'react-router';
import { HashRouter,BrowserRouter, Route, Switch } from 'react-router-dom';

import Main from './home/main';
import Store from './contents/store';
import Detail from './detail'
import Bookshelf from './bookshelf'
import Profile from './profile'
import ProfileEdit from './profileedit'
import History from './history'
import Search from './search'
import Footer from './footer';
import Header from './header';
import Favorite from './favorite';
import Notify from './notify';
import Page404 from './page404';

class Routes extends React.Component {
  constructor(props) {
    super(props)
    // console.log(props);
    this.state = {
      isLogOut: false,
      toLogin: false,
      changeImg: {},
      userProfile: {},
      searchWord: ''
    }
  }

  setLogOut = (value) => {
    if (!this.state.isLogOut) {
      this.setState({isLogOut: value});
    }
  }

  setLogin = (value) => {
    if (!this.state.toLogin) {
      this.setState({toLogin: value});
    } else {
      this.setState({toLogin: false});
    }
  }

  setUserImg = (value) => {
    this.setState({changeImg: value});
  }

  setUserProfile = (value) => {
    this.setState({userProfile: value});
  }

  setSearch = async (value) => {
    await this.setState({searchWord: value});
  }

  render() {
    return (
      //  <Router {...this.props} history={browserHistory}>
      //   <Route path="/" component={Main} mainpoint={this.props.mainpoint}/>
      //   <Route path="/store" component={Store} mainpoint={this.props.mainpoint}/>
      //   <Route path="/store/tab/:handle" component={Store} mainpoint={this.props.mainpoint} tabCheck={true}/>
      //   <Route path="/store/:handle" component={Store} mainpoint={this.props.mainpoint} hits={true}/>
      //   <Route path="/detail/:handle/:handle" component={Detail} mainpoint={this.props.mainpoint}/>
      //   <Route path="/bookshelf" component={Bookshelf} mainpoint={this.props.mainpoint}/>
      //   <Route path="/profile" component={Profile} mainpoint={this.props.mainpoint}/>
      //   <Route path="/profile/edit" component={ProfileEdit} mainpoint={this.props.mainpoint}/>
      //   <Route path="/profile/history" component={History} mainpoint={this.props.mainpoint}/>
      //   <Route path="/search" component={Search} mainpoint={this.props.mainpoint}/>
      //  </Router>
      
      <BrowserRouter {...this.props}>
        <Header {...this.props} {...this.state} search={(s)=>this.setSearch(s)} />
        <Switch>
        <Route exact path="/" render={(props) => <Main {...props} {...this.props} {...this.state} />} />
        <Route exact path="/store" render={(props) => <Store {...props} {...this.props} {...this.state} />} />
        <Route path="/store/type/:handle" render={(props) => <Store {...props} {...this.props} {...this.state} tabCheck={true} />} />
        <Route path="/store/:handle" render={(props) => <Store {...props} {...this.props} {...this.state} hits={true} />} />
        <Route exact path="/detail/:handle/:handle" render={(props) => <Detail {...props} {...this.props} {...this.state} login={(value) => this.setLogin(value)}/>} />
        <Route path="/bookshelf" render={(props) => <Bookshelf {...props} {...this.props} {...this.state} />} />
        <Route path="/favorite" render={(props) => <Favorite {...props} {...this.props} {...this.state} />} />
        <Route exact path="/profile" render={(props) => <Profile {...props} {...this.props} {...this.state} logout={(value) => this.setLogOut(value)} />} />
        <Route path="/profile/edit/:submit" render={(props) => 
          <ProfileEdit {...props} {...this.props} {...this.state}
          changeUserImg={(value) => this.setUserImg(value)}
          changeProfiles={(value) => this.setUserProfile(value)}
          handleMatch={this.handleMatch}
          />} 
        />
        <Route path="/profile/edit" render={(props) => 
          <ProfileEdit {...props} {...this.props} {...this.state}
          changeUserImg={(value) => this.setUserImg(value)}
          changeProfiles={(value) => this.setUserProfile(value)}
          />} 
        />
        
        <Route path="/profile/history" render={(props) => <History {...props} {...this.props} {...this.state} />} />
        <Route path="/search" render={(props) => <Search {...props} {...this.props} {...this.state} login={(value) => this.setLogin(value)}/>} />
        <Route exact path="/notify" render={(props) => <Notify {...props} {...this.props} {...this.state} />} />
        <Route path="/notify/:id" render={(props) => <Notify {...props} {...this.props} {...this.state} />} />
        <Route path="*" render={(props) => <Page404/>} />
        </Switch>
        <Footer  {...this.props} {...this.state}/>
      </BrowserRouter>
    );
  }
}

export default Routes;