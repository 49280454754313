import React from 'react'
import {Modal} from 'react-bootstrap';
// Components
import ClipLoader from 'react-spinners/ClipLoader';

const override = {
    display: 'block',
    margin: '0 auto',
    borderColor: 'white'
}

class Loader extends React.Component {
   
    render() {
        const { loading } = this.props;

        return (
            
            <Modal id="modal_loading" show={loading}
            backdropClassName="loading-backdrop" 
            onHide={() => null}>
                <Modal.Body>

                    <ClipLoader
                        css={override}
                        sizeUnit={"px"}
                        size={150}
                        color={'#ff8c20'}
                        loading={loading}
                    />

                </Modal.Body>
            </Modal>

        )
    }
  }

export default Loader