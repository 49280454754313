import React from 'react';
import './register.css';
import { Button,Row,Col,Modal,Form } from 'react-bootstrap';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import axios from 'axios';
import Loader from '../component/loader';
const MySwal = withReactContent(Swal)

class ForgotPwd extends React.Component {

  constructor(props){ 
    super(props);
    
    this.state = {
      email: '',
      loading: false
    };
  }

  swalFire = (msg) => {
    MySwal.fire({
      type: 'warning',
      confirmButtonText: 'ตกลง',
      text: msg
    });
  }

  closeModal = () => {
    this.setState({
      email: ''
    });
    this.props.onHide();
  }

  validate = () => {
    //console.log(this.state);
    const {email} = this.state;
    //let emailPattern = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    // if (!email) return this.swalFire('กรุณากรอกอีเมล');
    // if (!emailPattern.test(email)) return this.swalFire('กรุณากรอกรูปแบบอีเมลให้ถูกต้อง');
    
    if (email) {
      this.sendMail();
    }
  }

  sendMail = (value) => {
    
    let data = {
      email: this.state.email
    }
    //console.log(data);
    this.setState({ loading: true });
    axios.get(this.props.mainpoint+'/api/v1/user/forgetpwd/'+data.email).then(
      success => {
        this.setState({ loading: false });
        const { status, data } = success
        if (status === 201 || status === 200) {
          //console.log(data);
          if (data.code === 215) {
            Swal.fire({
              type: 'success',
              text: data.message,
              onClose: () => {
                this.closeModal();
              }
            });
          } else if (data.code === 515) {
            this.swalFire(data.message);
          }
        } else {
          
        }
      },
      error => {
        console.log('Error :: ', error);
        this.swalFire("ไม่มีบัญชีผู้ใช้หรือรหัสผ่านนี้ในระบบ");
        this.setState({ loading: false });
      }
    );
  }
    
    render() {
      return (
        <Modal
        {...this.props}
        //size="sm"
        id="send-mail-form"
        aria-labelledby="contained-modal-title-vcenter"
        centered>
        {/* <Modal.Header closeButton className="" style={{borderBottom: 'none'}}>
        </Modal.Header> */}
        <Modal.Body className="p-5">

          <Modal.Title id="contained-modal-title-vcenter"  className="mb-4"
            style={{paddingBottom: '0.3em', borderBottom: '3px solid #0eb06a', width: '40%'}}>
            <strong>ลืมรหัสผ่าน</strong>
          </Modal.Title>
          <Row>
            <Col md={12}>
              <Form.Group>
                <Form.Label>ระบุอีเมลของท่าน</Form.Label>
                <Form.Control type="email" className="borderRadius background-gray" placeholder=""
                  onChange={e => this.setState({ email: e.target.value.trim() })}/>
              </Form.Group>
            </Col>
          </Row>
          
          <Row>
            <Col md={12} className="text-center mt-3">
            <Button className="borderRadius bg-success border-success" onClick={this.validate} style={{}}>
              ส่งข้อมูล
            </Button>
            </Col>
          </Row>

        </Modal.Body>
        <Loader loading={this.state.loading}/>
      </Modal>
      );
    }
  }

export default ForgotPwd;
