import React from 'react';
import { Button, Row, Col, InputGroup, FormControl, Modal } from 'react-bootstrap';
import { FaSearch, FaTh, FaBook, FaPlusSquare, FaTrash, FaSyncAlt } from 'react-icons/fa';
import Select from 'react-select';
import Loader from '../component/loader';
import axios from 'axios';
import Swal from 'sweetalert2';
import { Link } from 'react-router-dom';
import Tag from '../component/tag';
import { translate } from 'react-switch-lang';
import MetaTags from 'react-meta-tags';

class Bookshelf extends React.Component {
  constructor(props, context) {
    super(props, context);

    this.sortShow = this.sortShow.bind(this);
    this.sortClose = this.sortClose.bind(this);
    this.newShelfShow = this.newShelfShow.bind(this);
    this.newShelfClose = this.newShelfClose.bind(this);
    this.onSearchText = this.onSearchText.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);
    this.onNewShelfText = this.onNewShelfText.bind(this);

    this.state = {
      showSort: false,
      showNewShelf: false,
      selecteContent: null,
      selecteSort: null,
      loading: false,
      isLogin: false,
      user: {},
      bookshelf: [],
      sortBy: 4,
      page: 0,
      totalPage: 0,
      ContentCheck: [],
      shelfId: '0',
      selfList: [],
      searchText: '',
      newShelfText:'',
      changeShelfId:'',
    };
  }

  componentDidMount() {
    setTimeout(() => {
      window.scrollTo({ top: 0});
    }, 500);
    var islogin = JSON.parse(localStorage.getItem('islogin'));
    var token = ""
    if (islogin) {
      this.setState({ isLogin: true, user: islogin });
      token = islogin.token
    } else {
      this.setState({ isLogin: false, user: {} });
    }

    this.getShelf(token);
    this.getBookshelf(token, this.state.sortBy, this.state.page, this.state.shelfId);
  }

  async componentWillReceiveProps(nextProps){
    if(nextProps.searchWord.trim() !== '' && nextProps.searchWord !== this.props.searchWord){
      await this.props.history.push('/search', nextProps.searchWord);
    }
  }

  handleChange = (selecteContent) => {
    if (selecteContent.value === '99') {
      this.setState({ shelfId:'0'});
      this.newShelfShow();
    } else {
      this.setState({ selecteContent,ContentCheck:[],shelfId:selecteContent.value});
      this.getBookshelf(this.state.user.token, this.state.sortBy, this.state.page, selecteContent.value);
    }
  }

  sortChange = (selecteSort) => {
    this.setState({ selecteSort,ContentCheck:[],sortBy:selecteSort.value});
    this.getBookshelf(this.state.user.token, selecteSort.value, this.state.page, this.state.shelfId);
  }

  sortClose() {
    this.setState({ showSort: false });
  }

  sortShow() {
    if (this.state.ContentCheck.length > 0) {
      this.setState({ showSort: true });
    } else {
      Swal.fire({
        type: 'warning',
        text: 'ยังไม่ได้เลือกรายการ',
      });
    }
  }

  newShelfClose() {
    this.setState({ showNewShelf: false, newShelfText:'' });
  }

  newShelfShow() {
    this.setState({ showNewShelf: true });
  }

  editSelf() {
    var shelfName = '';
    console.log(this.state.selfList);
    for (var i=0;i<this.state.selfList.length;i++){
      if(this.state.selfList[i].shelfId === this.state.shelfId){
        shelfName = this.state.selfList[i].name;
      }
    }
    this.setState({ newShelfText:shelfName, showNewShelf: true });
  }

  getBookshelf(token, sort, page, shelfId,msg) {
    this.setState({ loading: true });
    const api = axios.create({
      baseURL: this.props.mainpoint,
      headers: {
        "Content-Type": "application/json",
        "token": token
      },
      timeout: 85000
    });
    if(msg){
      // console.log('Search self');
      api.post('/api/v1/shelves/search/shelf/'+shelfId+'/device/0/page/'+page+'/limit/12/asc/false/sortBy/' + sort,msg).then(
        success => {
          this.setState({ loading: false });
          const { status, data } = success
  
          if (status === 201 || status === 200) {
            // console.log(data);
            this.setState({ bookshelf: data.result })
            if (page === this.state.page) {
              this.setState({ totalPage: data.pagination.totalPage })
            }
          } else {
            console.error('Error', status)
          }
        },
        error => {
          this.setState({ loading: false });
          console.clear();
        }
      );
    }else{
      // console.log('Get self');
        api.get('/api/v1/shelves/'+shelfId+'/page/'+page+'/limit/12/asc/false/sortBy/'+sort).then(
        success => {
          this.setState({ loading: false });
          const { status, data } = success
  
          if (status === 201 || status === 200) {
            // console.log(data);
            this.setState({ bookshelf: data.result })
            if (page === this.state.page) {
              this.setState({ totalPage: data.pagination.totalPage })
            }
          } else {
            console.error('Error', status)
          }
        },
        error => {
          this.setState({ loading: false });
          console.clear();
        }
      );
    }
  }

  setBookshelf() {
    if (this.state.bookshelf.length > 0) {
      const items = [];
      for (var i = 0; i < this.state.bookshelf.length; i++) {
        let cId = this.state.bookshelf[i].cId;
        items.push(<Col lg={4} md={6} xs={12} style={{ padding: 10 }} key={cId+'0'+i}>
          <Row style={{ margin: 0, backgroundColor: '#f9f9f9', borderRadius: 10, border: '1px solid #efefef', padding: 10, height: '100%' }}>
            <Col xs={1} style={{ padding: 0 }}>
            {this.state.shelfId !== '0' && this.state.shelfId !== '99' &&<input onChange={() => this.onCheckCont(cId)} type="checkbox" value="1" style={{ marginTop: '4vmax' }} />}
            </Col>
            <Col lg={5} xs={3} style={{ padding: 0, textAlign: 'center' }}>
            <Link to={"/detail/"+this.ctypeName(this.state.bookshelf[i].ctypeId)+"/"+cId}>
              <div style={{ maxHeight: 150, width: '100%', overflow: 'hidden' }}>
                <img src={this.state.bookshelf[i].imgUrl} alt="" width="100%" 
                onError={({ nativeEvent: {error} }) => console.clear()}
                />
              </div>
              </Link>
            </Col>
            <Col lg={5} xs={8} style={{}}>
              <Tag ctype ={this.state.bookshelf[i].ctypeId}/>
              {/* {this.tagIcon(this.ctypeName(this.state.bookshelf[i].ctypeId))} */}
              <Col xs={12} style={{ padding: 0 }}>{this.state.bookshelf[i].name}</Col>
            </Col>
          </Row>
        </Col>)
      }
      return (
        <>
          {items}
        </>
      )
    } else {
      return (
        <>
          <Col xs={12} style={{ textAlign: 'center', padding: 20 }}>ไม่มีเนื้อหา</Col>
        </>
      )
    }
  }

  setPageNumb() {
    if (this.state.totalPage > 1) {
      const paging = this.pagination(this.state.page + 1, this.state.totalPage, 2);
      const items = [];
      for (var i = 0; i < paging.length; i++) {
        let pageNum = paging[i] - 1;
        if (paging[i] !== '...') {
          items.push(
            <Button onClick={() => this.onclickPage(pageNum)} key={"page" + pageNum}
              className={this.state.page === pageNum ? "buttonPageActive" : "buttonPage"}
              style={{ marginLeft: 5, flex: 1 }}>{paging[i]}</Button>
          )
        } else {

          items.push(
            <label key={"pageNan" + i} style={{ marginLeft: 5, flex: 1, padding: 5 }}>{paging[i]}</label>
          )
        }
      }
      return (
        <>
          <Button onClick={() => this.onclickPage(this.state.page - 1)} className="buttonPage" style={{ marginLeft: 5, flex: 1 }}>ก่อนหน้า</Button>
          {items}
          <Button onClick={() => this.onclickPage(this.state.page + 1)} className="buttonPage" style={{ marginLeft: 5, flex: 1 }}>ถัดไป</Button>
        </>
      )
    }
  }

  onclickPage(p) {
    console.log(p);
    if (p > -1 && p < this.state.totalPage) {
      if (p !== this.state.page) {
        this.setState({ page: p });
        this.getBookshelf(this.state.user.token, this.state.sortBy, p, this.state.shelfId);
      }
    } else {
      console.log("out of page");
    }
  }

  onCheckCont(cId) {
    const check = this.state.ContentCheck;
    if (this.state.ContentCheck.length > 0) {
      var has;
      var index;
      for (let i = 0; i < check.length; i++) {
        if (cId === check[i]) {
          has = true;
          index = i;
          break;
        }
        else { has = false; }
      }
      if (has) { check.splice(index, 1); }
      else { check.push(cId); }
    } else {
      check.push(cId);
    }
    this.setState({ ContentCheck: check });
  }

  onDeleteClick() {
    if (this.state.ContentCheck.length > 0) {

      Swal.fire({
        title: 'ลบรายการ?',
        text: 'คุณต้องการลบรายการออกจากชั้นหนังสือใช่ไหม',
        type: 'warning',
        showCancelButton: true,
        confirmButtonText: 'ใช่',
        cancelButtonText: 'ไม่'
      }).then((result) => {
        if (result.value) {
            const api = axios.create({
            baseURL: this.props.mainpoint,
            headers: {
              "Content-Type": "application/json",
              "token": this.state.user.token
            },
            timeout: 85000
          });
          let self = this;
          for(var i=0;i<this.state.ContentCheck.length+1;i++){
            if(i === this.state.ContentCheck.length){
              this.setState({ContentCheck:[]});
              setTimeout(function(){
                self.getBookshelf(self.state.user.token, self.state.sortBy, self.state.page, self.state.shelfId);
              }, 1000);
            }else{
              api.delete('/api/v1/shelves/'+this.state.shelfId+'/'+this.state.ContentCheck[i]).then(
                success => {
                  const { status, data } = success
                  if (status === 201 || status === 200) {
                    console.log(data.message);
                    this.getBookshelf(this.state.user.token, this.state.sortBy, this.state.page, this.state.shelfId);
                  } else {
                    console.error('Error', status)
                  }
                },
                error => {
                  this.setState({ loading: false });
                  console.error('Error', error)
                }
              );
            }
          }
        } else if (result.dismiss === Swal.DismissReason.cancel) {
       
        }
      })
    } else {
      Swal.fire({
        type: 'warning',
        text: 'ยังไม่ได้เลือกรายการ',
      });
    }
  }

  getShelf(token) {
    // console.log("token : "+token);
    const api = axios.create({
      baseURL: this.props.mainpoint,
      headers: {
        "Content-Type": "application/json",
        "token": token
      },
      timeout: 85000
    });
    api.get('/api/v1/shelves').then(
      success => {
        const { status, data } = success
        if (status === 201 || status === 200) {
          // console.log(data);
          this.setState({ selfList: data.result, selecteContent: 0})
        } else {
          console.error('Error', status)
        }
      },
      error => {
        this.setState({ loading: false });
        console.error('Error', error)
      }
    );
  }

  setShelf(){
    const items = [];
    items.push({ value: '0', label: <label className="selectList"><FaTh style={{ marginRight: 10 }} />ทั้งหมด</label> , Select:true});
    for (var i = 0; i < this.state.selfList.length; i++) {
      let shelfId = this.state.selfList[i].shelfId;
      items.push({ value: shelfId, label: <label className="selectList"><FaBook style={{ marginRight: 10 }} />{this.state.selfList[i].name}</label> },)
    }
    items.push({ value: '99', label: <label style={{ color: '#07b169' }}><FaPlusSquare style={{ marginRight: 10 }} />สร้างใหม่</label>});
    return (
      <>
        <Col lg={3} xs={12}>
          <Col xs={12} style={{ padding: 0, color: 'gray' }}>เลือกชั้นหนังสือ</Col>
          <Col xs={12} style={{ padding: 0 }}>
            <Select
              value={this.state.selecteContent}
              onChange={this.handleChange}
              defaultValue={items[0]}
              options={items}
              isSearchable={false}
              styles={{
                control: (styles, state) => ({
                  ...styles,
                  borderColor: state.isFocused ? '#80bdff' : '#ced4da',
                  boxShadow: state.isFocused ? '0 0 0 0.2rem rgba(0,123,255,.25)' : null
                })
              }}
            />
          </Col>
        </Col>
      </>
    )
  }

  onSearchText(event) {
    this.setState({ searchText: event.target.value });
  }

  handleKeyPress(target) {
    if(target.charCode === 13){
      this.onSearchClick();    
    } 
  }

  onNewShelfText(event) {
    this.setState({ newShelfText: event.target.value });
  }

  addNewShelf(){
    if(this.state.shelfId === '0'){
      if(this.state.newShelfText !== ''){
        const api = axios.create({
          baseURL: this.props.mainpoint,
          headers: {
            "Content-Type": "application/json",
            "token": this.state.user.token
          },
          timeout: 85000
        });
        api.post('/api/v1/shelves',this.state.newShelfText).then(
          success => {
            const { status, data } = success
            if (status === 201 || status === 200) {
              // console.log(data);
              this.newShelfClose();
              this.getShelf(this.state.user.token);
            } else {
              console.error('Error', status)
            }
          },
          error => {
            this.setState({ loading: false });
            console.error('Error', error)
          }
        );
      }
    }else{
      const api = axios.create({
        baseURL: this.props.mainpoint,
        headers: {
          "Content-Type": "application/json",
          "token": this.state.user.token
        },
        timeout: 85000
      });
      api.put('/api/v1/shelves/'+this.state.shelfId,this.state.newShelfText).then(
        success => {
          const { status, data } = success
          if (status === 201 || status === 200) {
            console.log(data);
            this.newShelfClose();
            this.getShelf(this.state.user.token);
            window.location.reload(false);
          } else {
            console.error('Error', status)
          }
        },
        error => {
          this.setState({ loading: false });
          console.error('Error', error)
        }
      );
    }
  }

  deleteSelf(){
    if(this.state.shelfId !== '0'){
      if(this.state.bookshelf.length > 0){
        Swal.fire({
          title: 'มีหนังสือในชั้น?',
          text: 'ลบหนังสือหรือย้ายออกจากชั้นก่อน',
          type: 'warning'})
      }else{
        Swal.fire({
          title: 'ลบรายการ?',
          text: 'คุณต้องการลบชั้นหนังสือใช่ไหม',
          type: 'warning',
          showCancelButton: true,
          confirmButtonText: 'ใช่',
          cancelButtonText: 'ไม่'
        }).then((result) => {
          if (result.value) {
            const api = axios.create({
              baseURL: this.props.mainpoint,
              headers: {
                "Content-Type": "application/json",
                "token": this.state.user.token
              },
              timeout: 85000
            });
            api.delete('/api/v1/shelves/'+this.state.shelfId).then(
              success => {
                const { status, data } = success
                if (status === 201 || status === 200) {
                  console.log(data);
                  this.getShelf(this.state.user.token);
                  window.location.reload(false);
                } else {
                  console.error('Error', status)
                }
              },
              error => {
                this.setState({ loading: false });
                console.error('Error', error)
              }
            );
          } else if (result.dismiss === Swal.DismissReason.cancel) {
         
          }
        });
      }
    }
  }

  onSearchClick() {
    // console.log(this.state.selecteContent, this.state.selecteSort, this.state.searchText)
    var sort = '', shelfid = '0',searchText = '';
    if (this.state.selecteContent) {
      shelfid = this.state.selecteContent.value;
      // this.setState({ shelfId: this.state.selecteContent.value});
    } else {
      shelfid = this.state.shelfId;
    }

    if (this.state.selecteSort) {
      sort = this.state.selecteSort.value;
      // this.setState({sortBy: this.state.selecteSort.value });
    } else {
      sort = this.state.sortBy;
    }

    if(this.state.searchText !== ''){
      searchText = this.state.searchText;
      this.getBookshelf(this.state.user.token, sort, this.state.page, shelfid, searchText);
      // this.setState({ContentCheck:[],searchText:''});
    }else{
      Swal.fire({
        type: 'warning',
        text: 'ยังไม่ได้ใส่คำค้นหา',
      });
    }

    this.setState({ContentCheck:[]});
    this.getBookshelf(this.state.user.token, sort, this.state.page, shelfid, searchText);
  }

  setShelfChange(){
    const items = [];
    for (var i = 0; i < this.state.selfList.length; i++) {
      if(this.state.selfList[i].shelfId !== this.state.shelfId){
        let shelfId = this.state.selfList[i].shelfId;
        items.push(<div key={this.state.selfList[i].shelfId}>
          <label>
            <input type="radio" name="shelf" value={shelfId} style={{marginRight:5}} onChange={()=>this.onChangeShelf(shelfId)}/>
            {this.state.selfList[i].name}
        </label>
        </div>)
      }
    }
   
    return (
      <>
        <Col xs={12}>
          {items}
        </Col>
      </>
    )
  }

  onChangeShelf(shelfId){
    this.setState({changeShelfId:shelfId});
  }

  onClickShelf(){
    // console.log(this.state.ContentCheck,this.state.changeShelfId);
    if(this.state.changeShelfId !== ''){
      const api = axios.create({
        baseURL: this.props.mainpoint,
        headers: {
          "Content-Type": "application/json",
          "token": this.state.user.token
        },
        timeout: 85000
      });
      let self = this;
      for(var i=0;i<this.state.ContentCheck.length+1;i++){
        if(i === this.state.ContentCheck.length){
          this.sortClose();
          this.setState({ContentCheck:[]});
          setTimeout(function(){
            self.getBookshelf(self.state.user.token, self.state.sortBy, self.state.page, self.state.shelfId);
          }, 1000);
        }else{
          let content = this.state.ContentCheck[i]
          api.post('/api/v1/shelves/'+this.state.changeShelfId+'/'+content).then(
            success => {
              const { status, data } = success
              if (status === 201 || status === 200) {
                console.log(data.message);
                api.delete('/api/v1/shelves/'+this.state.shelfId+'/'+content).then(
                  success => {
                    const { status, data } = success
                    if (status === 201 || status === 200) {
                      console.log(data.message);
                    } else {
                      console.error('Error', status)
                    }
                  },
                  error => {
                    this.setState({ loading: false });
                    console.error('Error', error)
                  }
                );
              } else {
                console.error('Error', status)
              }
            },
            error => {
              this.setState({ loading: false });
              console.error('Error', error)
              Swal.fire({
                type: 'warning',
                text: error,
              });
            }
          );
        }
      }
    }
  }

  render() {
    const { t } = this.props;

    return (
      <>
        <MetaTags>
            <title>{'OBEC Content Center'}</title>
        </MetaTags>
        <Loader loading={this.state.loading} />

        <Row>
          <Col xs={12} className="p-0">
            <img src={require("../img/headerStore.png")} alt="" width="100%" />
            <label style={{ color: '#ffffff', fontSize: '5vmax', fontWeight: 'bold', textAlign: 'center', position: 'absolute', top: '25%', left: 0, right: 0 }}>
              {t('shelf.myshelf')}
              </label>
          </Col>
        </Row>
        <Row className="content">
          <Col xs={12}><h4>ตัวกรอง</h4></Col>
          <Col xs={12} style={{ padding: 0 }}>
            <Row style={{ margin: 0 }}>
              {this.setShelf()}
              <Col lg={3} xs={12}>
                <Col xs={12} style={{ padding: 0, color: 'gray' }}>จัดเรียงตาม</Col>
                <Col xs={12} style={{ padding: 0 }}>
                  <Select
                    value={this.state.selecteSort}
                    onChange={this.sortChange}
                    isSearchable={false}
                    options={[
                      { value: '4', label: 'วันที่' },
                      { value: '3', label: 'ความนิยม' },
                      { value: '1', label: 'ตัวอักษร' }
                    ]}
                    styles={{
                      control: (styles, state) => ({
                        ...styles,
                        borderColor: state.isFocused ? '#80bdff' : '#ced4da',
                        boxShadow: state.isFocused ? '0 0 0 0.2rem rgba(0,123,255,.25)' : null
                      })
                    }}
                  />
                </Col>
              </Col>
              <Col lg={4} xs={12}>
                <Col xs={12} style={{ padding: 0, color: 'gray' }}>ค้นหา</Col>
                <InputGroup className="mb-3">
                  <FormControl
                    placeholder="พิมพ์คำที่ต้องการค้นหา..."
                    aria-label="Recipient's username"
                    aria-describedby="basic-addon2"
                    value={this.state.searchText}
                    onChange={this.onSearchText}
                    onKeyPress={this.handleKeyPress} 
                  />
                  <InputGroup.Append style={{ backgroundColor: '#dedede', borderTopRightRadius: '.25rem', borderBottomRightRadius: '.25rem',zIndex:0,padding:10}}>
                      <FaSearch />
                  </InputGroup.Append>
                </InputGroup>
              </Col>
              <Col lg={2} xs={12}>
                <Col xs={12} style={{ padding: 0, opacity: 0 }}>.</Col>
                <Button onClick={() => this.onSearchClick()} variant="success" style={{ width: '100%' }}>ค้นหา</Button>
              </Col>
              <Col xs={12}><Col style={{ padding: 0, borderBottom: '1px solid #cccccc', marginTop: 10 }}></Col></Col>

              <Col xs={12}>
                <Row style={{ margin: 0, marginTop: 20 }}>
                  {this.setBookshelf()}
                </Row>

                <Row style={{ marginTop: 10, marginLeft: 0, marginRight: 0 }}>
                  <Col xs={12} style={{ textAlign: 'right', flexDirection: 'row' }}>
                    {this.setPageNumb()}
                  </Col>
                </Row>

                <Row style={{ margin: 0, marginTop: 20 }}>
                  <Col xs={6}>
                  {this.state.shelfId !== '0' && this.state.shelfId !== '99' && this.state.shelfId !== '1' && <>
                    <Button onClick={() => this.editSelf()} className="buttonWhite" style={{ marginRight: 20 }}>เปลี่ยนชื่อชั้นหนังสือ</Button>
                    <Button onClick={() => this.deleteSelf()} className="buttonWhite"><FaTrash style={{ marginRight: 10 }} />ลบชั้นหนังสือ</Button>
                    </>}
                  </Col>
                {this.state.shelfId !== '0' && this.state.shelfId !== '99' && 
                  <Col xs={6} style={{ textAlign: 'right' }}>
                    <Button onClick={() => this.onDeleteClick()} className="buttonRed"><FaTrash style={{ marginRight: 10 }} />ลบ</Button>
                    <Button onClick={this.sortShow} className="buttonBlue" style={{ marginLeft: 20 }}><FaSyncAlt style={{ marginRight: 10 }} />ย้าย</Button>
                  </Col>}
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>

        <Modal show={this.state.showSort} onHide={this.sortClose} size="sm" aria-labelledby="contained-modal-title-vcenter" centered >
          <Modal.Header closeButton>
            <h4>เลือกชั้นหนังสือ</h4>
          </Modal.Header>
          <Modal.Body>
            <Row style={{ marginTop: 10, padding: 10 }}>
              {this.setShelfChange()}
            </Row>
          </Modal.Body>
          <Modal.Footer style={{ justifyContent: 'center' }}>
            <Button variant="danger" onClick={this.sortClose}>ยกเลิก</Button>
            <Button variant="success" onClick={() => this.onClickShelf()}>ยืนยัน</Button>
          </Modal.Footer>
        </Modal>

        <Modal show={this.state.showNewShelf} onHide={this.newShelfClose} size="sm" aria-labelledby="contained-modal-title-vcenter" centered >
          <Modal.Header closeButton>
            {this.state.shelfId !== '0' && <h4>เปลี่ยนชื่อชั้นหนังสือใหม่</h4>}
            {this.state.shelfId === '0' && <h4>สร้างชั้นหนังสือใหม่</h4>}
          </Modal.Header>
          <Modal.Body>
            <Row style={{ marginTop: 10, padding: 10 }}>
              <Col xs={12}>
                <label style={{marginRight:5}}>ชื่อ</label>
                <input type="text" name="class"
                  value={this.state.newShelfText}
                  onChange={this.onNewShelfText}
                />
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer style={{ justifyContent: 'center' }}>
            <Button variant="danger" onClick={this.newShelfClose}>ยกเลิก</Button>
            <Button variant="success" onClick={() => this.addNewShelf()}>ยืนยัน</Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }

  pagination(c, m, r = 2) {  //c หน้าปัจจุบัน  m จำนวนหน้าทั้งหมด r  ระยะของช่วงปุ่มเลขหน้า
    let current = c,
      last = m,
      delta = r,
      left = current - delta,
      right = current + delta + 1,
      range = [],
      rangeWithDots = [],
      l;

    for (let i = 1; i <= last; i++) {
      if (i === 1 || i === last || (i >= left && i < right)) {
        range.push(i);
      }
    }

    for (let i of range) {
      if (l) {
        if (i - l === 2) {
          rangeWithDots.push(l + 1);
        } else if (i - l !== 1) {
          rangeWithDots.push('...');
        }
      }
      rangeWithDots.push(i);
      l = i;
    }

    return rangeWithDots;
  }

  ctypeName(cId) {
    if (cId === 1) { return 'book' }
    else if (cId === 2) { return 'application' }
    else if (cId === 3) { return 'vdo' }
    else if (cId === 4) { return 'images' }
    else if (cId === 5) { return 'sound' }
    else if (cId === 6) { return 'Multimedia' }
    else if (cId === 8) { return 'test' }
    else if(cId == 11){return 'template'}
    else { return 'book' }
  }

}

export default translate(Bookshelf);
