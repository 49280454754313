import React from 'react';
import { Row,Col,Modal,Button,ButtonToolbar,OverlayTrigger,Popover,Container} from 'react-bootstrap';
import axios from 'axios';
import Loader from '../component/loader';
import Rating from 'react-rating';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import Tag from '../component/tag'
import { translate } from 'react-switch-lang';
import MetaTags from 'react-meta-tags';

import {
    FacebookShareButton,FacebookIcon,
    TwitterShareButton,TwitterIcon,
    WhatsappShareButton,WhatsappIcon,
    PinterestShareButton,PinterestIcon,
    LineShareButton,LineIcon,
    EmailShareButton,EmailIcon,
  } from 'react-share';
import { async } from 'q';

class Detail extends React.Component {

    constructor(props, context) {
        super(props, context);
        // console.log(props);
        this.handleShow = this.handleShow.bind(this);
        this.handleClose = this.handleClose.bind(this);

        this.reportShow = this.reportShow.bind(this);
        this.reportClose = this.reportClose.bind(this);

        this.illTextChang = this.illTextChang.bind(this);
        this.reviewTextChang = this.reviewTextChang.bind(this);

        this.qrcodeShow = this.qrcodeShow.bind(this);
        this.qrcodeClose = this.qrcodeClose.bind(this);

        this.shareShow = this.shareShow.bind(this);
        this.shareClose = this.shareClose.bind(this);

        this.galleryShow = this.galleryShow.bind(this);
        this.galleryClose = this.galleryClose.bind(this);

        this.mediaShow = this.mediaShow.bind(this);
        this.mediaClose = this.mediaClose.bind(this);

        this.state = {
            show: false,
            isLogin:false,
            user:{},
            showReport: false,
            imgShow: '',
            imgGallery:'',
            detailType: 0,
            contentId: props.match.params.handle,
            imgList:[],
            illegalList:[],
            relatedList:[],
            reViewList:[],
            addRate:0,
            illegalSelect:'',
            illegalText:'',
            contentDetail:{},
            reviewsText:'',
            myReview:false,
            reviewNum:0,
            rating:0,
            visitor:0,
            downloads:0,
            qrCode:'',
            showQrcode: false,
            page:0,
            totalPage:0,
            showShare:false,
            linkShare: window.location.href,
            showGallery:false,
            showMedia:false,
            media:'',
            allowDl: 'n',
            allowWeb: 'n',
            contentName:'',
            isFav:'n',
            shelves: [],
            examUrl : ''
        };
    }
    
    async componentDidMount() {
        setTimeout(() => {
            window.scrollTo({ top: 0});
          }, 500);
        this.setState({imgShow: require("../img/no_image.png")});
        var islogin = JSON.parse(await localStorage.getItem('islogin'));
        var token = ""
        if(islogin){
            this.setState({ isLogin: true,user:islogin});
            token = islogin.token;
        }else{
            this.setState({ isLogin: false,user:{}});
        }
        this.getIllegals();
        this.getDetail(token,this.state.contentId);
        this.getRelated(this.state.contentId);
        this.getReviews(token,this.state.page,this.state.contentId);
        // this.getMyReview(token,this.state.contentId);
    }

    componentWillReceiveProps(newProps){
        setTimeout(() => {
            window.scrollTo({ top: 0});
          }, 500);
        this.setState({imgShow: require("../img/no_image.png")});
        var islogin = JSON.parse(localStorage.getItem('islogin'));
        var token = ""
        if(this.state.islogin){
            token = this.state.user.token;
        }
        this.setState({contentId:newProps.match.params.handle});
        this.getDetail(token,newProps.match.params.handle);
        this.getRelated(newProps.match.params.handle);
        this.getReviews(token,this.state.page,newProps.match.params.handle);
        // this.getMyReview(token,newProps.match.params.handle);
        if(newProps.searchWord !== '' && newProps.searchWord !== this.props.searchWord){
            this.props.history.push('/search', newProps.searchWord);
          }
    }

    handleClose() {this.setState({show:false, addRate:0});}
    handleShow() {if(this.state.isLogin){this.setState({ show: true });}}

    reportClose() {this.setState({showReport:false, illegalSelect:'', illegalText:''});}
    reportShow() {this.setState({ showReport: true });}

    qrcodeClose() {this.setState({showQrcode:false});}
    qrcodeShow() {this.setState({qrCode: this.state.contentDetail.content.qrCode,showQrcode: true });}

    shareClose() {this.setState({showShare:false});}
    shareShow() {this.setState({showShare: true });}

    galleryClose() {this.setState({showGallery:false});}
    galleryShow() {this.setState({showGallery: true });}

    mediaClose() {this.setState({showMedia:false});}
    mediaShow() {this.setState({showMedia: true });}

    tagIcon(type) {
        if(type > 0){
            return(<Tag ctype ={type}/>)
        }
    }

    readIcon(type,allowDl,allowWeb) {
        if(allowDl === 'y' && allowWeb === 'y'){
            if(type === 1) {
                return (
                    <Col className="my-3 my-sm-0">
                        {/* <a href={this.state.media} target="_blank"> */}
                            <div className="radiusGreen" onClick={() => this.getMedia(this.state.contentId)}>
                                <img src={require("../img/read_bt.png")} alt="" style={{ width: 20, marginTop: '-3px', marginRight: 5 }} />
                                อ่านเล่มนี้
                            </div>
                        {/* </a> */}
                    </Col>
                )
            }else if (type === 3 || type === 6) {
                return (
                    <Col className="my-3 my-sm-0">
                        {/* <a href={this.state.media} target="_blank"> */}
                            <div className="radiusGreen" style={{ width: '100%',minWidth:95}} onClick={() => this.getMedia(this.state.contentId)}>
                                <img src={require("../img/watch_bt.png")} alt="" style={{ width: 20, marginTop: '-3px', marginRight: 5 }} />
                                ดูออนไลน์
                            </div>
                        {/* </a> */}
                    </Col>
                )
            }else if (type === 5) {
                return (
                    <Col className="my-3 my-sm-0">
                        {/* <a href={this.state.media} target="_blank"> */}
                            <div className="radiusGreen" style={{ width: '100%',minWidth:95}} onClick={() => this.getMedia(this.state.contentId)}>
                                <img src={require("../img/listen_bt.png")} alt="" style={{ width: 20, marginTop: '-3px', marginRight: 5 }} />
                                ฟังออนไลน์
                            </div>
                        {/* </a> */}
                    </Col>
                )
            }else if (type === 4) {
                return (
                    <Col className="my-3 my-sm-0">
                        {/* <a href={this.state.media} target="_blank"> */}
                            <div className="radiusGreen" style={{ width: '100%',minWidth:95}} onClick={() => this.getMedia(this.state.contentId)}>
                                <img src={require("../img/watch_bt.png")} alt="" style={{ width: 20, marginTop: '-3px', marginRight: 5 }} />
                                ดาวน์โหลดรูปภาพ
                            </div>
                        {/* </a> */}
                    </Col>
                )
            }else if (type === 8) {
                if(this.state.examUrl == ''){
                    this.getExamUrl(this.state.user.token,this.state.contentId);
                }
                
                return (
                    <Col className="my-3 my-sm-0">
                        <a href={this.state.examUrl} target="_blank">
                            <div className="radiusGreen" style={{ width: '100%',minWidth:95}} onClick={() => this.getShelf(this.state.user.token,this.state.contentId)}>
                                <img src={require("../img/test_bt.png")} alt="" style={{ width: 20, marginTop: '-3px', marginRight: 5 }} />
                                ทำข้อสอบ
                            </div>
                        </a>
                    </Col>
                )
            }else if (type === 11) {
                return (
                    <Col className="my-3 my-sm-0">
                        {/* <a href={this.state.media} target="_blank"> */}
                            <div className="radiusGreen" style={{ width: '100%',minWidth:95}} onClick={() => this.getMedia(this.state.contentId)}>
                                <img src={require("../img/test_bt.png")} alt="" style={{ width: 20, marginTop: '-3px', marginRight: 5 }} />
                                ดาวน์โหลด
                            </div>
                        {/* </a> */}
                    </Col>
                )
            }else if (type === 2){
                return (
                    <Col className="my-3 my-sm-0">
                        {/* <a href={this.state.media} target="_blank"> */}
                            <div className="radiusGreen" style={{ width: '100%',minWidth:95}} onClick={() => this.getMedia(this.state.contentId)}>
                                ดาวน์โหลดแอปพลิเคชัน
                            </div>
                        {/* </a> */}
                    </Col>
                )
            }
        }else{
            return (
                <>
                <Col className="my-3 my-sm-0">
                    <div className="radiusReadOnly">เนื้อหาที่ไม่สามารถดาวน์โหลดได้</div>
                </Col>
                </>
            )
        }
    }

    async getExamUrl(token,cId) {
        
        const api = axios.create({
            baseURL: this.props.mainpoint,
            headers: {
              "Content-Type": "application/json",
              "token": token
            },
            timeout: 85000
          });

          await api.get(`/api/v1/exams/${cId}/url`).then(
            success => {
              const { status, data } = success
              if (status === 201 || status === 200) {

                  if(data.result.length > 0){
                    this.setState({examUrl : data.result});
                  }

              } else {
                  console.error('Error', status)
              }
            },
            error => {
              console.error('Error', error)
            }
          );  
    }

    getShelf(token,cId) {
        const api = axios.create({
          baseURL: this.props.mainpoint,
          headers: {
            "Content-Type": "application/json",
            "token": token
          },
          timeout: 85000
        });
        api.get('/api/v1/shelves').then(
          success => {
            const { status, data } = success
            if (status === 201 || status === 200) {
                // console.log(data)
                if(data.result.length > 0){
                    this.addToSelf(cId,data.result[0].shelfId);
                }else{
                    this.addShelves(token,cId)
                }
            } else {
                console.error('Error', status)
            }
          },
          error => {
            console.error('Error', error)
          }
        );  
    }

    addToSelf =async(contId,shelfId)=>{
        // console.log(contId,this.state.user.token);
        const api = axios.create({
        baseURL: this.props.mainpoint,
        headers: {
            "Content-Type": "application/json",
            "token": this.state.user.token
        },
        timeout: 85000
        });
        api.post('/api/v1/shelves/'+shelfId+'/'+contId).then(
            success => {
                const { status, data } = success
                if (status === 201 || status === 200) {
                console.log(data.message);
                } else {
                console.error('Error', status)
                }
            },
            error => {
                this.setState({ loading: false });
                // console.error('Error', error)
            }
        );
    }

    addShelves(token,cId){
        const api = axios.create({
            baseURL: this.props.mainpoint,
            headers: {
                "Content-Type": "application/json",
                "token": token
            },
            timeout: 85000
            });
            api.post('/api/v1/shelves','default').then(
            success => {
                const { status, data } = success
                if (status === 201 || status === 200) {
                    // console.log(data)
                    this.addToSelf(cId,data.result.shelfId);
                } else {
                console.error('Error', status)
                }
            },
            error => {
                this.setState({ loading: false });
                console.error('Error', error)
            }
        );
    }

    getDetail(token,contId){
        this.setState({ loading: true });
        var header = {};
        if(token === ""){
            header = {
                "Content-Type": "application/json"
              }
        }else{
            header = {
                "Content-Type": "application/json",
                "token":token
              }
        }
        const api = axios.create({
            baseURL: this.props.mainpoint,
            headers: header,
            timeout: 85000
        });
        api.get('/api/v1/contents/'+contId+'/details').then(
            success => {
                this.setState({loading:false});
                const {status,data} = success
                if (status === 201 || status === 200) {
                    // console.log(data);
                    this.setState({contentDetail:data.result,detailType: data.result.cType.ctypeId})
                    if(data.result.content){
                        this.setState({imgShow:data.result.content.imgUrl, rating:data.result.content.rating, visitor:data.result.content.visitor, downloads:data.result.content.cntDlStr, contentName:data.result.content.name });

                        if(data.result.content.flgAllowDl === 'y'){
                            this.setState({allowDl:'y'});
                            // this.getMedia(data.result.content.cId);
                        }else{
                            this.setState({allowDl:'n'});
                            // this.getMedia(data.result.content.cId);
                        }

                        if(data.result.content.flgSuppWeb === 'y'){
                            this.setState({allowWeb:'y'});
                        }else{
                            this.setState({allowWeb:'n'});
                        }
                    }
                    
                    if(data.result.stats){
                        this.setState({isFav: data.result.stats.flgFav})
                    }
                } else {
                    console.error('Error',status)
                }
            },
            error => {
                this.setState({ loading: false });
                console.error('Error',error)
            }
        );
    }

    setImageList(){
        if(JSON.stringify(this.state.contentDetail) !== JSON.stringify({})){
            if(this.state.contentDetail.previews.length > 0){
                const items =[];
                var h = 100;
                var w = 100;
                if(this.state.contentDetail.cType.ctypeId === 1){
                    h = 130
                }
                if(this.state.contentDetail.cType.ctypeId === 3){
                    h = 70
                }
                for (var i = 0; i < this.state.contentDetail.previews.length; i++) {
                    let imgReviews = this.state.contentDetail.previews[i];
                    items.push(<div key={this.state.contentDetail.previews[i].cimageId}
                        onClick={() => {
                            this.changeGallery(imgReviews);
                            this.galleryShow();
                            }
                        }
                        style={{height: h ,width: w ,overflow:'hidden',display:'inline-block',marginRight:20,cursor:'pointer',backgroundColor:'#e4e4e4'}}>
                        <img src={this.state.contentDetail.previews[i].imgUrl} alt="" width="100%" />
                    </div>)
                }
                return(
                    <Row style={{ margin: 0 }}>
                        <Col xs={12} style={{ backgroundColor: '#f3f2f3', padding: 10}}>
                            <div style={{width:'100%',height:'100%',overflow:'hidden',cursor:'pointer'
                            }}
                            onClick={() => {
                                this.changeGallery();
                                this.galleryShow();
                                }
                            }
                            >
                                <img src={this.state.imgShow} alt="" style={{maxWidth:'100%', maxHeight:'100%', minWidth:'60%'}}
                                onError={({nativeEvent:{error}})=>console.clear()}
                                />
                            </div>
                        </Col>
                        <Col xs={12} style={{ marginTop: 10, padding: 10, overflow: 'auto', whiteSpace: 'nowrap' }}>
                            {items}
                        </Col>
                    </Row>
                )
            }else{
                return(
                    <Row style={{ margin: 0 }}>
                        <Col xs={12} style={{backgroundColor: '#f3f2f3', padding: 10}}>
                            <div style={{width:'100%',height:'100%',overflow:'hidden',maxHeight:600}}>
                                <img src={this.state.imgShow} alt="" style={{maxWidth:'100%', maxHeight:'100%', minWidth:'60%'}}/>
                            </div>
                        </Col>
                    </Row>
                )
            }
        }
    }

    setGalleryList(){
        if(JSON.stringify(this.state.contentDetail) !== JSON.stringify({})){
            if(this.state.contentDetail.previews.length > 0){
                const items =[];
                for (var i = 0; i < this.state.contentDetail.previews.length; i++) {
                    let imgReviews = this.state.contentDetail.previews[i];
                    items.push(<Col la={6} md={6} xs={12} key={i}
                        onClick={() => this.changeGallery(imgReviews)}
                        style={this.state.imgGallery === imgReviews.imgUrl ? 
                            {padding: 5,cursor:'pointer',boxShadow:'0 0 3px red'} : 
                            {padding: 5,cursor:'pointer'}
                            }>
                        <img src={this.state.contentDetail.previews[i].imgUrl} alt="" width="100%" />
                    </Col>)
                }
                return(
                    <Row style={{margin:0}}>
                        {items}
                    </Row>
                )
            }
        }
    }

    // nameContent(content){
    //     if(content){
    //         return(
    //             <label>{content.name}</label>
    //         )
    //     }
    // }

    setDetail(ctd) {
        if(ctd.attributes){
            const items =[],itemTag =[],itemCate =[],itemPublish =[];
            for (var i = 0; i < ctd.attributes.length; i++) {
                items.push(<Row key={ctd.attributes[i].cattrId} style={{margin:0,paddingBottom:10}}>
                <Col xs={4} style={{ color: 'gray', padding: 0 }}>{ctd.attributes[i].name}</Col>
                <Col xs={2} style={{ padding: 0 }}>{ctd.attributes[i].values}</Col>
                <Col xs={6} style={{ padding: 0 }}>{ctd.attributes[i].unit}</Col>
                </Row>)
            }
            for (var j = 0; j < ctd.hashtags.length; j++) {
                let indexJ = j;
                itemTag.push(
                    <Link 
                        to={{
                        pathname: '/store/'+ctd.hashtags[j].htagId,
                        // hash: ctd.hashtags[j].name,
                        }}
                        key={'j'+indexJ}
                    >
                        <label key={ctd.hashtags[j].htagId} className="outlineGreen" style={{ marginRight: 3, marginBottom:3, cursor: 'pointer', maxWidth: '300px', overflow: 'hidden', textOverflow: 'ellipsis'}}>{ctd.hashtags[j].name}</label>
                    </Link>
                )
            }
            for (var k = 0; k < ctd.deweies.length; k++) {
                if(itemCate.length === 0){
                    itemCate.push(<label key={ctd.deweies[k].obecId+'0'+k} style={{margin:0}}>{ctd.deweies[k].name}</label>)
                }else{
                    itemCate.push(<label key={ctd.deweies[k].obecId+'0'+k} style={{margin:0}}>,{ctd.deweies[k].name}</label>)
                }
            }
            for (var l = 0; l < ctd.subjects.length; l++) {
                if(itemCate.length === 0){
                    itemCate.push(<label key={ctd.subjects[l].obecId} style={{margin:0}}>{ctd.subjects[l].name}</label>)
                }else{
                    itemCate.push(<label key={ctd.subjects[l].obecId} style={{margin:0}}>,{ctd.subjects[l].name}</label>)
                }
            }
            for (var m = 0; m < ctd.classes.length; m++) {
                if(itemCate.length === 0){
                    itemCate.push(<label key={ctd.classes[m].obecId} style={{margin:0}}>{ctd.classes[m].name}</label>)
                }else{
                    itemCate.push(<label key={ctd.classes[m].obecId} style={{margin:0}}> , {ctd.classes[m].name}</label>)
                }
            }
            for (var n = 0; n < ctd.publishers.length; n++) {
                if(itemPublish.length === 0){
                    itemPublish.push(<label key={ctd.publishers[n].pubId} style={{margin:0}}>{ctd.publishers[n].name}</label>)
                }else{
                    itemPublish.push(<label key={ctd.publishers[n].pubId} style={{margin:0}}> , {ctd.publishers[n].name}</label>)
                }
            }
            return (
                <>
                <Col xs={12} style={{padding:0}}>
                    {ctd.authors.length > 0 &&
                    <Row style={{margin:0,paddingBottom:10}}>
                        <Col xs={4} style={{ color: 'gray', padding: 0 }}>ผู้จัดทำ</Col>
                        <Col xs={8} style={{ padding: 0 }}>{ctd.authors[0].name}</Col>
                    </Row>
                    }
                    {ctd.publishers.length > 0 && <>
                    <Row style={{margin:0,paddingBottom:10}}>
                        <Col xs={4} style={{ color: 'gray', padding: 0 }}>หน่วยงาน/สำนักพิมพ์</Col>
                        <Col xs={8} style={{ padding: 0 }}>{itemPublish}</Col>
                    </Row>
                    <Row style={{margin:0,paddingBottom:10}}>
                        <Col xs={4} style={{ color: 'gray', padding: 0 }}>หมวดหมู่</Col>
                        <Col xs={8} style={{ padding: 0 }}>{itemCate}</Col>
                    </Row>
                    </>}
                    {items}
                    <Row style={{margin:0,paddingBottom:10}}>
                        <Col xs={4} style={{ color: 'gray', padding: 0 }}>Tag</Col>
                        <Col xs={8} style={{ padding: 0 }}>
                            {itemTag}
                        </Col>
                    </Row>
                </Col>
                </>
            )
        }
    }

    contentType(ctype){
        const { t } = this.props;

        if(ctype){
            if (ctype === 1) {
                return (<label className="radiusGreenThin">{t("tag.book")}</label>)
            }else if (ctype  === 3) {
                return (<label className="radiusPink">{t("tag.video")}</label>)
            }else if (ctype  === 5) {
                return (<label className="radiusOrange">{t("tag.sound")}</label>)
            }else if (ctype === 4) {
                return (<label className="radiusBlue">{t("tag.photo")}</label>)
            }else if (ctype  === 8) {
                return (<label className="radiusPurple">{t("tag.exam")}</label>)
            }else if (ctype  === 2) {
                return (<label className="radiusApp">{t("tag.application")}</label>)
            }else if (ctype  === 6) {
                return (<label className="radiusFlash">{t("tag.multimedia")}</label>)
            }else if (ctype  === 11) {
                return (<label className="radiusFlash">{t("tag.template")}</label>)
            }else{
                return (<label className="radiusApp" style={{background:'#ff0300'}}>! No type {this.state.ctype}</label>)
            }
        }
    }

    contentSupport(content){
        if(content){
            return(
                <Col xs={12} style={{padding:0}}>
                    <Row style={{margin:0,paddingBottom:10}}>
                    <Col xs={3} style={{ color: 'gray', padding: '0px 0 0 8px', lineHeight: '32px' }}>สนับสนุน</Col>
                    <Col xs={8} style={{ padding: 0 }}>
                        {content.flgSuppAndroid === 'y' && <img src={require("../img/androids.png")} alt="Android" title="Android" style={{ width: '32px', marginRight: '3px' }} />}
                        {content.flgSuppIos === 'y' && <img src={require("../img/apple.png")} alt="Apple" title="Apple" style={{ width: '32px', marginRight: '3px' }} />}
                        {content.flgSuppWindows === 'y' && <img src={require("../img/windows.png")} alt="Windows" title="Windows" style={{ width: '32px', marginRight: '3px' }} />}
                        {content.flgSuppWeb === 'y' && <img src={require("../img/web.png")} alt="Website" title="Website" style={{ width: '32px', marginRight: '3px' }} />}
                    </Col>
                    </Row>
                </Col>
            )
        }
    }

    setDesc(content){
        if(content){
            return(
                <Col xs={12} style={{ padding: 0, marginBottom: 10 }}>{content.description}</Col>
            )
        }
    }

    setLicense(lice){
        if(lice){
            return(
                <Col xs={12} style={{ padding: 10, backgroundColor: '#efefef', borderRadius: 10 }}>
                    <Row style={{ margin: 0 }}>
                        <Col xs={6} style={{ padding: 0 }}><img src={require("../img/cc_logo.png")} alt="" style={{ height: 25 }} /></Col>
                        <Col xs={6} style={{ padding: 0, textAlign: 'right' }}><img src={require("../img/cc_info.png")} alt="" style={{ height: 25 }} /></Col>
                        <Col xs={12} style={{ padding: 0, borderTop: '1px solid #000000', fontSize: '1.5vmin',marginTop:5}}>
                            {lice.title} 
                            {/* <a href={lice.link} target="_blank" style={{color:'#048acc'}}>{lice.description}</a> */}
                        </Col>
                    </Row>
                </Col>
            )
        }
    }

    changeImage(img){
        // console.log(img);
        this.setState({imgShow: img.imgUrl});
    }

    changeGallery(img){
        // console.log(img);
        if(img){
            this.setState({imgGallery: img.imgUrl});
        }else{
            this.setState({imgGallery: this.state.imgShow});
        }
        
    }

    getIllegals(){
        axios.get(this.props.mainpoint+'/api/v1/illegals').then(
            success => {
                const { status, data } = success
                if (status === 201 || status === 200) {
                    // console.log(data);
                    this.setState({illegalList:data.result})
                } else {
                    console.error('Error',status)
                }
            },
            error => {
                this.setState({ loading: false });
                console.error('Error',error)
            }
        );
    }

    setIllegals(){
        if (this.state.illegalList.length > 0) {
            const items = [];
            for (var i = 0; i < this.state.illegalList.length; i++) {
                let illId = this.state.illegalList[i].illId;
                items.push(<Col xs={12} key={illId}>
                    <label>
                        <input type="radio" name="report" value="1" style={{ marginRight: 5 }} id={illId} onChange={() => this.onClickIllegal(illId)} />
                        {this.state.illegalList[i].name}
                    </label>
                </Col>)
            }
            return (
              <>
                <Row style={{ backgroundColor: '#f9f9f9', padding: 10, borderRadius: 5, fontSize: 14 }}>
                    {items}
                </Row>
              </>
            )
          }
    }

    getRelated(contId){
        axios.get(this.props.mainpoint+'/api/v1/contents/'+contId+'/related').then(
            success => {
                const { status, data } = success
                if (data.code === 201 || data.code === 200) {
                    // console.log(data);
                    this.setState({relatedList:data.result})
                } else {
                    console.error('Error',status)
                }
            },
            error => {
                this.setState({ loading: false });
                console.error('Error',error)
            }
        );
    }

    setRelated(){
        if (this.state.relatedList.length > 0) {
            // console.log(this.state.relatedList)
            let numb = 5;
            if(this.state.relatedList.length < 5){
                numb = this.state.relatedList.length;
            }
            const items = [];
            for (let i = 0; i < numb; i++) {
                // console.log(this.state.relatedList[i])
                items.push(<Col xs={2} key={this.state.relatedList[i].cId}>
                    <div style={{width:'100%',marginBottom:10}}>
                        <Link to={"/detail/"+this.ctypeName(this.state.relatedList[i].ctypeId)+"/"+this.state.relatedList[i].cId} title={this.state.relatedList[i].name}>
                            <img src={this.state.relatedList[i].imgUrl} alt="" width="100%"/>
                        </Link>
                    </div>
                    {this.contentType(this.state.relatedList[i].ctypeId)}
                    <div>{this.state.relatedList[i].name}</div>
                </Col>)
            }
            return (
              <>
                <Col xs={12} style={{ backgroundColor: '#f9f9f9', border: '1px solid #e1e1e1', padding: 10, marginTop: '1vmax' }} className="hidden-xs">
                        <Row style={{ width: '100%', margin: 0 }}>
                            <Col xs={12} style={{ marginTop: 10 }}><h4>เนื้อหาใกล้เคียง</h4></Col>
                            <Col xs={12}>
                                <Row>
                                    {items}
                                </Row>
                            </Col>
                        </Row>
                    </Col>
              </>
            )
          }
    }

    clickRate(rate){
        this.setState({addRate:rate});
    }

    addRate(){
        if(this.state.addRate > 0){
            const api = axios.create({
                baseURL: this.props.mainpoint,
                headers: {
                  "Content-Type": "application/json",
                  "token":this.state.user.token
                },
                timeout: 85000
            });
            api.post('/api/v1/contents/'+this.state.contentId+'/rate/'+this.state.addRate).then(
                success => {
                    const { status, data } = success
                    if (status === 201 || status === 200) {
                        // console.log(data);
                        this.getDetail(this.state.user.token, this.state.contentId);
                        // this.getMyReview(this.state.user.token,this.state.contentId);
                        this.handleClose();
                        Swal.fire({
                            type: 'success',
                            text: data.message,
                        });
                    } else {
                        console.error('Error',status)
                    }
                },
                error => {
                    this.setState({ loading: false });
                    console.error('Error',error)
                }
            );
        }else{
            alert('ยังไม่ได้ให้คะแนน');
        }
    }

    onClickIllegal(illId){
        this.setState({illegalSelect:illId});
    }

    illTextChang(event){
        this.setState({illegalText: event.target.value});
    }

    illegalSubmit(){
        // console.log(this.state.illegalSelect,this.state.illegalText);
        if(this.state.illegalSelect !== '' && this.state.illegalText !== ''){
            const api = axios.create({
                baseURL: this.props.mainpoint,
                headers: {
                  "Content-Type": "application/json",
                  "token":this.state.user.token
                },
                timeout: 85000
            });
            let data = {
                cId: this.state.contentId,
                crptId: 0,
                description: this.state.illegalText,
                illId: this.state.illegalSelect,
                statusId: 0,
                userId: this.state.user.uId
              };
            //   console.log(data);
            api.post('/api/v1/contents/'+this.state.contentId+'/illegals',data).then(
                success => {
                    const { status, data } = success
                    if (status === 201 || status === 200) {
                        this.getDetail(this.state.user.token, this.state.contentId);
                        this.reportClose();
                        Swal.fire({
                            type: 'success',
                            text: data.message,
                        });
                    } else {
                        console.error('Error',status)
                    }
                },
                error => {
                    this.setState({ loading: false });
                    console.error('Error',error)
                }
            );
        }else{
            //alert('ยังไม่ได้เลือกหัวข้อการแจ้ง');
            Swal.fire({
                type: 'warning',
                text: 'โปรดกรอกข้อมูลให้ครบถ้วน',
            });
        }
    }

    reviewTextChang(event){
        this.setState({reviewsText: event.target.value});
    }

    cancelReview(){
        this.setState({reviewsText:''});
    }

    getMyReview(token,contId){
        const api = axios.create({
            baseURL: this.props.mainpoint,
            headers: {
              "Content-Type": "application/json",
              "token":token
            },
            timeout: 85000
        });
        api.get('/api/v1/contents/'+contId+'/reviews/my').then(
            success => {
                const { status, data } = success
                if (status === 201 || status === 200) {
                    // console.log(data);
                    if(data.result){
                        this.setState({ myReview: true }); 
                    }else{
                        this.setState({ myReview: false }); 
                    }
                } else {
                    console.error('Error',status)
                }
            },
            error => {
                this.setState({ loading: false });
                console.error('Error',error)
            }
        );
    }

    sendReview(){
        // if(this.state.myReview){
            if(this.state.reviewsText !== ''){
                const api = axios.create({
                    baseURL: this.props.mainpoint,
                    headers: {
                      "Content-Type": "application/json",
                      "token":this.state.user.token
                    },
                    timeout: 85000
                });
                let data = {
                    cId: this.state.contentId,
                    description: this.state.reviewsText,
                    rating: '',
                    statusId: '',
                    uId: this.state.user.uId
                  };
                //   console.log(data);
                api.post('/api/v1/contents/'+this.state.contentId+'/reviews',data).then(
                    success => {
                        const { status, data } = success
                        if (status === 201 || status === 200) {
                            // console.log(data);
                            this.setState({reviewsText:''});
                            console.log("sendReview");
                            console.log(this.state.contentId);
                            this.getReviews(this.state.user.token,this.state.page,this.state.contentId);

                            Swal.fire({
                                type: 'success',
                                text: 'แสดงความคิดเห็นสำเร็จ',
                            });

                        } else {
                            console.error('Error',status)
                        }
                    },
                    error => {
                        this.setState({ loading: false });
                        console.error('Error',error)
                    }
                );
            }else{
                Swal.fire({
                    type: 'warning',
                    text: 'ยังไม่ได้พิมพ์ข้อความ',
                });
            }
        // }else{
        //     Swal.fire({
        //         type: 'warning',
        //         text: 'ยังไม่ได้ให้คะแนนความพึงพอใจ ไม่สามารถเขียนรีวิวได้',
        //     });
        // }
    }

    
    getReviews(token,page,contId){
        console.log("getReviews");
        console.log(contId);
        const api = axios.create({
            baseURL: this.props.mainpoint,
            headers: {
              "Content-Type": "application/json",
              "token":token
            },
            timeout: 85000
        });
        api.get('/api/v1/contents/'+contId+'/reviews/page/'+page+'/limit/10/asc/false/sortBy/4').then(
            success => {
                const { status, data } = success
                if (status === 201 || status === 200) {
                    // console.log(data);
                    this.setState({reViewList:data.result, reviewNum:data.result.length, totalPage:data.pagination.totalPage})
                } else {
                    console.error('Error',status)
                }
            },
            error => {
                this.setState({ loading: false });
                console.error('Error',error)
            }
        );
    }

    setReviews(){
        if (this.state.reViewList.length > 0) {
            const items = [];
            for (var i = 0; i < this.state.reViewList.length ; i++) {
                items.push(<Col xs={12} key={this.state.reViewList[i].id}>
                    <Row style={{ margin: 0, paddingTop: 10, paddingBottom: 10 }}>
                        <Col md={2} xs={4}>
                            <div className='commentImg rounded-circle' 
                            style={this.state.reViewList[i].imgUrl ? {backgroundImage: 'url("'+ this.state.reViewList[i].imgUrl+'")'} : null}>
                                {/* <img src={this.state.reViewList[i].imgUrl ? this.state.reViewList[i].imgUrl:require("../img/male-user-48.png")} alt="" /> */}
                            </div>      
                        </Col>
                        <Col md={10} xs={8} style={{ padding: 0 }}>
                            <Row style={{ margin: 0 }}>
                                <Col xs={12} style={{ padding: 0, color: 'gray', fontSize: 14 }}>{this.state.reViewList[i].fullName} | {this.state.reViewList[i].updateDateStr}</Col>
                                <Col xs={12} style={{ padding: 0 }}>{this.state.reViewList[i].description}</Col>
                            </Row>
                        </Col>
                    </Row>
                </Col>  )
            }
            return (
              <>
                {items}
              </>
            )
          }
    }

    setPageNumb(){
        if (this.state.totalPage > 1) {
            const paging = this.pagination(this.state.page+1, this.state.totalPage, 2);
            const items = [];
            for (var i = 0; i < paging.length; i++) {
                let pageNum = paging[i]-1;
                if(paging[i] != '...'){
                    items.push(
                        <Button  onClick={() => this.onclickPage(pageNum)} key={"page"+pageNum}
                        className={this.state.page == pageNum ? "buttonPageActive" : "buttonPage"} 
                        style={{ marginLeft: 5, flex: 1 }}>{paging[i]}</Button>
                    )
                }else{

                    items.push(
                        <label key={"pageNan"+i}style={{ marginLeft: 5,flex:1,padding:5}}>{paging[i]}</label>
                    )
                }
            }
            return (
                <>
                <Button onClick={() => this.onclickPage(this.state.page - 1)} className="buttonPage" style={{ marginLeft: 5, flex: 1 }}>ก่อนหน้า</Button>
                 {items}
                 <Button onClick={() => this.onclickPage(this.state.page + 1)} className="buttonPage" style={{ marginLeft: 5, flex: 1 }}>ถัดไป</Button>
                </>
            )
        }
    }

    onclickPage(p){
        // console.log(p);
        if(p > -1 && p < this.state.totalPage){
            if(p != this.state.page){
                this.setState({page:p});
                this.getReviews(this.state.user.token, p ,this.state.contentId);
            }
        }else{
            console.log("out of page");
        }
    }

    onReadContent(type){
        this.mediaShow();
        // if (type === 3 || type === 5) {
        //     this.mediaShow();
        // }
    }

    getMedia(cId){
        // console.log(cId,this.state.user);
        const api = axios.create({
            baseURL: this.props.mainpoint,
            headers: {
              "Content-Type": "application/json",
              "token":this.state.user.token
            },
            timeout: 85000
        });
        api.get('/api/v1/contents/'+cId+'/dl/request/device/4').then(
            success => {
                const { status, data } = success
                if (status === 201 || status === 200) {
                    // console.log(data.result);
                    api.get('/api/v1/contents/dl/key/'+data.result.dlKey).then(
                        success => {
                            this.getShelf(this.state.user.token,cId)
                            // console.log(success);
                            window.open(success.data.result.contentURL, '_blank');
                            // this.setState({media:success.data.result.contentURL}); 
                        },
                        error => {
                            console.error('Error',error)
                        }
                    );
                } else {
                    console.error('Error',status)
                }
            },
            error => {
                console.error('Error',error)
            }
        );
    }

    favorite(cId){
        const api = axios.create({
            baseURL: this.props.mainpoint,
            headers: {
              "Content-Type": "application/json",
              "token":this.state.user.token
            },
            timeout: 85000
        });
        api.post('/api/v1/contents/'+cId+'/favorite').then(
            success => {
                const { status, data } = success
                if (status === 201 || status === 200) {
                    // console.log(data.message);
                    this.setState({isFav: data.result.flgFav})
                } else {
                    console.error('Error',status)
                }
            },
            error => {
                console.error('Error',error)
            }
        );
    }

    openLogin = () => {
        this.props.login(true);
    }

    render() {
        return (
            <>
                <MetaTags>
                    <title>{this.state.contentName}</title>
                    <meta property="og:title" content={this.state.contentName} />
                    <meta property="og:image" content={this.state.imgShow} />
                </MetaTags>
                <Loader loading={this.state.loading}/>

                <Row className="py-3">
                    <Col>
                    <Container>
                        <Row>
                        <Col xs={12} style={{ backgroundColor: '#f9f9f9', border: '1px solid #e1e1e1' }} >
                            <Row style={{ width: '100%', margin: 0 }}>
                                <Col md={6} xs={12} className="text-center mt-3 p-2">
                                    {this.setImageList()}
                                </Col>
                                <Col md={6} xs={12} className="mt-3 p-2">
                                    <Row className="pt-0 pb-3 px-3">
                                        <Col xs={12} className="text-center text-sm-left">
                                            {this.state.contentDetail.flgRecommend === 'y' &&
                                            <label className="radiusStarOrange mr-2">
                                                <img src={require("../img/starOrange.png")} alt="" style={{ width: 20, marginTop: '-3px' }} />เนื้อหาแนะนำ
                                            </label>
                                            }
                                            {this.contentType(this.state.detailType)}
                                        </Col>
                                        <Col xs={12} className="border-bottom mt-3 text-center text-sm-left">
                                            <h2>{this.state.contentName}</h2>
                                            {this.contentSupport(this.state.contentDetail.content)}
                                        </Col>
                                        <Col xs={12} className="text-secondary border-bottom py-2 text-center text-sm-left" >
                                            <label className="border-right py-0 px-2 mb-0" style={{ cursor: 'pointer' }} onClick={this.handleShow}>
                                                <span className="mr-2">คะแนน</span>
                                                <Rating
                                                placeholderRating={this.state.rating}
                                                emptySymbol={<img src={require("../img/starGray.png")} alt="" className="icon" width="20"/>}
                                                placeholderSymbol={<img src={require("../img/starYellow.png")} alt="" className="icon" width="20"/>}
                                                fullSymbol={<img src={require("../img/starYellow.png")} alt="" className="icon" width="20"/>}
                                                readonly
                                                />
                                            </label>
                                            <label className="border-right py-0 px-2 mb-0">ผู้เยี่ยมชม : {this.state.visitor}</label>
                                            <label className="py-0 px-2 mb-0">ดาวน์โหลด : {this.state.downloads}</label>
                                            {this.state.detailType === "book" &&<label style={{ paddingLeft: 10, borderLeft: '1px solid #000000'}}>
                                            <a onClick={this.qrcodeShow} style={{cursor:'pointer'}}>QR code <img src={require("../img/qr_code.png")} alt="" style={{ width: '3vmin' }} /></a>
                                            </label>}
                                            <Col xs={5} style={{ padding: 0 }}></Col>
                                        </Col>

                                        <Col xs={12} className="border-bottom pb-3 py-sm-3 px-0">
                                            <Row className="d-flex align-items-center" style={{display: '-webkit-inline-box', margin: 0}}>
                                                {this.state.isLogin && this.readIcon(this.state.detailType, this.state.allowDl, this.state.allowWeb)}
                                                {this.state.isLogin &&
                                                    <div onClick={this.handleShow} className="radiusWhite" style={{ color: '#f6b83b' }}>
                                                        <img src={require("../img/starYellow.png")} alt="" style={{ width: 16, marginTop: '-3px', marginRight: 2 }} />
                                                        คะแนน
                                                    </div>
                                                }
                                                {!this.state.isLogin &&
                                                    <div className="radiusWhite" style={{ color: '#2ac79a', padding: '5px 10px', marginLeft: '10px'}} onClick={this.openLogin}>
                                                        เข้าสู่ระบบ
                                                    </div>
                                                }
                                                <div>
                                                    <ButtonToolbar>
                                                        <OverlayTrigger
                                                        trigger="click"
                                                        key='top'
                                                        placement='top'
                                                        rootClose={true}
                                                        overlay={
                                                            <Popover id={`popover-positioned-top`}>
                                                                <Row>
                                                                    <Col>
                                                                        <FacebookShareButton  url={this.state.linkShare} imageURL={this.state.imgShow} style={{margin:'auto',width:40,cursor:'pointer'}}>
                                                                            <FacebookIcon size={40} round={true}/>
                                                                        </FacebookShareButton>
                                                                    </Col>
                                                                    <Col>
                                                                        <TwitterShareButton  url={this.state.linkShare} imageURL={this.state.imgShow} style={{margin:'auto',width:40,cursor:'pointer'}}>
                                                                            <TwitterIcon size={40} round={true}/>
                                                                        </TwitterShareButton>
                                                                    </Col>
                                                                    <Col>
                                                                        <WhatsappShareButton  url={this.state.linkShare} imageURL={this.state.imgShow} style={{margin:'auto',width:40,cursor:'pointer'}}>
                                                                            <WhatsappIcon size={40} round={true}/>
                                                                        </WhatsappShareButton>
                                                                    </Col>
                                                                    <Col>
                                                                        <PinterestShareButton  url={this.state.linkShare} imageURL={this.state.imgShow} media={this.state.imgShow} style={{margin:'auto',width:40,cursor:'pointer'}}>
                                                                            <PinterestIcon size={40} round={true}/>
                                                                        </PinterestShareButton>
                                                                    </Col>
                                                                    <Col>
                                                                        <LineShareButton  url={this.state.linkShare} imageURL={this.state.imgShow} style={{margin:'auto',width:40,cursor:'pointer'}}>
                                                                            <LineIcon size={40} round={true}/>
                                                                        </LineShareButton>
                                                                    </Col>
                                                                    <Col>
                                                                        <EmailShareButton  url={this.state.linkShare} imageURL={this.state.imgShow} style={{margin:'auto',width:40,cursor:'pointer'}}>
                                                                            <EmailIcon size={40} round={true}/>
                                                                        </EmailShareButton>
                                                                    </Col>
                                                                </Row>
                                                            </Popover>
                                                        }
                                                        >
                                                        {/* <Button variant="secondary">Popover on top</Button> */}
                                                        <div variant="secondary" className="radiusWhite" style={{ color: '#4891e3'}}>
                                                            <img src={require("../img/share.png")} alt="" style={{ width: 16, marginTop: '-3px', marginRight: 2 }} />
                                                            แชร์
                                                        </div>
                                                        </OverlayTrigger>
                                                    </ButtonToolbar>
                                                </div>
                                                {this.state.isLogin && <>
                                                <div>
                                                    <div onClick={this.reportShow} className="radiusWhite" style={{ color: '#f2641e'}}>
                                                        <img src={require("../img/message-alert.png")} alt="" style={{ width: 16, marginTop: '-3px', marginRight: 2 }} />
                                                        รายงาน
                                                    </div>
                                                </div>
                                                <div>
                                                    {this.state.isFav === 'y' ? 
                                                    <div onClick={()=>this.favorite(this.state.contentId)} className="radiusWhite" style={{ color: '#FFCDD2'}}>
                                                        <img src={require("../img/cards-heart-light.png")} alt="" style={{ width: 16, marginTop: '-3px', marginRight: 2 }} />
                                                        ยกเลิก
                                                    </div>
                                                    :
                                                    <div onClick={()=>this.favorite(this.state.contentId)} className="radiusWhite" style={{ color: '#fe5c5e'}}>
                                                        <img src={require("../img/cards-heart.png")} alt="" style={{ width: 16, marginTop: '-3px', marginRight: 2 }} />
                                                        บันทึก
                                                    </div>
                                                    }
                                                </div>
                                                </>}
                                            </Row>
                                        </Col>
                                        
                                        <Col xs={12} className="my-3 border-bottom">
                                            <h5>ข้อมูลทั่วไป</h5>
                                            {this.setDetail(this.state.contentDetail)}
                                        </Col>
                                        <Col xs={12} className="mb-3 pb-3 border-bottom">
                                            <h5>รายละเอียด</h5>
                                            <div>{this.setDesc(this.state.contentDetail.content)}</div>
                                            {this.setLicense(this.state.contentDetail.license)}
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Col>

                        {this.setRelated()}

                        <Col xs={12} style={{ backgroundColor: '#f9f9f9', border: '1px solid #e1e1e1', padding: 10, marginTop: '1vmax' }} >
                            <Row style={{ width: '100%', margin: 0 }}>
                                <Col xs={12}><label style={{ color: 'gray', marginLeft: 5 }}>{this.state.reviewNum} ความเห็น</label></Col>
                                {this.state.isLogin &&
                                <Col xs={12}>
                                    <Row style={{ margin: 0 }}>
                                        <Col md={2} xs={2} className="hiddenXs">
                                            <div className='commentImg rounded-circle' 
                                            style={this.state.user.imgUrl ? {backgroundImage: 'url("'+this.state.user.imgUrl+'")'} : null}>
                                            </div>  
                                            {/* <img src={this.state.user.imgUrl ? this.state.user.imgUrl : require("../img/male-user-48.png")} alt="" style={{ width: '90%',borderRadius:'50%'}} /> */}
                                        </Col>
                                        <Col md={10} xs={12} style={{ padding: 0 }}>
                                            <input placeholder="พิมพ์ความคิดเห็น..." style={{width:'100%',padding:10,borderRadius:5,border:'1px solid #d8d8d8'}} 
                                            value={this.state.reviewsText}
                                            onChange={this.reviewTextChang}
                                            />
                                            </Col>
                                        <Col xs={12} style={{ borderBottom: '1px solid #e2e2e2', padding: 0, textAlign: 'right' }}>
                                            <label onClick={() => this.cancelReview()} className="radiusGrayLinear" style={{ marginTop: 10 }}>ยกเลิก</label>
                                            <label onClick={() => this.sendReview()} className="radiusGreenLinear" style={{ marginTop: 10, marginLeft: 10}}>ส่ง</label>
                                        </Col>
                                    </Row>
                                </Col>
                                }
                                {this.setReviews()}
                        
                                <Col xs={12} style={{textAlign: 'right',flexDirection:'row'}}>
                                    {this.setPageNumb()}
                                </Col>
                            </Row>
                        </Col>

                    </Row>
                    </Container>
                    </Col>
                </Row>

                

                <Modal show={this.state.show} onHide={this.handleClose} size="sm" aria-labelledby="contained-modal-title-vcenter" centered >
                    <Modal.Body>
                        <Row style={{ padding: '1vmax' }}>
                            <Col xs={12} style={{ textAlign: 'center' }}>
                                {/* <label style={{
                                    padding: 10, width: '5vmax', height: '5vmax', borderRadius: '4vmax',
                                    backgroundImage: 'linear-gradient(to top left,#fec35c,#ff8c20)'
                                }}>
                                    <img src={require("../img/starWhite.png")} alt="" style={{ width: '100%' }} />
                                </label> */}
                                <img src={require("../img/rate.png")} alt="" style={{ width: 80 }} />
                            </Col>
                            <Col xs={12} style={{ textAlign: 'center' }}>ให้คะแนนความพึงพอใจ</Col>
                            <Col xs={12} style={{ textAlign: 'center', fontSize: 10, color: "gray" }}>
                                <Row>
                                    <Col xs={12}>
                                        <Rating
                                            placeholderRating={this.state.addRate}
                                            emptySymbol={<img src={require("../img/starGray.png")} alt="" className="p-2" />}
                                            placeholderSymbol={<img src={require("../img/starYellow.png")} alt="" className="p-2" />}
                                            fullSymbol={<img src={require("../img/starYellow.png")} alt="" className="p-2" />}
                                            onChange={(rate) => this.clickRate(rate)}
                                            initialRating={this.state.addRate}
                                        />
                                    </Col>
                                    <Col xs={12}>
                                        <div className="d-flex flex-row justify-content-around">
                                            <div className="p-2">ควรปรับปรุง</div>
                                            <div className="p-2">เฉยๆ</div>
                                            <div className="p-2">ปานกลาง</div>
                                            <div className="p-2">พอใจ</div>
                                            <div className="p-2">พอใจที่สุด</div>
                                        </div>
                                    </Col>
                                    
                                </Row>
                            </Col>
                        </Row>
                    </Modal.Body>
                    <Modal.Footer style={{ padding: 0 }}>
                        <Button variant="success" style={{ width: '100%', borderRadius: 0 }} onClick={() => this.addRate()}>ส่ง</Button>
                    </Modal.Footer>
                </Modal>

                <Modal show={this.state.showReport} onHide={this.reportClose} size="sm" aria-labelledby="contained-modal-title-vcenter" centered >
                    <Modal.Body>
                        <Row style={{ padding: '1vmax' }}>
                            <Col xs={12} style={{ textAlign: 'center' }}>
                                {/* <label className="borderIconRed" style={{ marginLeft: 0 }}>
                                    <img src={require("../img/script-text.png")} alt="" />
                                </label> */}
                                <img src={require("../img/report.png")} alt="" />
                            </Col>
                            <Col xs={12} style={{ textAlign: 'center', marginTop: 10 }}>แจ้งเนื้อหาไม่เหมาะสม</Col>
                            <Col xs={12} style={{ paddingLeft: '5%', paddingRight: '5%' }}>
                                {this.setIllegals()}
                            </Col>
                            <Col xs={12} style={{ padding: 0, fontSize: 12 }}>
                                <Col xs={12} style={{ padding: '5px 0px' }}>เหตุผลการแจ้ง</Col>
                                <Col xs={12} style={{ padding: 0 }}>
                                    <textarea rows="4" style={{ width: '100%', border: '1px solid #e2e2e2', backgroundColor: '#f9f9f9' }}
                                    value={this.state.illegalText}
                                    onChange={this.illTextChang}
                                    />
                                </Col>
                            </Col>
                        </Row>
                    </Modal.Body>
                    <Modal.Footer style={{ padding: 0 }}>
                        <Button variant="success" style={{ width: '100%', borderRadius: 0 }} onClick={() => this.illegalSubmit()}>ส่ง</Button>
                    </Modal.Footer>
                </Modal>

                <Modal show={this.state.showQrcode} onHide={this.qrcodeClose} size="sm" aria-labelledby="contained-modal-title-vcenter" centered >
                    <Modal.Body>
                        <Row>
                            <Col xs={12} style={{ textAlign: 'center' }}>
                                <img src={this.state.qrCode} alt="" width="100%"/>
                            </Col>
                        </Row>
                    </Modal.Body>
                </Modal>

                <Modal show={this.state.showShare} onHide={this.shareClose} size="lg" aria-labelledby="contained-modal-title-vcenter" centered className="modalShare">
                    <Modal.Body>
                        <Row>
                            <Col>
                                <FacebookShareButton  url={this.state.linkShare} imageURL={this.state.imgShow} style={{margin:'auto',width:50,cursor:'pointer'}}>
                                    <FacebookIcon size={50} round={true}/>
                                </FacebookShareButton>
                            </Col>
                            <Col>
                                <TwitterShareButton  url={this.state.linkShare} imageURL={this.state.imgShow} style={{margin:'auto',width:50,cursor:'pointer'}}>
                                    <TwitterIcon size={50} round={true}/>
                                </TwitterShareButton>
                            </Col>
                            <Col>
                                <WhatsappShareButton  url={this.state.linkShare} imageURL={this.state.imgShow} style={{margin:'auto',width:50,cursor:'pointer'}}>
                                    <WhatsappIcon size={50} round={true}/>
                                </WhatsappShareButton>
                            </Col>
                            <Col>
                                <PinterestShareButton  url={this.state.linkShare} imageURL={this.state.imgShow} media={this.state.imgShow} style={{margin:'auto',width:50,cursor:'pointer'}}>
                                    <PinterestIcon size={50} round={true}/>
                                </PinterestShareButton>
                            </Col>
                            <Col>
                                <LineShareButton  url={this.state.linkShare} imageURL={this.state.imgShow} style={{margin:'auto',width:50,cursor:'pointer'}}>
                                    <LineIcon size={50} round={true}/>
                                </LineShareButton>
                            </Col>
                            <Col>
                                <EmailShareButton  url={this.state.linkShare} imageURL={this.state.imgShow} style={{margin:'auto',width:50,cursor:'pointer'}}>
                                    <EmailIcon size={50} round={true}/>
                                </EmailShareButton>
                            </Col>
                        </Row>
                    </Modal.Body>
                </Modal>

                <Modal show={this.state.showGallery} onHide={this.galleryClose} size="lg" aria-labelledby="contained-modal-title-vcenter" centered dialogClassName='custom-dialog'>
                    <Modal.Body style={{padding:'1vw'}}>
                        <Row style={{margin:0}}>
                            <Col xs={9} style={{padding:0,height:'80vh',overflow:'auto',textAlign:'center'}}>
                                <img src={this.state.imgGallery} alt="" style={{width:'100%'}}
                                onError={({nativeEvent:{error}})=>console.clear()}
                                />
                            </Col>
                            <Col xs={3} style={{padding:5,maxHeight:'80vh',overflow:'auto'}}>
                                {this.setGalleryList()}
                            </Col>
                        </Row>
                    </Modal.Body>
                </Modal>

                <Modal show={this.state.showMedia} onHide={this.mediaClose} size="lg" aria-labelledby="contained-modal-title-vcenter" centered dialogClassName='custom-dialog' className="modalMedia">
                    <iframe style={{width:'100%',height:'75vh'}} src={this.state.media} frameBorder="0" scrolling="no"/>
                </Modal>
            </>
        );
    }

    ctypeName(cId){
        if(cId === 1){return 'book'}
        else if(cId === 2){return 'application'}
        else if(cId === 3){return 'vdo'}
        else if(cId === 4){return 'images'}
        else if(cId === 5){return 'sound'}
        else if(cId === 6){return 'Multimedia'}
        else if(cId === 8){return 'test'}
        else if(cId == 11){return 'template'}
        else {return 'book'}
      }

      pagination(c, m, r=2) {  //c หน้าปัจจุบัน  m จำนวนหน้าทั้งหมด r  ระยะของช่วงปุ่มเลขหน้า
        let current = c,
            last = m,
            delta = r,
            left = current - delta,
            right = current + delta + 1,
            range = [],
            rangeWithDots = [],
            l;
     
        for (let i = 1; i <= last; i++) {
            if (i == 1 || i == last || i >= left && i < right) {
                range.push(i);
            }
        }
     
        for (let i of range) {
            if (l) {
                if (i - l === 2) {
                    rangeWithDots.push(l + 1);
                } else if (i - l !== 1) {
                    rangeWithDots.push('...');
                }
            }
            rangeWithDots.push(i);
            l = i;
        }
     
        return rangeWithDots;
     }
}

export default translate(Detail);