import React from 'react';
import './register.css';
import { Row, Col, Image, Container } from 'react-bootstrap';
import axios from 'axios';
import { translate } from 'react-switch-lang';
import MetaTags from 'react-meta-tags';

class Notify extends React.Component {
  
  constructor(props, context) {
    super(props, context);

    this.state = {
      userToken: "",
      notifies: []
    };
  }

  async componentDidMount (){
    setTimeout(() => {
      window.scrollTo({ top: 0});
    }, 500);
    let items = await JSON.parse(localStorage.getItem('islogin'));
    if (items) {
      
      await this.setState({userToken:items.token});
      
      if (this.props.match.params.id){
        this.getNotifies(this.props.match.params.id);
      } else {
        this.getAllNotifies();
      }
    }
  }

  componentDidUpdate(prevProps){
    if (this.props.match.params.id && this.props.match.params.id !== prevProps.match.params.id) {
      this.getNotifies(this.props.match.params.id);
    }
    if (!this.props.match.params.id && this.props.match.params.id !== prevProps.match.params.id) {
      this.getAllNotifies();
    }

  }

  async componentWillReceiveProps(nextProps){
    if(nextProps.searchWord.trim() !== '' && nextProps.searchWord !== this.props.searchWord){
      await this.props.history.push('/search', nextProps.searchWord);
    }
  }

  getAllNotifies =  () => {
    if (!this.state.userToken) return;
    
    const api = axios.create({
      baseURL: this.props.mainpoint,
      headers: {
        "Content-Type": "application/json",
        "token":this.state.userToken
      },
      timeout: 85000
    });
    api.get(`/api/v1/notifies/page/0/limit/10`).then(
      success => {
        if (process.env.REACT_APP_ENV_MODE === "development") console.log(success);
        const { status, data } = success
        if (status === 201 || status === 200) {
          if (process.env.REACT_APP_ENV_MODE === "development") console.log("Get Notifies Page ", data);
          if (data.result.length > 0) {

            this.setState({
              notifies: data.result
            });
            data.result.forEach(item=>{
              if (item.flgRead == 'n') {
                this.readed(item.notiId);
              }
              
            })
          } else {
            this.setState({
              notifies: []
            });

          }
        }
      },
      error => {
        if (process.env.REACT_APP_ENV_MODE === "development") console.log(error)
      }
    );
  }

 
  
  getNotifies =  (id) => {
    if (!this.state.userToken) return;
    
    const api = axios.create({
      baseURL: this.props.mainpoint,
      headers: {
        "Content-Type": "application/json",
        "token":this.state.userToken
      },
      timeout: 85000
    });
    api.get(`/api/v1/notifies/${id}/details`).then(
      success => {
        if (process.env.REACT_APP_ENV_MODE === "development") console.log(success);
        const { status, data } = success
        if (status === 201 || status === 200) {
          if (process.env.REACT_APP_ENV_MODE === "development") console.log("Get Notifies Page ", data);
          let array = [];
          array.push(data.result);
          
             this.setState({
              notifies: array
            });
        }
      },
      error => {
        if (process.env.REACT_APP_ENV_MODE === "development") console.log(error)
      }
    );
  }

  readed = (id) => {
    const api = axios.create({
      baseURL: this.props.mainpoint,
      headers: {
        "Content-Type": "application/json",
        "token":this.state.userToken
      },
      timeout: 85000
    });
    api.get(`/api/v1/notifies/${id}/details`).then(
      success => {
        const { status, data } = success
        if (status === 201 || status === 200) {
          if (process.env.REACT_APP_ENV_MODE === "development") console.log("Readed", data);
        }
      },
      error => {
        if (process.env.REACT_APP_ENV_MODE === "development") console.log(error)
      }
    );
  }

  setNotifies = () => {
    let notifies = [];
    this.state.notifies.forEach(item => {
      notifies.push(
        <Row key={item.notiType+item.notiId}>
          <Col>
            <Row className="mb-4">
            <Col sm={12}>
            {(item.imgUrl)?
              <Image src={item.imgUrl} className="img-fluid"></Image>
              :
              ''
            }
            </Col>
          </Row>
            <Row>
              <Col>
              <h6><strong>{item.name}</strong></h6>
              <p style={{fontSize:'.9em', color: '#777777', marginBottom: '.7em'}}
               dangerouslySetInnerHTML={{ __html: item.description }}>
              </p>
              <p style={{fontSize:'.9em', color: '#333333', fontWeight: '600'}}>
                {item.createDateStr}
              </p>
              </Col>
            </Row>
          </Col>
        </Row>);
    });
    return notifies;
  }
    
    render() {
      const { t } = this.props;
      return (
        <>
        <MetaTags>
          <title>{'OBEC Content Center'}</title>
        </MetaTags>

        <Row as="div" className="notify-content my-2">
          <Col col={12}>
            <Container>
            {/* <h4 className="mb-2"><strong>{t("header.notification")}</strong></h4>             */}
            {(this.state.notifies.length > 0)? 
              this.setNotifies() : null
            }
            </Container>
          </Col>
        </Row>
        </>
      );
    }
  }

export default translate(Notify);