import React from 'react';
import { Button, Navbar, Form, Nav, NavDropdown, Row, Col, InputGroup, Container } from 'react-bootstrap';
import { FaUserCircle, FaSistrix } from "react-icons/fa";
import axios from 'axios';
import { Link } from 'react-router-dom';
import { Redirect } from 'react-router-dom';
import { LinkContainer } from "react-router-bootstrap";
import Register from './register';
import Login from './login';
import Notice from './notice';
import Policy from './policy';
import ForgotPwd from "./forgotpassword";
import {
  setTranslations,
  setDefaultLanguage,
  setLanguage,
  translate,
} from 'react-switch-lang';
import en from '../locale/en.json';
import th from '../locale/th.json';

setTranslations({ en, th });
let __OBECLANG__ = localStorage.getItem('__OBECISLANG__');
__OBECLANG__ = (__OBECLANG__!==null)? __OBECLANG__:'th';
setDefaultLanguage(__OBECLANG__);

class Header extends React.Component {

  constructor(props){ 
    super(props);
    this.linkSearch = React.createRef();
    this.setLogOut=this.setLogOut.bind(this);
    this.state={
      registerShow: false,
      loginShow: false,
      policyShow: false,
      isLogin:false,
      forgotPwdShow: false,
      userName: '',
      userImage: '',
      userRole: null,
      rdNum: 0,
      searchWord:'',
      lang: __OBECLANG__,
      redirect: false
    };
    //this.setLoginState=this.setLoginState.bind(this);
  }

  async componentDidMount(){
    let items = JSON.parse(localStorage.getItem('islogin'));
    if(items) await this.checkSessionExpire();
    if (process.env.REACT_APP_ENV_MODE === "development") console.log(items);

    items = JSON.parse(localStorage.getItem('islogin'));
    if(items){
      let dateNow = new Date();
      let loginDate = new Date(items.loginDate);
      let diffDay = dateNow - loginDate;

      if(diffDay > 86400000){
        localStorage.removeItem('islogin');
      }else{
        this.setState({ isLogin: true, userName: items.firstname+' '+items.lastname, userRole: items.userRole});
        if (items.imgUrl) {
          this.setState({ userImage: items.imgUrl });
        }
      }

    }else{
      this.setState({ isLogin: false, userName: '', userRole: null});
    }
    
  }

  componentDidUpdate(prevProps, prevState, snapshot){
    if (this.props.isLogOut && this.state.isLogin) {
      this.setState({ isLogin: false, userName: '', userRole: null});
    }
    if (this.props.changeImg !== prevProps.changeImg) {
      //if (process.env.REACT_APP_ENV_MODE === "development") console.log("Props ChangeImg",prevProps.changeImg,this.props.changeImg);
      this.setState({ rdNum: this.props.changeImg.rdNum, userImage: this.props.changeImg.imgUrl});

    }
    if (this.props.userProfile !== prevProps.userProfile) {
      this.setState({ userName: this.props.userProfile.firstname+' '+this.props.userProfile.lastname});
    }
    if (this.props.toLogin !== prevProps.toLogin && !this.state.loginShow) {
      this.setState({ loginShow: true});
    }
  }

  checkSessionExpire =  () => {
    let items = JSON.parse(localStorage.getItem('islogin'));

    const api = axios.create({
      baseURL: this.props.mainpoint,
      headers: {
        "Content-Type": "application/json",
        "token": items.token
      },
      timeout: 0
    });
    api.get(`/api/v1/shelves`).then(
      success => {
        // const { status, data } = success
        // console.log(success)
      },
      error => {
        // console.log(error);
        // const { status, data } = error

        this.setLogOut();
        this.setState({redirect: true });
        
        if (process.env.REACT_APP_ENV_MODE === "development") console.log(error)
      }
    ).catch(
      error => {
        console.log(error);
      }
    );
  }

  setLogOut(){
    localStorage.removeItem('islogin');
    this.props.logout(true);
  }

  setLoginState(value){
    this.setState({ 
      registerShow: value,
      loginShow: false
    });
  }

  setIsLogin(value){
    let dateLogin = new Date();
    const dataUser = value;
    dataUser.loginDate = dateLogin;
    this.setState({ 
      isLogin: true,
      loginShow: false,
      userName: value.firstname+' '+value.lastname
    });
    localStorage.setItem('islogin', JSON.stringify(dataUser));
  }

  setPolicyState(value){
    this.setState({ 
      policyShow: value
    });
  }

  setForgotPwd(value){
    this.setState({ 
      forgotPwdShow: value,
      loginShow: false
    });
  }

  registerClose = () => this.setState({ registerShow: false });

  loginClose = () => this.setState({ loginShow: false });

  policyClose = () => this.setState({ policyShow: false });

  fgPwdClose = () => this.setState({ forgotPwdShow: false });

  formPreventDefault = (e) => {  
    e.preventDefault();
    this.props.search(this.state.searchWord);
  }

  handleSetLanguage = (key) => () => {

    if (this.state.lang == 'en') {
      setLanguage('th');
      this.setState({lang:'th'});
      localStorage.setItem('__OBECISLANG__', 'th');
    } else {
      setLanguage('en');
      this.setState({lang:'en'});
      localStorage.setItem('__OBECISLANG__', 'en');
    }
  };

  renderRedirect = () => {
    if (this.state.redirect) {
      return <Redirect to='/' />
    }
  }

  render() {
    const { t } = this.props;
    return (
      <>
      {this.renderRedirect()}
      
      <Row className="border-bottom shadow-sm">
        <Col>
        <Container>
          <Row>
            <Col>
              <Navbar expand="lg" className="p-0">
                <Navbar.Brand>
                <Link to='/'>
                  <img src={require("../img/logo.png")} alt="" className="img-fluid" style={{}} />
                  </Link>
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                  <Nav className="">
                  <LinkContainer to="/"><Nav.Link>{t('header.main')}</Nav.Link></LinkContainer>
                    <NavDropdown title={t('header.treasury')} id="contentList">
                      <LinkContainer to="/store/type/1"><NavDropdown.Item>{t('tag.book')}</NavDropdown.Item></LinkContainer>
                      <LinkContainer to="/store/type/3"><NavDropdown.Item>{t('tag.video')}</NavDropdown.Item></LinkContainer>
                      <LinkContainer to="/store/type/4"><NavDropdown.Item>{t('tag.photo')}</NavDropdown.Item></LinkContainer>
                      <LinkContainer to="/store/type/5"><NavDropdown.Item>{t('tag.sound')}</NavDropdown.Item></LinkContainer>
                      <LinkContainer to="/store/type/2"><NavDropdown.Item>{t('tag.application')}</NavDropdown.Item></LinkContainer>
                      <LinkContainer to="/store/type/8"><NavDropdown.Item>{t('tag.exam')}</NavDropdown.Item></LinkContainer>
                      <LinkContainer to="/store/type/11"><NavDropdown.Item>{t('tag.template')}</NavDropdown.Item></LinkContainer>
                      <LinkContainer to="/store/type/6"><NavDropdown.Item>{t('tag.multimedia')}</NavDropdown.Item></LinkContainer>
                    </NavDropdown>
                    {this.state.isLogin  && <>
                    <LinkContainer to="/bookshelf"><Nav.Link >{t('header.collection')}</Nav.Link></LinkContainer>
                    </>}
                    <a className='nav-link' href='https://info.contentcenter.obec.go.th' target='_blank'>{t('header.about')}</a> 
                    <LinkContainer to="/search"><Nav.Link className="d-md-block d-lg-none d-xl-none">{t('header.search')}</Nav.Link></LinkContainer>
                    <label className="mb-0 d-md-block d-lg-none d-xl-none">
                    {(this.state.isLogin ) && <>
                      <Notice {...this.props} islogin={this.state.isLogin} iconLink={t('header.notification')}></Notice>
                      </>
                    }
                    </label>
                    {!this.state.isLogin && <>
                      <Nav.Link href="#" onClick={() => this.setState({ loginShow: true })} style={{ color: '#42dfaf' }} className="d-md-block d-lg-none d-xl-none">{t('header.sign_in')}</Nav.Link>
                      <Nav.Link href="#" onClick={() => this.setState({ registerShow: true })} style={{ color: '#fec35c' }} className="d-md-block d-lg-none d-xl-none">{t('header.register')}</Nav.Link>
                      </>
                      }
                      {(this.state.isLogin )? <>
                        <LinkContainer to='/profile'><Nav.Link className="d-md-block d-lg-none d-xl-none">{this.state.userName}</Nav.Link></LinkContainer>
                      </>
                      :
                        <span className="d-md-block d-lg-none d-xl-none" style={{color:'rgba(0,0,0,.5)'}}>{this.state.userName}</span>
                    }
                      <div className="d-md-block d-lg-none d-xl-none mb-3">
                        {(this.state.lang == 'en')? 
                          <a value="en" style={{cursor:'pointer'}} onClick={this.handleSetLanguage('')}><img src={require("../img/th.png")} style={{width:'23px',height:'17px'}}/></a>
                          :
                          <a value="th" style={{cursor:'pointer'}} onClick={this.handleSetLanguage('')}><img src={require("../img/en.png")} style={{width:'23px',height:'17px'}}/></a>
                        }
                      </div>
                  </Nav>
                  <Form inline className="d-none d-lg-block d-xl-block " onSubmit={this.formPreventDefault}>
                    <Form.Group>
                      <InputGroup className="mr-sm-2" style={{ width:'150px'}}>
                        <Form.Control className="borderRadius search-btn" placeholder={t('header.search')} onChange={e => this.setState({ searchWord: e.target.value.trim() })}/>
                        <div className="input-group-append" >
                            <Link to={{pathname:'/search', state: {searchWord:this.state.searchWord}}} className="input-group-text borderRadius"
                              style={{backgroundColor: 'whitesmoke', border: 'none' }} ref={ref=>this.linkSearch=ref}>
                              <FaSistrix style={{color: '#42dfaf', fontSize: '1.25rem'}} />
                            </Link>
                        </div>
                      </InputGroup>
                      {(this.state.isLogin) && <>
                        <Notice {...this.props} islogin={this.state.isLogin} iconLink={<img src={require("../img/noti.png")}/>}></Notice>
                      </>
                      }
                      {!this.state.isLogin && <>
                      <Nav.Link href="#" onClick={() => this.setState({ loginShow: true })} style={{ color: '#42dfaf' }}>{t('header.sign_in')}</Nav.Link>
                      <Button className="buttonFilter" onClick={() => this.setState({ registerShow: true })}>{t('header.register')}</Button>
                      </>
                      }
                      {(this.state.isLogin )&& <>
                        <LinkContainer to='/profile' style={{display:"flex",flexDirection:"row",justifyContent: "space-between"}}>
                          <Nav.Link>
                            <div style={{paddingRight:".5rem"}}>
                                {this.state.userImage? 
                                  <img src={this.state.userImage} alt="" className="rounded-circle" style={{width:38,height:38}}/>
                                : 
                                  <img src={require("../img/avatar/role_99.png")} alt="" className="rounded-circle" style={{width:38,height:38}}/>
                                }
                            </div>
                            <div style={{display:"flex",alignItems:"flex-end"}}>
                                <div style={{height:30,whiteSpace: 'nowrap',textOverflow: 'ellipsis',overflow: 'hidden'}}>{t('header.hello')} {this.state.userName}</div>
                            </div>
                          </Nav.Link>
                        </LinkContainer>
                      
                      </>}
                      <div className="ml-2">
                      {(this.state.lang == 'en')? 
                        <a value="en" style={{cursor:'pointer'}} onClick={this.handleSetLanguage('')}><img src={require("../img/th.png")} style={{width:'23px',height:'17px'}}/></a>
                        :
                        <a value="th" style={{cursor:'pointer'}} onClick={this.handleSetLanguage('')}><img src={require("../img/en.png")} style={{width:'23px',height:'17px'}}/></a>
                      }
                      </div>
                    </Form.Group>
                  </Form>
                </Navbar.Collapse>
              </Navbar>
              <Register {...this.props} show={this.state.registerShow} onHide={this.registerClose} topolicy={(value) => this.setPolicyState(value)}></Register>
              <Login {...this.props} show={this.state.loginShow} onHide={this.loginClose}
                toregis={(value) => this.setLoginState(value)} 
                islogin={(value) => this.setIsLogin(value)}
                toforgotpwd={(value) => this.setForgotPwd(value)}>
              </Login>
              <Policy show={this.state.policyShow} onHide={this.policyClose} toregis={(value) => this.setLoginState(value)} />
              <ForgotPwd {...this.props} show={this.state.forgotPwdShow} onHide={this.fgPwdClose}></ForgotPwd>
            </Col>
          </Row>
        </Container>
        </Col>
      </Row>
      </>
    );
  }
}

export default translate(Header);