import React from 'react';
import './register.css';
import { Button, Row, Col, Modal } from 'react-bootstrap';
import axios from 'axios';

class Policy extends React.Component {

  constructor(props) {
    super(props);

  }

  componentDidMount = () => {
  }

  goback = () => {
    this.props.onHide();
  }

  // render() {
  //   return(
  //     <Modal
  //       {...this.props}
  //       size="lg"
  //       id="register-form"
  //       aria-labelledby="contained-modal-title-vcenter-p"
  //       centered
  //       onHide={this.goback}>
  //       <Modal.Header className="pt-4 pb-2 px-4" style={{borderBottom: 'none'}} >
  //         <Modal.Title id="contained-modal-title-vcenter-p" style={{paddingBottom: '0.3em', borderBottom: '3px solid #0eb06a'}}>
  //           <strong>เงื่อนไขและนโยบายความเป็นส่วนตัว</strong>
  //         </Modal.Title>
  //       </Modal.Header>
  //       <Modal.Body className="pl-4 pr-4">
  //       <object style={{width:'100%', minHeight: '60vh'}} data="https://contentcenter.obec.go.th/policy/"></object>

  //       <Row>
  //         <Col className="text-center p-2">
  //         <Button className="borderRadius bg-light border-white mr-3" onClick={this.goback.bind(this)}>
  //           <span className="text-success">ปิดหน้านี้</span>
  //         </Button>
  //         </Col>
  //       </Row>

  //       </Modal.Body>
  //       <Modal.Footer className="justify-content-center" style={{borderTop: 'none'}} >

  //       </Modal.Footer>
  //     </Modal>
  //   );
  // }

  render() {
    return (
      <Modal
        {...this.props}
        size="lg"
        id="register-form"
        aria-labelledby="contained-modal-title-vcenter-p"
        centered
        onHide={this.goback}>
        <Modal.Header className="p-4" style={{ borderBottom: 'none' }} >
          <Modal.Title id="contained-modal-title-vcenter-p" style={{ paddingBottom: '0.3em', borderBottom: '3px solid #0eb06a' }}>
            <strong>เงื่อนไขและนโยบายความเป็นส่วนตัว</strong>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="pl-4 pr-4">
          <Row>
            <Col md={12} className="px-3">
              <h6><strong>ข้อกําหนดและเงื่อนไขการสมัครสมาชิก Application OBEC Content Center</strong></h6>
              <h6><strong>โปรดอ่านและทำความเข้าใจข้อตกลงข้างล่างนี้ โดยละเอียดก่อนสมัครเป็นสมาชิก เพื่อรักษาสิทธิประโยชน์ในการใช้บริการของท่าน</strong></h6>
              <br />
              <h6><strong><u>บัญชีและการสมัครสมาชิก</u></strong></h6>
              <p>ผู้สมัครสมาชิกตกลงยินยอมผูกพันตามข้อกําหนดและเงื่อนไขการใช้บริการร้องเรียนออนไลน์ของ <strong>OBEC Content Center</strong>  ดังต่อไปนี้</p>
              <p>(1) การสมัครสมาชิก ไม่ต้องเสียค่าใช้จ่ายใดๆ ทั้งสิ้น </p>
              <p>(2) สมาชิกต้องกรอกข้อมูลให้ครบถ้วน และตรงตามเป็นจริง เพื่อสิทธิประโยชน์ของท่านเอง หากตรวจสอบพบว่าข้อมูลของท่านที่ให้มาเป็นเท็จ ทางระบบจะยกเลิกการเป็นสมาชิกของท่านทันที โดยไม่ต้องแจ้งให้ทราบล่วงหน้า</p>
              <p>(3) ผู้ใดแอบอ้าง หรือกระทําการใด ๆ อันเป็นการละเมิดสิทธิส่วนบุคคล โดยใช้ข้อมูลของผู้อื่นมาแอบอ้าง สมัครสมาชิก เพื่อให้ได้ซึ่งสิทธิการเป็นสมาชิก ถือเป็นความผิด ต้องรับโทษตามที่กฎหมายกําหนดไว้</p>
              <p>(4) ผู้สมัครจะต้องรักษารหัสผ่าน หรือชื่อเข้าใช้งานในระบบสมาชิกเป็นความลับ และหากมีผู้อื่นสามารถ เข้าใช้จากทางชื่อของผู้สมัคร ได้ทางระบบ <strong>OBEC Content Center</strong> จะไม่รับผิดชอบใด ๆ</p>
              <p>(5) หากสมาชิกกระทำการใดๆ หรือมีการอ้างอิงที่ก่อให้เกิดความเสียหาย ต่อ <strong>OBEC Content Center</strong> ท่านจะถูกยกเลิกเป็นสมาชิกทันที และจะถูกดำเนินการตามกฏหมายต่อไป </p>
              <br />
              <h6><strong><u>ข้อห้ามในการใช้งาน</u></strong></h6>
              <p>ห้ามมิให้ผู้ใช้งาน ใช้ <strong>Application OBEC Content Center</strong> หรือเนื้อหาของ <strong>OBEC Content Center</strong> </p>
              <p>(1) เพื่อวัตถุประสงค์ที่มิชอบด้วยกฎหมาย </p>
              <p>(2) เพื่อชักชวนให้ผู้อื่นเข้าร่วมในการกระทำใด ๆ ที่มิชอบด้วยกฎหมาย </p>
              <p>(3) เพื่อละเมิดกฎระเบียบ, กฎหมาย พระราชบัญญัติ หรือข้อบังคับใด ๆ ทั้งในระดับรัฐ, จังหวัด, ชาติหรือระดับนานาชาติ </p>
              <p>(4) เพื่อละเมิดทรัพย์สินทางปัญญาของของผู้อื่น </p>
              <p>(5) เพื่อก่อความรำคาญ, กล่าวร้าย, ดูถูก, ทำร้าย, ทำลายชื่อเสียง, ใส่ร้าย, ดูหมิ่น, ข่มขู่ หรือการเลือกปฏิบัติด้วยการแบ่งแยกเพศ, รสนิยมทางเพศ, ศาสนา, เชื้อชาติ, อายุ, ชาติกำเนิด หรือความพิการ</p>
              <p>(6) เพื่อนำเสนอข้อมูลที่ผิด หรือทำให้เข้าใจผิด </p>
              <p>(7) เพื่อการส่งต่อไวรัส หรือโค้ดอันตรายและไม่พึงประสงค์ใด ๆ ที่ก่อให้เกิดผลกระทบต่อการทำงาน หรือการปฏิบัติการของการบริการ หรือแอปพลิเคชันที่เกี่ยวข้อง, แอปพลิเคชันมือถืออื่นๆ หรืออินเทอร์เน็ต</p>
              <p>(8) เพื่อรวบรวม หรือติดตามข้อมูลส่วนบุคคลของผู้อื่น</p>
              <p>(9) เพื่อวัตถุประสงค์ในทางลามกอนาจาร หรือผิดศีลธรรมใด ๆ หรือ </p>
              <p>(10) เพื่อก่อให้เกิดความเสียหายด้านความปลอดภัยของบริการ หรือแอปพลิเคชันที่เกี่ยวข้องอื่น ๆ หรืออินเทอร์เน็ต โดย <strong>OBEC Content Center</strong> ขอสงวนสิทธิ์ในการยุติการใช้บริการของผู้ใช้</p>
            </Col>
          </Row>
          <Row>
            <Col className="text-center p-2">
              <Button className="borderRadius bg-light border-white mr-3" onClick={this.goback.bind(this)}>
                <span className="text-success">ปิดหน้านี้</span>
              </Button>
            </Col>
          </Row>

        </Modal.Body>
        <Modal.Footer className="justify-content-center" style={{ borderTop: 'none' }} >

        </Modal.Footer>
      </Modal>
    );
  }
}

export default Policy;
