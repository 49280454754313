import React from 'react';
import {Button,Container,Carousel,Row,Col} from 'react-bootstrap';
// import { Player } from 'video-react';
import Tag from '../../component/tag';
import Menu from '../../component/menu';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { translate } from 'react-switch-lang';
import MetaTags from 'react-meta-tags';

class Main extends React.Component {
  constructor(props, context) {
    super(props, context);
    // console.log(props);
    this.state = {
      isLogin:false,
      user:{},
      banner:[],
      ebookRec:[],
      otherRec:[],
      vdoRec:[],
      recent:[],
      hashtags:[],
    };
  }

  async componentDidMount(){
    setTimeout(() => {
      window.scrollTo({ top: 0});
    }, 500);
    var islogin = JSON.parse(await localStorage.getItem('islogin'));
    // console.log(islogin);
    var token='';
    if(islogin){
      this.setState({ isLogin: true,user:islogin});
      token = islogin.token
    }else{
      this.setState({ isLogin: false,user:{}});
    }
    this.getRecent(token);
    this.getBanner();
    this.getDashboard();
  }

  async componentWillReceiveProps(nextProps){
    if(nextProps.searchWord){
      await this.props.history.push('/search', nextProps.searchWord);
    }
  }

  getBanner(){
      axios.get(this.props.mainpoint+'/api/v1/banners').then(
      success => {
        const { status, data } = success

        if (status === 201 || status === 200) {
          this.setState({banner:data.result})
        } else {
          console.error('Error',status)
        }
      },
      error => {
        console.error('Error',error)
      }
    );
  }

  setBanner() {
    const items =[],itemsRight =[];
    // console.log(this.state.banner);
    if(this.state.banner.length > 0){
      for (var i=0;i < this.state.banner.length;i++ ){
        if(this.state.banner[i].flgMaster === 'y'){
          items.push(
            <Carousel.Item key={this.state.banner[i].bannerId} style={{height:'100%',overflow:'hidden'}}>
              <a href={this.state.banner[i].link} target="_blank">
              < img src={this.state.banner[i].imgUrl} alt="" style={{maxHeight:'100%',minHeight:'100%'}}/>
              </a>
            </Carousel.Item>
          )
        }else{
          itemsRight.push(
            <Col lg={12} md={6} xs={12} className="d-none d-sm-block" style={{padding:0,maxHeight:218}} key={this.state.banner[i].bannerId}>
              <a href={this.state.banner[i].link} target="_blank">
                <img src={this.state.banner[i].imgUrl} alt="" width="100%" />
              </a>
            </Col>
          )
        }
      }
    }else{
      items.push(
        <Carousel.Item key={'banner1'} style={{height:'100%',overflow:'hidden'}}>
          <img src={require("../../img/img1.png")} alt="" style={{maxHeight:'100%',minHeight:'100%'}}/>
        </Carousel.Item>
      )
    }
    
    return (
      <Container>
        <Row noGutters className="my-1">
          <Col lg={8} md={12}>
            <Carousel>
              {items}
            </Carousel>
          </Col>
          <Col lg={4} md={12}>
            <Row className="m-0">
              {itemsRight}
            </Row>
          </Col>
        </Row>
      </Container>
    )
  }

  getDashboard(){
      axios.get(this.props.mainpoint+'/api/v1/contents/dashboard').then(
      success => {
        const { status, data } = success
        // console.log(status);
        if (status === 201 || status === 200) {
          // console.log(data);
          this.setState({ebookRec:data.result.ebookRecommend, otherRec:data.result.otherRecommend, vdoRec:data.result.vdoRecommend})
        } else {
          console.error('Error',status)
        }
      },
      error => {
        console.error('Error',error)
      }
    );
  }

  setEbookRec() {
    if (this.state.ebookRec.length > 0) {
      const items = [];
      const itemsXs = [];
      var numb = 5;
      if(this.state.ebookRec.length < numb){
        numb = this.state.ebookRec.length;
      }
      for (var i = 0; i < numb; i++) {
        if(i > 0){
          items.push(<Col key={this.state.ebookRec[i].cId} xs={6} style={{ marginBottom: 20 }}>
            <Link to={"/detail/"+this.ctypeName(this.state.ebookRec[i].ctypeId)+'/'+this.state.ebookRec[i].cId}>
            <Row style={{ width: '100%', height: '100%', margin: 0, backgroundColor: '#ffffff', padding: 10 }}>
              <Col xs={5} style={{ overflow: 'hidden', position: 'relative', padding: 0 }}>
                <div className="ribbon2">{i+1}</div>
                {/* <Link to={"/detail/"+this.ctypeName(this.state.ebookRec[i].ctypeId)+'/'+this.state.ebookRec[i].cId}> */}
                  <img src={this.state.ebookRec[i].imgUrl} alt="" width="100%" />
                {/* </Link> */}
              </Col>
              <Col xs={7}>
                <div><Tag ctype ={this.state.ebookRec[i].ctypeId}/></div>
                <div className="text-dark">
                  {this.state.ebookRec[i].name}
                </div>
              </Col>
            </Row>
            </Link>
          </Col>)
        }
        itemsXs.push(<Col key={this.state.ebookRec[i].cId} xs={12} style={{ marginBottom: 20 }}>
          <Link to={"/detail/"+this.ctypeName(this.state.ebookRec[i].ctypeId)+'/'+this.state.ebookRec[i].cId}>
          <Row style={{ width: '100%', height: '100%', margin: 0, backgroundColor: '#ffffff', padding: 10 }}>
            <Col xs={4} style={{ overflow: 'hidden', position: 'relative', padding: 0 }}>
              <div className="ribbon2">{i+1}</div>
              {/* <Link to={"/detail/"+this.ctypeName(this.state.ebookRec[i].ctypeId)+'/'+this.state.ebookRec[i].cId}> */}
                <img src={this.state.ebookRec[i].imgUrl} alt="" width="100%" />
              {/* </Link> */}
            </Col>
            <Col>
              <div><Tag ctype ={this.state.ebookRec[i].ctypeId}/></div>
              <div  className="text-dark">{this.state.ebookRec[i].name}</div>
            </Col>
          </Row>
          </Link>
        </Col>)
      }
      return (
        <>
          <Col xs={12} style={{ marginTop: 10, padding: 0 }} className="hiddenXs">
            <Row style={{ width: '100%', margin: 0 }}>
              <Col xs={4} style={{ padding: 0 }}>
              <Link to={"/detail/"+this.ctypeName(this.state.ebookRec[0].ctypeId)+'/'+this.state.ebookRec[0].cId}>
                <Row style={{ margin: 0, height: '100%' }}>
                  <Col xs={12} style={{ marginBottom: 20 }}>
                    <Row style={{ width: '100%', height: '100%', margin: 0, backgroundColor: '#ffffff', padding: 10 }}>
                      <Col xs={12} style={{ overflow: 'hidden', position: 'relative', paddingLeft: '15%', paddingRight: '15%' }}>
                        <div className="ribbon2" style={{ left: '15%' }}>1</div>
                        {/* <Link to={"/detail/"+this.ctypeName(this.state.ebookRec[0].ctypeId)+'/'+this.state.ebookRec[0].cId}> */}
                          <img src={this.state.ebookRec[0].imgUrl} alt="" width="100%" />
                        {/* </Link> */}
                      </Col>
                      <Col>
                        <div><Tag ctype ={this.state.ebookRec[0].ctypeId}/></div>
                        <div>{this.state.ebookRec[0].name}</div>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                </Link>
              </Col>
              <Col xs={8} style={{ padding: 0 }}>
                <Row style={{ margin: 0, height: '100%' }}>
                  {items}
                </Row>
              </Col>
            </Row>
          </Col>
          <Col xs={12} style={{ marginTop: 10, padding: 0 }} className="showXs">
            <Row style={{ width: '100%', margin: 0 }}>
              {itemsXs}
            </Row>
          </Col>
        </>
      )
    }
  }

  setVdoRec() {
    if (this.state.vdoRec.length > 0) {
      const items = [];
      const itemsXs = [];
      var numb = 5;
      if(this.state.vdoRec.length < numb){
        numb = this.state.vdoRec.length;
      }
      for (var i = 0; i < numb; i++) {
        if(i > 0){
          items.push(<Col key={this.state.vdoRec[i].cId} xs={6} style={{marginBottom: 20 }}>
            <Link to={"/detail/"+this.ctypeName(this.state.vdoRec[i].ctypeId)+'/'+this.state.vdoRec[i].cId}>
            <Row style={{ width: '100%',height:'100%', margin: 0, backgroundColor: '#ffffff', padding: 10 }}>
              <Col xs={12} style={{ overflow: 'hidden', position: 'relative',padding:0}}>
                <div className="ribbon2">{i+1}</div>
                {/* <Link to={"/detail/"+this.ctypeName(this.state.vdoRec[i].ctypeId)+'/'+this.state.vdoRec[i].cId}> */}
                  <div className="playVdo"><img src={require("../../img/video_ic.png")} alt=""/></div>
                  <img src={this.state.vdoRec[i].imgUrl} alt="" width="100%" />
                {/* </Link> */}
              </Col>
              <Col style={{marginTop:10}}>
                <div><Tag ctype ={this.state.vdoRec[i].ctypeId}/></div>
                <div style={{height:20,overflow:'hidden',whiteSpace:'nowrap',textOverflow:'ellipsis'}}>{this.state.vdoRec[i].name}</div>
              </Col>
            </Row>
            </Link>
          </Col>)
        }
        itemsXs.push(<Col key={this.state.vdoRec[i].cId} xs={12} style={{marginBottom: 20 }}>
          <Link to={"/detail/"+this.ctypeName(this.state.vdoRec[i].ctypeId)+'/'+this.state.vdoRec[i].cId}>
          <Row style={{ width: '100%',height:'100%', margin: 0, backgroundColor: '#ffffff', padding: 10 }}>
            <Col xs={12} style={{ overflow: 'hidden', position: 'relative',padding:0}}>
              <div className="ribbon2">{i+1}</div>
              {/* <Link to={"/detail/"+this.ctypeName(this.state.vdoRec[i].ctypeId)+'/'+this.state.vdoRec[i].cId}> */}
                <div className="playVdo"><img src={require("../../img/video_ic.png")} alt=""/></div>
                <img src={this.state.vdoRec[i].imgUrl} alt="" width="100%" />
              {/* </Link> */}
            </Col>
            <Col xs={12} style={{marginTop:10}}>
              <div><Tag ctype ={this.state.vdoRec[i].ctypeId}/></div>
              <div>{this.state.vdoRec[i].name}</div>
            </Col>
          </Row>
          </Link>
        </Col>)
      }
      return (
        <>
          <Col xs={12} style={{ marginTop: 10, padding: 0 }} className="hiddenXs">
            <Row style={{ width: '100%', margin: 0 }}>
              <Col xs={6} style={{ padding: 0 }}>
              <Link to={"/detail/"+this.ctypeName(this.state.vdoRec[0].ctypeId)+'/'+this.state.vdoRec[0].cId}>
                <Row style={{ margin: 0, height: '100%' }}>
                  <Col xs={12} style={{ marginBottom: 20 }}>
                    <Row style={{ width: '100%', height: '100%', margin: 0, backgroundColor: '#ffffff', padding: 10 }}>
                      <Col xs={12} style={{ overflow: 'hidden', position: 'relative', padding: 0}}>
                        <div className="ribbon2">1</div>
                        {/* <Link to={"/detail/"+this.ctypeName(this.state.vdoRec[0].ctypeId)+'/'+this.state.vdoRec[0].cId}> */}
                          <div className="playVdo"><img src={require("../../img/video_ic.png")} alt="" /></div>
                          <img src={this.state.vdoRec[0].imgUrl} alt="" width="100%" />
                        {/* </Link> */}
                      </Col>
                      <Col style={{marginTop:10}}>
                        <div><Tag ctype ={this.state.vdoRec[0].ctypeId}/></div>
                        <div>{this.state.vdoRec[0].name}</div>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                </Link>
              </Col>
              <Col xs={6} style={{ padding: 0 }}>
                <Row style={{ margin: 0, height: '100%' }}>
                  {items}
                </Row>
              </Col>
            </Row>
          </Col>
          <Col xs={12} style={{ marginTop: 10, padding: 0 }} className="showXs">
            <Row style={{ width: '100%', margin: 0 }}>
              {itemsXs}
            </Row>
          </Col>
        </>
      )
    }
  }

  setOtherRec() {
    if (this.state.otherRec.length > 0) {
      // console.log(this.state.otherRec);
      const items = [];
      const itemsXs = [];
      var numb = 5;
      if(this.state.otherRec.length < numb){
        numb = this.state.otherRec.length;
      }
      for (var i = 0; i < numb; i++) {
        if(i > 0){
          items.push(<Col key={this.state.otherRec[i].cId+Math.random()} xs={6} style={{marginBottom: 20 }}>
            <Link to={"/detail/"+this.ctypeName(this.state.otherRec[i].ctypeId)+'/'+this.state.otherRec[i].cId}>
            <Row style={{ width: '100%',height:'100%', margin: 0, backgroundColor: '#ffffff', padding: 10 }}>
              <Col xs={5} style={{ overflow: 'hidden', position: 'relative',padding:0}}>
                  <img src={this.state.otherRec[i].imgUrl} alt="" width="100%" />
              </Col>
              <Col xs={7}>
                <div><Tag ctype ={this.state.otherRec[i].ctypeId}/></div>
                <div>{this.state.otherRec[i].name}</div>
              </Col>
            </Row>
            </Link>
          </Col>)
        }
        itemsXs.push(<Col key={this.state.otherRec[i].cId+Math.random()} xs={12} style={{marginBottom: 20 }}>
          <Link to={"/detail/"+this.ctypeName(this.state.otherRec[i].ctypeId)+'/'+this.state.otherRec[i].cId}>
          <Row style={{ width: '100%',height:'100%', margin: 0, backgroundColor: '#ffffff', padding: 10 }}>
            <Col xs={4} style={{ overflow: 'hidden', position: 'relative',padding:0}}>
                <img src={this.state.otherRec[i].imgUrl} alt="" width="100%" />
            </Col>
            <Col>
              <div><Tag ctype={this.state.otherRec[i].ctypeId}/></div>
              <div>{this.state.otherRec[i].name}</div>
            </Col>
          </Row>
          </Link>
        </Col> )
      }
      return (
        <>
          <Col xs={12} style={{ marginTop: 10, padding: 0 }} className="hiddenXs">
            <Row style={{ width: '100%', margin: 0 }}>
              <Col xs={4} style={{ padding: 0 }}>
              <Link to={"/detail/"+this.ctypeName(this.state.otherRec[0].ctypeId)+'/'+this.state.otherRec[0].cId}>
                <Row style={{ margin: 0,height:'100%' }}>
                  <Col xs={12} style={{ marginBottom: 20 }}>
                    <Row style={{ width: '100%',height:'100%', margin: 0, backgroundColor: '#ffffff', padding: 10 }}>
                      <Col xs={12} style={{ overflow: 'hidden', position: 'relative',paddingLeft:'15%',paddingRight:'15%'}}>
                          <img src={this.state.otherRec[0].imgUrl} alt="" width="100%" />
                      </Col>
                      <Col>
                        <div>
                          <Tag ctype ={this.state.otherRec[0].ctypeId}/>
                        </div>
                        <div>{this.state.otherRec[0].name}</div>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                </Link>
              </Col>
              <Col xs={8} style={{ padding: 0 }}>
                <Row style={{ margin: 0,height:'100%' }}>
                  {items}
                </Row>
              </Col>
            </Row>
          </Col>
          <Col xs={12} style={{ marginTop: 10, padding: 0 }} className="showXs">
            <Row style={{ width: '100%', margin: 0 }}>
              {itemsXs}
            </Row>
          </Col>
        </>
      )
    }
  }

  getRecent(token){
      console.log(token);
      var header = {};
      if(token!==''){
          header = {
              "Content-Type": "application/json"
            }
      }else{
          header = {
              "Content-Type": "application/json",
              "token":token
            }
      }
      axios.get(this.props.mainpoint+'/api/v1/contents/dashboard/recent',{
        headers: header
      }).then(
        success => {
          const { status, data } = success
          if (status === 201 || status === 200) {
            // console.log(data);
            this.setState({recent:data.result.recents, hashtags:data.result.hashtags})
          } else {
            console.error('Error',status)
          }
        },
        error => {
          console.error('Error',error)
        }
      );
  }

  setHashtags(){
    const { t } = this.props;
    if (this.state.hashtags) {
    if (this.state.hashtags.length > 0) {
      const items = [];
      var numb = 5;
      if(this.state.hashtags.length < numb){
        numb = this.state.hashtags.length;
      }
      for(var i = 0; i < numb; i++) {
        items.push(<Col lg={2} md={4} xs={6} key={this.state.hashtags[i].htagId}>
          <Link 
          // to={"/store/"+this.state.hashtags[i].name}
            to={{
              pathname: '/store/'+this.state.hashtags[i].htagId,
              hashtag: this.state.hashtags[i].name,
            }}
          >
        <div className="hitList">
            <img src={this.state.hashtags[i].imgUrl} height='100%' alt="" style={{minWidth:'100%'}}/>
            <Row className="hitListText">
              <Col xs={12} style={{fontWeight:'bold',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis'}}>{this.state.hashtags[i].name}</Col>
              <Col xs={12}>{this.state.hashtags[i].cntContentStr} {t("main.lists")}</Col>
            </Row>
        </div>
        </Link>
      </Col>)
      }
      return (
        <>
        <Container>
        <Row className="my-4">
          <Col>
            <Row>
              <Col lg={2} md={4} xs={6}>
                <div className="hitList" style={{ backgroundColor: '#fec35c' }}>
                  <Row style={{ marginTop: 30 }}>
                    <Col style={{ alignItems: 'center' }}>
                      <div className="borderIcon">
                        <img src={require("../../img/seal.png")} alt="" />
                      </div>
                    </Col>
                  </Row>
                  <Row><Col style={{ alignItems: 'center', color: '#ffffff', fontSize: 18, fontWeight: 'bold' }}>{t("main.yourinteresting")}</Col></Row>
                </div>
              </Col>
              {items}
            </Row>
          </Col>
        </Row>
        </Container>
        </>
      )
    }
    }
  }

  setRecent() {
    const { t } = this.props;
    if(this.state.recent) {
    if(this.state.recent.length > 0) {
      const items = [];
      var numb = 6;
      if(this.state.recent.length < numb){
        numb = this.state.recent.length;
      }
      for(var i = 0; i < numb; i++) {
        items.push(<Col key={this.state.recent[i].cId+''+i} lg={2} md={4} xs={6} style={{padding: 10}}>
          <Link to={"/detail/"+this.ctypeName(this.state.recent[i].ctypeId)+'/'+this.state.recent[i].cId}>
            <div style={{backgroundColor: '#ffffff',height:'100%'}}>
              <Row style={{margin:0}}>
                <Col xs={12} style={{overflow:'hidden',padding:0}}>
                {/* <Link to={"/detail/"+this.ctypeName(this.state.recent[i].ctypeId)+'/'+this.state.recent[i].cId}> */}
                  <img src={this.state.recent[i].imgUrl} alt="" style={{width:'100%'}}/>
                {/* </Link> */}
                </Col>
                <Col xs={12} style={{padding:5}}>
                  <div><Tag ctype ={this.state.recent[i].ctypeId}/></div>
                </Col>
                <Col xs={12} style={{padding:5,height:58,overflow:'hidden',textOverflow:'ellipsis'}}>{this.state.recent[i].name}</Col>
              </Row>
            </div>
          </Link>
        </Col>  )
      }
      return (
        <Row className="contentLatest py-4">
          <Col>
            <Container>
              <Row >
                <Col xs={12}>
                  <div className="borderIcon" style={{ marginLeft: 0, marginRight: 5, float: 'left' }}>
                    <img src={require("../../img/clock.png")} alt="" />
                  </div>
                  <h4 style={{ fontWeight: 'bold', marginTop: 15, color: '#ffffff' }}>{t("main.recent_content")}</h4>
                </Col>
                <Col xs={12} style={{ marginTop: 10 }}>
                  <Row style={{ width: '100%', margin: 0 }}>
                    {items}
                  </Row>
                </Col>
              </Row>
            </Container>
          </Col>
        </Row>
      )
    }
  }
  }

  render() {
    const { t } = this.props;

    return (
      
      <Row>
        <Col>
        <MetaTags>
            <title>{'OBEC Content Center'}</title>
        </MetaTags>

        {this.setBanner()}

        <Row className="bg-light">
          <Col xs={12} className="py-3">
            <Container>
            <Menu {...this.props}/>
            </Container>
          </Col>
        </Row>

        {this.setHashtags()}

        <Row className="contentBook py-4">
          <Col>
            <Container>
              <Row>
                <Col xs={8}>
                  <div className="borderIcon" style={{ marginLeft: 0, marginRight: 5, float: 'left' }}>
                    <img src={require("../../img/book.png")} alt="" />
                  </div>
                  <h4 style={{ color: '#ffffff', fontWeight: '300', marginTop: 15 }}>{t("main.recommend_book")}</h4>
                </Col>
                <Col xs={4} style={{ textAlign: 'right'}}>
                  <Link to="/store/type/1">
                      <Button variant="outline-light" style={{fontSize: 16,fontWeight:'300',marginTop:5,borderRadius:25,paddingLeft:15,paddingRight:15}}>
                        {t("main.view_all")}
                      </Button>
                    </Link>
                </Col>
                {this.setEbookRec()}
              </Row>
            </Container>
          </Col>
        </Row>

        <Row className="contentVdo py-4">
          <Col>
            <Container>
              <Row>
                <Col xs={8}>
                  <div className="borderIconBlue" style={{ marginLeft: 0, marginRight: 5, float: 'left' }}>
                    <img src={require("../../img/play.png")} alt="" />
                  </div>
                  <h4 style={{ marginTop: 15 }}>{t("main.recommend_vdo")}</h4>
                </Col>
                <Col xs={4} style={{ textAlign: 'right' }}>
                  <Link to="/store/type/3">
                    <Button variant="outline-primary" style={{ fontSize: 16, marginTop: 5, borderRadius: 25, paddingLeft: 15, paddingRight: 15 }}>
                      {t("main.view_all")}
                    </Button>
                  </Link>
                </Col>   
                {this.setVdoRec()}
              </Row>
            </Container>
          </Col>
        </Row>

        <Row className="contentRecomend py-4">
          <Col>
            <Container>
              <Row>
                <Col xs={12}>
                  <div className="borderIcon" style={{ marginLeft: 0, marginRight: 5, float: 'left' }}>
                    <img src={require("../../img/star.png")} alt="" />
                  </div>
                  <h4 style={{ marginTop: 15, color: '#ffffff' }}>{t("main.interest_content")}</h4>
                </Col>
                {this.setOtherRec()}
              </Row>
            </Container>
          </Col>
        </Row>

        {this.setRecent()}
        {/* <Row className="contentLatest">
          <Col xs={12}>
            <div className="borderIcon" style={{ marginLeft: 0, marginRight: 5, float: 'left' }}>
              <img src={require("../../img/clock.png")} alt="" />
            </div>
            <h4 style={{ fontWeight: 'bold', marginTop: 15, color: '#ffffff' }}>เนื้อหาที่เรียกดูล่าสุด</h4>
          </Col>
          {this.setRecent()}
        </Row> */}
        </Col>
      </Row>
    );
  }

  ctypeName(cId){
    if(cId == 1){return 'book'}
    else if(cId == 2){return 'application'}
    else if(cId == 3){return 'vdo'}
    else if(cId == 4){return 'images'}
    else if(cId == 5){return 'sound'}
    else if(cId == 6){return 'multimedia'}
    else if(cId == 8){return 'test'}
    else {return 'book'}
  }
}

export default translate(Main);
