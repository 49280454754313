import React from 'react'
import {Button} from 'react-bootstrap';

class Pagination extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            page: 0,
            totalPage: 1,
            range: 2
        }
        //this.onclickPage=this.onclickPage.bind(this);
    }

    componentDidMount(){
        this.setState({
            page: this.props.page,
            totalPage: this.props.totalPage,
            range: this.props.range
        });
    }

    componentDidUpdate(prevProps){
        if (this.props.page !== prevProps.page) {
            this.setState({ page: this.props.page});
        }
        if (this.props.totalPage !== prevProps.totalPage) {
            this.setState({ totalPage: this.props.totalPage});
        }
    }

    pagination = (c, m, r=2) => {  //c หน้าปัจจุบัน  m จำนวนหน้าทั้งหมด r  ระยะของช่วงปุ่มเลขหน้า
        let current = c,
            last = m,
            delta = r,
            left = current - delta,
            right = current + delta + 1,
            range = [],
            rangeWithDots = [],
            l;
     
        for (let i = 1; i <= last; i++) {
            if (i === 1 || i === last || (i >= left && i < right)) {
                range.push(i);
            }
        }
     
        for (let i of range) {
            if (l) {
                if (i - l === 2) {
                    rangeWithDots.push(l + 1);
                } else if (i - l !== 1) {
                    rangeWithDots.push('...');
                }
            }
            rangeWithDots.push(i);
            l = i;
        }
     
        return rangeWithDots;
     }

    clickPage = (p) => {
        //if (process.env.REACT_APP_ENV_MODE === "development") console.log(p);
        if(p > -1 && p < this.state.totalPage){
            if(p !== this.state.page){
                this.setState({page:p});
                this.props.pageNumber(p);
            }
        }else{
            console.log("out of page");
        }
    }

     setPageNumb = () => {
        if (this.state.totalPage > 1) {
            const paging = this.pagination(this.state.page+1, this.state.totalPage, this.state.range);
            let items = [];
            for (var i = 0; i < paging.length; i++) {
                let pageNum = paging[i]-1;
                if(paging[i] !== '...'){
                    items.push(
                        <Button  onClick={(e) => this.clickPage(pageNum)} key={"page"+pageNum}
                        className={this.state.page === pageNum ? "buttonPageActive" : "buttonPage"} 
                        style={{ marginLeft: 5, flex: 1 }}>{paging[i]}</Button>
                    )
                }else{

                    items.push(
                        <label key={"pageNan"+i}style={{ marginLeft: 5,flex:1,padding:5}}>{paging[i]}</label>
                    )
                }
            }
            return (
                <>
                <Button onClick={() => this.clickPage(this.state.page - 1)} className="buttonPage" style={{ marginLeft: 5, flex: 1 }}>ก่อนหน้า</Button>
                {items}
                <Button onClick={() => this.clickPage(this.state.page + 1)} className="buttonPage" style={{ marginLeft: 5, flex: 1 }}>ถัดไป</Button>
                </>
            )
        } else {
            return []
        }
    }
   
    render() {
        //const { page, totalPage, range } = this.props;
        return this.setPageNumb();
    }
  }

export default Pagination