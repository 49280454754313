import React from 'react';
import './register.css';
import { Row, Col } from 'react-bootstrap';

class Page404 extends React.Component {
  
  constructor(props, context) {
    super(props, context);

    this.state = {
    };
  }

  componentDidMount (){
    
  }

  componentDidUpdate(prevProps){

  }
    
    render() {
      return (
        <>
        <Row as="div" style={{margin:0}} className="content">
          <Col col={12} className="d-flex justify-content-center">
            <div className="pagenotfound">
              <h3 className="text-center mb-4"><strong>Page Not Found</strong></h3>
              <h4 className="text-center">ไม่พบหน้าตามที่ระบุ</h4>
              
            </div>
            

          </Col>
        </Row>
        </>
      );
    }
  }

export default Page404;