import React from 'react';
// import './App.css';
import {Container, } from 'react-bootstrap';

import Header from './screen/header';
import Footer from './screen/footer';
import Routes from './screen/routes';
// import retina from './component/retina.js';

class App extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      mainpoint: process.env.REACT_APP_API_URL,
      facebook_id: process.env.REACT_APP_FACEBOOK_ID,
      google_id: process.env.REACT_APP_GOOGLE_ID,
      microsoft_id: process.env.REACT_APP_MICROSOFT_ID,
      cType: [
        {id: 1, name: 'หนังสือ', en: 'book'},
        {id: 2, name: 'แอปพลิเคชัน', en: 'application'},
        {id: 3, name: 'วีดีโอ', en: 'vdo'},
        {id: 4, name: 'รูปภาพ', en: 'images'},
        {id: 5, name: 'เสียง', en: 'sound'},
        {id: 6, name: 'มัลติมีเดีย', en: 'Multimedia'},
        {id: 8, name: 'ข้อสอบ', en: 'test'},
        {id: 11, name: 'เทมเพลต', en: 'template'}
      ]
    }
  }

  componentDidMount(){
    // window.addEventListener('load', retina);
  }

  render() {
    if (process.env.REACT_APP_ENV_MODE === "development") console.log(process.env.REACT_APP_ENV_MODE);
    return (
      <Container fluid>
        {/* <Header {...this.state}/> */}
        <Routes {...this.state}/>
        {/* <Footer /> */}
      </Container >
    );
  }
}

export default App;
