import React from 'react';
import './register.css';
import { Alert, Button, Row, Col, Modal, Form, InputGroup } from 'react-bootstrap';
import { FaEyeSlash, FaEye, FaChevronLeft, FaChevronRight } from "react-icons/fa";
import Select from 'react-select'
import DatePicker, { registerLocale } from "react-datepicker";
import Th from 'date-fns/locale/th';
import moment from 'moment';
//import "react-datepicker/dist/react-datepicker.css";
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import axios from 'axios';
import Loader from '../component/loader';
import InputMask from 'react-input-mask';
import range from "lodash/range";
import getYear from "date-fns/getYear";
import getMonth from "date-fns/getMonth";
import getDate from "date-fns/getDate";

const years = range(1950, getYear(new Date()) + 1, 1);
const months = [
  { en: "January", th: "มกราคม" },
  { en: "February", th: "กุมภาพันธ์" },
  { en: "March", th: "มีนาคม" },
  { en: "April", th: "เมษายน" },
  { en: "May", th: "พฤษภาคม" },
  { en: "June", th: "มิถุนายน" },
  { en: "July", th: "กรกฏาคม" },
  { en: "August", th: "สิงหาคม" },
  { en: "September", th: "กันยายน" },
  { en: "October", th: "ตุลาคม" },
  { en: "November", th: "พฤศจิกายน" },
  { en: "December", th: "ธันวาคม" }
];
const MySwal = withReactContent(Swal)
//require('moment/locale/th');

class CustomInput extends React.Component {

  handleValue = (date) => {
    if (date) {
      const d = date.split("-");
      const tDate = new Date(d[2] + '-' + d[1] + '-' + d[0]);
      let dd = ((tDate.getDate() < 10) ? '0' : '').concat(tDate.getDate());
      let MM = (((tDate.getMonth() + 1) < 10) ? '0' : '').concat(tDate.getMonth() + 1);
      let yyyy = Number(tDate.getFullYear());
      if (process.env.REACT_APP_ENV_MODE === "development") console.log("input view date", date, dd + '-' + MM + '-' + yyyy);
      return dd + '-' + MM + '-' + yyyy;
    }
  }

  render() {
    return (
      <Form.Control id="ubirthdate" className={this.props.className} onClick={this.props.onClick} value={this.handleValue(this.props.value)} onChange={() => { }} readOnly />
    );
  }
}

class Register extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      title: '',
      firstname: '',
      lastname: '',
      gender: '',
      tel: '',
      birthDay: '',
      pid: '',
      email: '',
      password: '',
      cfpassword: '',
      userType: '',
      address: '',
      provinceId: 0,
      amphurId: 0,
      tambolId: 0,
      obecAreaId: 0,
      obecSchoolId: 0,
      zipcode: '',
      accept: false,

      passwordHidden: true,
      cfpasswordHidden: true,
      provinceList: [],
      provinceSelectValue: [],
      amphurList: [],
      amphurSelectValue: [],
      tambolList: [],
      tambolSelectValue: [],
      areaList: [],
      areaSelectValue: [],
      schoolList: [],
      schoolSelectValue: [],
      birthDate: null,
      userTypes: null,
      alertShow: false,
      selectError: false,
      variant: null,
      message: '',
      loading: false,
      require13pid: false
    };
    this.dateChange = this.dateChange.bind(this);
  }

  async componentDidMount() {
    await this.getUserTypes();
    await this.getProvince();
    await this.getAreas();
  }

  getUserTypes = () => {
    return axios.get(this.props.mainpoint + '/api/v1/usertypes').then(
      success => {
        //if (process.env.REACT_APP_ENV_MODE === "development") console.log(success);
        const { status, data } = success
        if (status === 201 || status === 200) {
          //if (process.env.REACT_APP_ENV_MODE === "development") console.log(data);
          let userTypesArray = [];
          data.result.forEach(element => {
            userTypesArray.push({ value: element.uRoleId, label: element.name });
          });
          this.setState({
            userTypes: userTypesArray
          });
        }
      },
      error => {
        if (process.env.REACT_APP_ENV_MODE === "development") console.log(error)
      }
    );
  }

  getProvince = () => {
    return axios.get(this.props.mainpoint + '/api/v1/provinces/0').then(
      success => {
        //if (process.env.REACT_APP_ENV_MODE === "development") console.log('province',success);
        const { status, data } = success
        if (status === 201 || status === 200) {
          if (process.env.REACT_APP_ENV_MODE === "development") console.log('province', data);
          let provincesArray = [];
          data.result.forEach(element => {
            provincesArray.push({ value: element.provinceId, label: element.name });
          });
          this.setState({
            provinceList: provincesArray
          });
        }
      },
      error => {
        if (process.env.REACT_APP_ENV_MODE === "development") console.log('Error', error);
      }
    );
  }

  getAmphur = (id) => {
    return axios.get(this.props.mainpoint + '/api/v1/amphurs/0/provinceid/' + id).then(
      success => {
        const { status, data } = success
        if (status === 201 || status === 200) {
          if (process.env.REACT_APP_ENV_MODE === "development") console.log('amphur', data);
          let amphurArray = [];
          data.result.forEach(element => {
            amphurArray.push({ value: element.amphurId, label: element.name });
          });
          this.setState({
            amphurList: amphurArray
          });
        }
      },
      error => {
        if (process.env.REACT_APP_ENV_MODE === "development") console.log('Error', error);
      }
    );
  }

  getTambol = (id) => {
    return axios.get(this.props.mainpoint + '/api/v1/tambols/0/amphurid/' + id).then(
      success => {
        const { status, data } = success
        if (status === 201 || status === 200) {
          if (process.env.REACT_APP_ENV_MODE === "development") console.log('tambol', data);
          let tambolArray = [];
          data.result.forEach(element => {
            tambolArray.push({ value: element.tambolId, label: element.name });
          });
          this.setState({
            tambolList: tambolArray
          });
        }
      },
      error => {
        if (process.env.REACT_APP_ENV_MODE === "development") console.log('Error', error);
      }
    );
  }
  getAreas = () => {
    // let areaArray = [{
    //   value: "1",
    //   label: "สพม.กรุงเทพมหานคร เขต 1"
    // }, {
    //   value: "2",
    //   label: "สพม.กรุงเทพมหานคร เขต 2"
    // }];

    // this.setState({
    //   areaList: areaArray
    // });
    return axios.get(this.props.mainpoint + '/api/v1/obec/areas').then(
      success => {
        const {
          status,
          data
        } = success
        if (status === 201 || status === 200) {
          if (process.env.REACT_APP_ENV_MODE === "development") console.log('areas', data);
          let areaArray = [];
          data.result.forEach(element => {
            areaArray.push({
              value: element.areaId,
              label: element.abbreviation
            });
          });
          this.setState({
            areaList: areaArray
          });
        }
      },
      error => {
        if (process.env.REACT_APP_ENV_MODE === "development") console.log('Error', error);
      }
    );
  }
  getSchools = (areaId) => {
    return axios.get(this.props.mainpoint + '/api/v1/obec/areas/' + areaId + '/schools').then(
      success => {
        const {
          status,
          data
        } = success
        if (status === 201 || status === 200) {
          if (process.env.REACT_APP_ENV_MODE === "development") console.log('schools', data);
          let schoolArray = [];
          data.result.forEach(element => {
            schoolArray.push({
              value: element.id,
              label: element.name
            });
          });
          this.setState({
            schoolList: schoolArray
          });
        }
      },
      error => {
        if (process.env.REACT_APP_ENV_MODE === "development") console.log('Error', error);
      }
    );
  }
  togglePassword = () => {
    this.setState({ passwordHidden: !this.state.passwordHidden });
  }

  toggleCfPassword = () => {
    this.setState({ cfpasswordHidden: !this.state.cfpasswordHidden });
  }

  selectUserType = (optionSelected) => {
    //if (process.env.REACT_APP_ENV_MODE === "development") console.log(optionSelected.value, optionSelected.label);
    this.setState({ userType: optionSelected.value });
    this.setState({ selectError: false });

    if (optionSelected.value >= 52 && optionSelected.value <= 54) {
      this.setState({ require13pid: true });
    } else {
      this.setState({ require13pid: false });
    }
    this.setState({
      obecAreaId:0,
      areaSelectValue: [],
      obecSchoolId:0,
      schoolSelectValue: []
    });
  }

  selectProvince = (optionSelected) => {
    this.setState({ amphurSelectValue: [], tambolSelectValue: [] })
    if (process.env.REACT_APP_ENV_MODE === "development") console.log("changeProvince", optionSelected);
    this.setState({
      provinceId: optionSelected.value,
      provinceSelectValue: [{ value: optionSelected.value, label: optionSelected.label }]
    });
    if (optionSelected.value == '0') {
      this.setState({ amphurList: [], tambolList: [] })
    } else {
      this.getAmphur(optionSelected.value)
    }
  }

  selectAmphur = (optionSelected) => {
    this.setState({ tambolSelectValue: [] })
    if (process.env.REACT_APP_ENV_MODE === "development") console.log("changeAmphur", optionSelected);
    this.setState({
      amphurId: optionSelected.value,
      amphurSelectValue: [{ value: optionSelected.value, label: optionSelected.label }]
    });
    this.getTambol(optionSelected.value);
  }

  selectTambol = (optionSelected) => {
    if (process.env.REACT_APP_ENV_MODE === "development") console.log("changeTambol", optionSelected);
    this.setState({
      tambolId: optionSelected.value,
      tambolSelectValue: [{ value: optionSelected.value, label: optionSelected.label }]
    });
  }
  selectArea = (optionSelected) => {
    this.setState({ schoolSelectValue: []})
    if (process.env.REACT_APP_ENV_MODE === "development") console.log("changeArea", optionSelected);
    this.setState({
      obecAreaId: optionSelected.value,
      areaSelectValue: [{ value: optionSelected.value, label: optionSelected.label }]
    });
    if (optionSelected.value == '0') {
      this.setState({ schoolList: []})
    } else {
      this.getSchools(optionSelected.value)
    }
  }
  selectSchool = (optionSelected) => {
    if (process.env.REACT_APP_ENV_MODE === "development") console.log("changeSchool", optionSelected);
    this.setState({
      obecSchoolId: optionSelected.value,
      schoolSelectValue: [{ value: optionSelected.value, label: optionSelected.label }]
    });
  }
  onDatepickerRef = (el) => {
    if (el && el.input) {
      el.input.readOnly = true;
    }
  }

  dateChange(date) {
    let dd = (moment(date).get("date") < 10 ? '0' : '') + moment(date).get("date");
    let MM = ((moment(date).get("month") + 1) < 10 ? '0' : '') + (moment(date).get("month") + 1);
    let yyyy = moment(date).get("year");
    this.setState({
      birthDate: date,
      birthDay: dd + '-' + MM + '-' + yyyy
    });
  }

  handleMonth = (date) => {
    return months.filter((item, index) => {
      return index == moment(date).get("month");
    }).map(ele => ele.th)[0];
  }

  cancelRegister = () => {
    this.setState({
      title: '',
      fname: '',
      lname: '',
      gender: '',
      tel: '',
      birthDay: '',
      ctzId: '',
      email: '',
      password: '',
      cfpassword: '',
      userType: '',
      accept: false,
      birthDate: null,
      provinceId: 0,
      amphurId: 0,
      tambolId: 0,
      obecAreaId: 0,
      obecSchoolId: 0,
      address: '',
      selectError: false,
      alertShow: false
    });
    this.props.onHide();
  }

  swalFire = (msg) => {
    MySwal.fire({
      type: 'warning',
      confirmButtonText: 'ตกลง',
      text: msg
    });
  }

  setAlert = (name, type, msg) => {
    this.setState({ selectError: false });
    document.getElementById('ugender').style.borderColor = '#ced4da';
    document.getElementById('uterms').style.border = 'none';
    let x = document.getElementsByClassName('is-invalid');
    for (let i = 0; i < x.length; i++) {
      x[i].classList.remove('is-invalid');
    }
    let y = document.getElementById(name);
    // if (name == 'ugender') {
    //   y.style.borderColor = '#ced4da';
    // }
    if (name == 'uusertype') {
      this.setState({ selectError: true });
    } else if (name == 'ugender') {
      y.style.borderColor = '#dc3545';
    } else if (name == 'uterms') {
      y.style.border = '1px solid #dc3545';
    } else {
      y.classList.add('is-invalid');
    }

    this.setState({
      alertShow: true,
      variant: type,
      message: msg
    });
    let regDiv = document.getElementById('register-form');
    regDiv.scrollIntoView(true);
  }

  checkPid = (id) => {
    if (id.length != 13) return false;
    let sum = 0;
    for (let i = 0; i < 12; i++) {
      sum += parseFloat(id.charAt(i)) * (13 - i);
    }
    if ((11 - sum % 11) % 10 != parseFloat(id.charAt(12))) {
      return false;
    } else {
      return true;
    }
  }

  validate = () => {
    //if (process.env.REACT_APP_ENV_MODE === "development") console.log(this.state);
    const { firstname, lastname, gender, birthDay, pid, email, password, cfpassword, userType, accept, provinceId, obecAreaId ,obecSchoolId, zipcode } = this.state;

    let emailPattern = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!email) return this.setAlert('uemail', 'danger', 'กรุณากรอกอีเมล');
    if (!emailPattern.test(email)) return this.setAlert('uemail', 'warning', 'กรุณากรอกรูปแบบอีเมลให้ถูกต้อง');
    if (!password) return this.setAlert('upassword', 'danger', 'กรุณากรอกรหัสผ่าน');
    if (password.length < 8) return this.setAlert('upassword', 'danger', 'รหัสผ่านต้องมากกว่าหรือเท่ากับ 8 ตัวอักษร');
    if (password.length > 20) return this.setAlert('upassword', 'danger', 'รหัสผ่านต้องน้อยกว่าหรือเท่ากับ 20 ตัวอักษร');
    if (!cfpassword) return this.setAlert('ucfpassword', 'danger', 'กรุณายืนยันรหัสผ่าน');
    if (password !== cfpassword) return this.setAlert('ucfpassword', 'danger', 'กรุณากรอกรหัสผ่านกับยืนยันรหัสผ่านให้ตรงกัน');
    if (!userType) return this.setAlert('uusertype', 'danger', 'กรุณาเลือกประเภทผู้ใช้งาน');
   
   
    // if (!title || (title.trim() == '')) return this.setAlert('title','danger','กรุณากรอกคำนำหน้าชื่อ');
    if (!firstname || (firstname.trim() == '')) return this.setAlert('ufname', 'danger', 'กรุณากรอกชื่อ');
    if (!lastname || (lastname.trim() == '')) return this.setAlert('ulname', 'danger', 'กรุณากรอกนามสกุล');
    // if (!gender) return this.setAlert('ugender','danger','กรุณาระบุเพศ');
    // if (!tel || (tel.trim() == '')) return this.setAlert('utel','danger','กรุณากรอกหมายเลขโทรศัพท์');
    // if (tel.replace(/-/gi, '').length < 9) return this.setAlert('utel','danger','กรุณาระบุหมายเลขโทรศัพท์ให้ครบจำนวน');
    // if (!birthDay || (birthDay.trim() == '')) return this.setAlert('ubirthdate','danger','กรุณาระบุวัน เดือน ปีเกิด');
    if (this.state.require13pid) {
      if (!pid || (pid.trim() == '')) return this.setAlert('ucid', 'danger', 'กรุณากรอกเลขประจำตัวประชาชน');
    }

    if (pid.trim() !== '') {
      if (pid.replace(/ /gi, '').length < 13) return this.setAlert('ucid', 'danger', 'กรุณาระบุเลขประจำตัวประชาชนให้ครบ 13 หลัก');
    }
    // if (!this.checkPid(pid.replace(/ /gi, ''))) return this.setAlert('ucid','danger','กรุณาระบุเลขประจำตัวประชาชนให้ถูกต้อง');
    if ( userType && userType != "50" && userType != "51") {
      if (userType == "52") {
        if (obecAreaId ==0) return this.setAlert('obecAreaId', 'danger', 'กรุณาเลือกเขตพื้นที่การศึกษา');
        if (obecSchoolId ==0) return this.setAlert('obecSchoolId', 'danger', 'กรุณาเลือกโรงเรียน');
      }else{
        if (obecAreaId ==0) return this.setAlert('obecAreaId', 'danger', 'กรุณาเลือกเขตพื้นที่การศึกษา');
       
      }
    }
    // if (!address || (address.trim() == '')) return this.setAlert('address','danger','กรุณาระบุที่อยู่');
    if (!provinceId) return this.setAlert('provinceId', 'danger', 'กรุณาระบุจังหวัด');
    // if (!amphurId) return this.setAlert('amphurId','danger','กรุณาระบุอำเภอ');
    // if (!tambolId) return this.setAlert('tambolId','danger','กรุณาระบุตำบล');    
    // if (zipcode.replace(/_/gi, '').length < 5) return this.setAlert('zipcode', 'danger', 'กรุณาระบุรหัสไปรษณีย์ให้ครบ 5 หลัก');

    if (this.state.alertShow) {
      document.getElementById('uterms').style.border = 'none';
      this.setState({ alertShow: false });
    }
    if (accept) {
      this.registerSubmit();
    } else {
      this.setAlert('uterms', 'warning', 'กรุณา "ยอมรับเงื่อนไขและนโยบายความเป็นส่วนตัว"');
    }
  }

  registerSubmit = () => {
    let data = {
      title: this.state.title,
      firstname: this.state.firstname,
      lastname: this.state.lastname,
      gender: this.state.gender,
      tel: this.state.tel.replace(/-|_|\./gi, ''),
      birthday: this.state.birthDay,
      pid: this.state.pid.replace(/ /gi, ''),
      email: this.state.email,
      username: this.state.email,
      password: this.state.password,
      uRoleId: this.state.userType,
      address: this.state.address,
      provinceId: this.state.provinceId,
      obecAreaId: this.state.obecAreaId,
      obecSchoolId: this.state.obecSchoolId,
      amphurId: this.state.amphurId,
      tambolId: this.state.tambolId,
      zipcode: this.state.zipcode,
      registerDeviceId: 4
    }
    //if (process.env.REACT_APP_ENV_MODE === "development") console.log(data);
    this.setState({ loading: true });
    axios.post(this.props.mainpoint + '/api/v1/user/register', data).then(
      success => {
        this.setState({ loading: false });
        const { status, data } = success
        if (process.env.REACT_APP_ENV_MODE === "development") console.log(success);
        if (status === 201 || status === 200) {
          //if (process.env.REACT_APP_ENV_MODE === "development") console.log(data);
          if (data.code == 200) {
            Swal.fire({
              type: 'success',
              text: data.message,
              confirmButtonText: 'ตกลง',
              onClose: () => {
                this.cancelRegister();
              }
            });
          } else if (data.code == 511) {
            this.swalFire(data.message);
          }
        } else {

        }
      },
      error => {
        if (process.env.REACT_APP_ENV_MODE === "development") console.log(JSON.parse(JSON.stringify(error)));
        const failure = JSON.parse(JSON.stringify(error));
        const { status, data } = failure.response;
        this.swalFire(data.message);
        this.setState({ loading: false });
      }
    );
  }

  setPolicyState = (value) => {
    this.setState({ alertShow: false, message: "" });
    this.props.topolicy(true);
  }

  render() {
    return (
      <Modal
        {...this.props}
        //size="lg"
        id="register-form"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      //onHide={this.cancelRegister}
      >
        <Modal.Header className="p-4" style={{ borderBottom: 'none' }} >
          <Modal.Title id="contained-modal-title-vcenter" style={{ paddingBottom: '0.3em', borderBottom: '3px solid #0eb06a' }}>
            <strong>สมัครสมาชิก</strong>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="pl-4 pr-4">
          <Loader loading={this.state.loading} />
          <Row>
            <Col md={12}>
              <Alert show={this.state.alertShow} variant={this.state.variant}>
                {this.state.message}
              </Alert>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <Form.Group>
                <Form.Label>อีเมล <span className="required">*</span></Form.Label>
                <Form.Control type="email" id="uemail" className="borderRadius background-gray" placeholder=""
                  onChange={e => this.setState({ email: e.target.value.trim() })} />
              </Form.Group>
            </Col>
            <Col md={6}></Col>
          </Row>
          <Row>
            <Col md={6}>
              <Form.Group>
                <Form.Label>รหัสผ่าน <span className="required">*</span></Form.Label>
                <InputGroup>
                  <Form.Control type={this.state.passwordHidden ? "password" : "text"} id="upassword" className="borderRadius background-gray"
                    minLength="8" maxLength="20" onChange={e => this.setState({ password: e.target.value.trim() })} />
                  <div className="input-group-append">
                    <span className="input-group-text borderRadius">
                      <a onClick={this.togglePassword}>
                        {this.state.passwordHidden ? <FaEye /> : <FaEyeSlash />}
                      </a>
                    </span>
                  </div>
                </InputGroup>
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group>
                <Form.Label>ยืนยันรหัสผ่าน <span className="required">*</span></Form.Label>
                <InputGroup>
                  <Form.Control type={this.state.cfpasswordHidden ? "password" : "text"} id="ucfpassword" className="borderRadius background-gray"
                    minLength="8" maxLength="20" onChange={e => this.setState({ cfpassword: e.target.value.trim() })} />
                  <div className="input-group-append">
                    <span className="input-group-text borderRadius">
                      <a onClick={this.toggleCfPassword}>
                        {this.state.cfpasswordHidden ? <FaEye /> : <FaEyeSlash />}
                      </a>
                    </span>
                  </div>
                </InputGroup>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <Form.Group controlId="">
                <Form.Label>ประเภทผู้ใช้งาน <span className="required">*</span></Form.Label>
                <Select
                  placeholder={'-- เลือก --'}
                  id="uusertype"
                  styles={
                    {
                      control: (styles, state) => (this.state.selectError ? {
                        ...styles,
                        backgroundColor: '#f9f9f9',
                        borderRadius: '20px',
                        borderColor: state.isFocused ? '#dc3545' : '#dc3545',
                        '&:hover': '#dc3545',
                        boxShadow: state.isFocused ? '0 0 0 0.2rem rgba(220, 53, 69,.25)' : null
                      } : {
                        ...styles,
                        backgroundColor: '#f9f9f9',
                        borderRadius: '20px',
                        borderColor: state.isFocused ? '#80bdff' : '#ced4da',
                        boxShadow: state.isFocused ? '0 0 0 0.2rem rgba(0,123,255,.25)' : null
                      })
                    }
                  }
                  options={this.state.userTypes}
                  onChange={this.selectUserType}
                  isSearchable={false} />

              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group>
                <Form.Label>คำนำหน้าชื่อ</Form.Label>
                <Form.Control type="text" placeholder="" id="utitle" className="borderRadius inputProfile"
                  onChange={e => this.setState({ title: e.target.value.trim() })}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <Form.Group>
                <Form.Label>ชื่อ <span className="required">*</span></Form.Label>
                <Form.Control type="text" id="ufname" className="borderRadius background-gray" placeholder=""
                  onChange={e => this.setState({ firstname: e.target.value.trim() })} />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group>
                <Form.Label>นามสกุล <span className="required">*</span></Form.Label>
                <Form.Control type="text" id="ulname" className="borderRadius background-gray" placeholder=""
                  onChange={e => this.setState({ lastname: e.target.value.trim() })} />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <Form.Group>
                <Form.Label>เพศ </Form.Label>
                <Col as="div" className="form-control borderRadius background-gray" id="ugender" style={{}}>
                  <Form.Check
                    inline
                    type="radio"
                    name="gender"
                    value="m"
                    label="ชาย"
                    id='gender-male'
                    className="ml-3 checkBox"
                    onChange={e => this.setState({ gender: e.target.value })}
                  />
                  <Form.Check
                    inline
                    type="radio"
                    name="gender"
                    value="f"
                    label="หญิง"
                    id='gender-female'
                    className="ml-4 checkBox"
                    onChange={e => this.setState({ gender: e.target.value })}
                  />
                </Col>
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group>
                <Form.Label>เบอร์โทรศัพท์</Form.Label>
                <InputMask mask="999-999-9999" maskChar={null}
                  id="utel" placeholder=""
                  className="form-control borderRadius background-gray"
                  onChange={e => this.setState({ tel: e.target.value.trim() })} />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <Form.Group>
                <Form.Label>วัน เดือน ปีเกิด</Form.Label>
                <DatePicker
                  placeholderText=""
                  onChange={this.dateChange}
                  maxDate={new Date()}
                  selected={this.state.birthDate}
                  className="form-control borderRadius background-gray"
                  dateFormat="dd-MM-yyyy"
                  locale={Th}
                  ref={el => this.onDatepickerRef(el)}
                  customInput={<CustomInput />}
                  renderCustomHeader={({
                    date,
                    changeYear,
                    changeMonth,
                    decreaseMonth,
                    increaseMonth,
                    prevMonthButtonDisabled,
                    nextMonthButtonDisabled
                  }) => (
                    <div
                      style={{
                        margin: 5,
                        display: "flex",
                        justifyContent: "space-between"
                      }}
                    >
                      <Button style={{ height: "2em", padding: "0 .5em" }}
                        onClick={decreaseMonth}
                        disabled={prevMonthButtonDisabled}
                      >
                        <FaChevronLeft />
                      </Button>
                      <span style={{ fontSize: "1.5em" }}>{this.handleMonth(date)}</span>
                      <select style={{ borderRadius: "5px", padding: "0 .5em" }}
                        value={getYear(date)}
                        onChange={({ target: { value } }) => changeYear(value)}
                      >
                        {years.map(option => (
                          <option key={option} value={option}>
                            {option + 543}
                          </option>
                        ))}
                      </select>
                      {/* {getYear(date)+543} */}


                      <Button style={{ height: "2em", padding: "0 .5em" }}
                        onClick={increaseMonth}
                        disabled={nextMonthButtonDisabled}
                      >
                        <FaChevronRight />
                      </Button>
                    </div>
                  )}
                />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group>
                <Form.Label>รหัสประจำตัวประชาชน {(this.state.require13pid) ? <span className="required">*</span> : ''} </Form.Label>
                <InputMask mask="9 9999 99999 99 9" maskChar={null}
                  id="ucid" className="form-control borderRadius background-gray" placeholder=""
                  onChange={e => this.setState({ pid: e.target.value.trim() })} />
              </Form.Group>
            </Col>
           
            <Col md={6}  style={ !this.state.userType || this.state.userType == 50 || this.state.userType == 51   ?{display:'none'}:{display:'block'} }>
              <Form.Group controlId="">
                <Form.Label>เขตพื้นที่การศึกษา <span className="required">*</span></Form.Label>
                <Select
                  placeholder={'-- เลือก --'}
                  id="obecAreaId"
                  styles={
                    {
                      control: (styles, state) => (this.state.selectError ? {
                        ...styles,
                        backgroundColor: '#f9f9f9',
                        borderRadius: '20px',
                        borderColor: state.isFocused ? '#dc3545' : '#dc3545',
                        '&:hover': '#dc3545',
                        boxShadow: state.isFocused ? '0 0 0 0.2rem rgba(220, 53, 69,.25)' : null
                      } : {
                        ...styles,
                        backgroundColor: '#f9f9f9',
                        borderRadius: '20px',
                        borderColor: state.isFocused ? '#80bdff' : '#ced4da',
                        boxShadow: state.isFocused ? '0 0 0 0.2rem rgba(0,123,255,.25)' : null
                      })
                    }
                  }
                  value={this.state.areaSelectValue}
                  options={this.state.areaList}
                  onChange={this.selectArea}
                  isSearchable={false} />

              </Form.Group>
            </Col>
            <Col md={6}  style={ !this.state.userType || this.state.userType == 50 || this.state.userType == 51   ?{display:'none'}:{display:'block'} }>
              <Form.Group controlId="">
                <Form.Label>โรงเรียน {(this.state.userType == 52 ) ? <span className="required">*</span> : ''}</Form.Label>
                <Select
                  placeholder={'-- เลือก --'}
                  id="obecSchoolId"
                  styles={
                    {
                      control: (styles, state) => (this.state.selectError ? {
                        ...styles,
                        backgroundColor: '#f9f9f9',
                        borderRadius: '20px',
                        borderColor: state.isFocused ? '#dc3545' : '#dc3545',
                        '&:hover': '#dc3545',
                        boxShadow: state.isFocused ? '0 0 0 0.2rem rgba(220, 53, 69,.25)' : null
                      } : {
                        ...styles,
                        backgroundColor: '#f9f9f9',
                        borderRadius: '20px',
                        borderColor: state.isFocused ? '#80bdff' : '#ced4da',
                        boxShadow: state.isFocused ? '0 0 0 0.2rem rgba(0,123,255,.25)' : null
                      })
                    }
                  }
                  value={this.state.schoolSelectValue}
                  options={this.state.schoolList}
                  onChange={this.selectSchool}
                  isSearchable={false} />

              </Form.Group>
            </Col>
            {/* <Col md={12}>
              <Form.Group>
                <Form.Label>ที่อยู่</Form.Label>
                <Form.Control type="text" id="address" className="borderRadius background-gray" placeholder=""
                  onChange={e => this.setState({ address: e.target.value.trim() })} />
              </Form.Group>
            </Col> */}

            <Col lg={6} xs={12}>
              <Form.Group>
                <Form.Label>จังหวัด <span className="required">*</span></Form.Label>
                <Select
                  placeholder={''}
                  id="provinceId"
                  styles={
                    {
                      control: (styles, state) => (this.state.selectError ? {
                        ...styles,
                        backgroundColor: '#f9f9f9',
                        borderRadius: '20px',
                        borderColor: state.isFocused ? '#dc3545' : '#dc3545',
                        '&:hover': '#dc3545',
                        boxShadow: state.isFocused ? '0 0 0 0.2rem rgba(220, 53, 69,.25)' : null
                      } : {
                        ...styles,
                        backgroundColor: '#f9f9f9',
                        borderRadius: '20px',
                        borderColor: state.isFocused ? '#80bdff' : '#ced4da',
                        boxShadow: state.isFocused ? '0 0 0 0.2rem rgba(0,123,255,.25)' : null
                      })
                    }
                  }
                  value={this.state.provinceSelectValue}
                  options={this.state.provinceList}
                  onChange={this.selectProvince}
                  isSearchable={true}
                  isDisabled={false}
                />
              </Form.Group>
            </Col>

            <Col lg={6} xs={12}>
              <Form.Group>
                <Form.Label>อำเภอ</Form.Label>
                <Select
                  placeholder={''}
                  id="amphurId"
                  styles={
                    {
                      control: (styles, state) => (this.state.selectError ? {
                        ...styles,
                        backgroundColor: '#f9f9f9',
                        borderRadius: '20px',
                        borderColor: state.isFocused ? '#dc3545' : '#dc3545',
                        '&:hover': '#dc3545',
                        boxShadow: state.isFocused ? '0 0 0 0.2rem rgba(220, 53, 69,.25)' : null
                      } : {
                        ...styles,
                        backgroundColor: '#f9f9f9',
                        borderRadius: '20px',
                        borderColor: state.isFocused ? '#80bdff' : '#ced4da',
                        boxShadow: state.isFocused ? '0 0 0 0.2rem rgba(0,123,255,.25)' : null
                      })
                    }
                  }
                  value={this.state.amphurSelectValue}
                  options={this.state.amphurList}
                  onChange={this.selectAmphur}
                  isSearchable={true}
                  isDisabled={false}
                />
              </Form.Group>
            </Col>
            <Col lg={6} xs={12}>
              <Form.Group>
                <Form.Label>ตำบล</Form.Label>
                <Select
                  placeholder={''}
                  id="tambolId"
                  styles={
                    {
                      control: (styles, state) => (this.state.selectError ? {
                        ...styles,
                        backgroundColor: '#f9f9f9',
                        borderRadius: '20px',
                        borderColor: state.isFocused ? '#dc3545' : '#dc3545',
                        '&:hover': '#dc3545',
                        boxShadow: state.isFocused ? '0 0 0 0.2rem rgba(220, 53, 69,.25)' : null
                      } : {
                        ...styles,
                        backgroundColor: '#f9f9f9',
                        borderRadius: '20px',
                        borderColor: state.isFocused ? '#80bdff' : '#ced4da',
                        boxShadow: state.isFocused ? '0 0 0 0.2rem rgba(0,123,255,.25)' : null
                      })
                    }
                  }
                  value={this.state.tambolSelectValue}
                  options={this.state.tambolList}
                  onChange={this.selectTambol}
                  isSearchable={true}
                  isDisabled={false}
                />
              </Form.Group>
            </Col>

            <Col lg={6} xs={12}>
              <Form.Group>
                <Form.Label>รหัสไปรษณีย์ </Form.Label>
                <InputMask mask="99999" type="text" placeholder="" id="zipcode" className="form-control borderRadius background-gray"
                  onChange={e => this.setState({ zipcode: e.target.value.trim() })}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col as="div" className="mt-3 ml-3 mr-3 pt-3" id="uterms" style={{ backgroundColor: '#efefef', fontSize: '.8em' }}>
              <Form.Group>
                <Form.Check
                  required
                  inline
                  name="terms"
                  id="agreementform"
                  className="mr-0"
                  value="accepted"
                  onChange={e => this.setState({ accept: e.target.checked })}
                /><label htmlFor="agreementform" >ฉันยอมรับ
                  <a href="#"> <u className="text-primary" onClick={this.setPolicyState}>เงื่อนไขและนโยบายความเป็นส่วนตัว</u>
                  </a>
                </label>
              </Form.Group>
            </Col>
          </Row>

        </Modal.Body>
        <Modal.Footer className="justify-content-center" style={{ borderTop: 'none' }} >
          <Button className="borderRadius bg-light border-white mr-3" onClick={this.cancelRegister.bind(this)} style={{ width: '120px' }}>
            <span className="text-danger">ยกเลิก</span>
          </Button>
          <Button className="borderRadius bg-success border-success  ml-3" onClick={this.validate} style={{ width: '120px' }}>
            สมัครสมาชิก
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default Register;
