import React from 'react';
import { translate } from 'react-switch-lang';

class Tag extends React.Component {
    constructor(props, context) {
      super(props, context);
        
      this.state = {
        ctype:props.ctype
      };
    }

    render(){
        const { t } = this.props;
        if (this.state.ctype === 1) {
            return (<label className="radiusGreenThin">{t("tag.book")}</label>)
        }else if (this.state.ctype  === 3) {
            return (<label className="radiusPink">{t("tag.video")}</label>)
        }else if (this.state.ctype  === 5) {
            return (<label className="radiusOrange">{t("tag.sound")}</label>)
        }else if (this.state.ctype  === 4) {
            return (<label className="radiusBlue">{t("tag.photo")}</label>)
        }else if (this.state.ctype  === 8) {
            return (<label className="radiusPurple">{t("tag.exam")}</label>)
        }else if (this.state.ctype  === 2) {
            return (<label className="radiusApp">{t("tag.application")}</label>)
        }else if (this.state.ctype  === 6) {
            return (<label className="radiusFlash">{t("tag.multimedia")}</label>)
        }else if (this.state.ctype  === 11) {
            return (<label className="radiusFlash">{t("tag.template")}</label>)
        }else{
            return (<label className="radiusApp" style={{background:'#ff0300'}}>! No type {this.state.ctype}</label>)
        }
    }
}

export default translate(Tag);