import React from 'react';
import './register.css';
import { Alert, Button, Form, Row, Col, Card, InputGroup, Image } from 'react-bootstrap';
import Select from 'react-select'
import { FaCaretDown, FaCaretRight } from "react-icons/fa";
import axios from 'axios';
import Loader from '../component/loader';
import Pagination from '../component/pagination';
import Tag from '../component/tag';
import { Link } from 'react-router-dom';
import MetaTags from 'react-meta-tags';

class Search extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      isLogin: false,
      userToken: "",

      searchMsg: "",
      advanceSearch: {
        nameMsg: "",
        titleMsg: "",
        authorMsg: "",
        publisherMsg: "",
        isbnMsg: "",
        keywordMsg: "",
        descriptionMsg: "",
        subjectIds: "",
        classes: "",
        deweies: "",
        messages: ""
      },
      pagination: {
        asc: false,
        displayItem: 0,
        limit: 5,
        page: 0,
        sortBy: 1,
        totalItem: 0,
        totalPage: 0
      },
      searchResult: [],
      searched: false,
      cTypes: 0,
      cTypes2: 2,
      cTypes3: 29,
      alertShow: false,
      alertMessage: '',
      loading: false,
      cTypeSelect: { value: 0, label: 'ทั้งหมด' },
      cTypeSelect2: {},
      cTypeSelect3: {},
      cTypeData: [],
      cTypeData2: [],
      cTypeData3: [],
      sortBySelect: { value: 1, label: 'ตัวอักษร' },
      collapse: true
    };
    this.clickPage = this.clickPage.bind(this);
  }

  async componentDidMount() {
   
    if (typeof this.props.location.state !== 'undefined') {
      const { searchWord } = this.props.location.state;
      this.setState({ searchMsg: searchWord });
      await this.setData(false);
    } else if (this.props.searchWord !== '') {
      this.setState({ searchMsg: this.props.searchWord });
      await this.setData(false);
    }
    setTimeout(() => {
      document.getElementById("searchInput").focus();
    }, 1000);
    // ประเภท
    const ct = await [this.state.cTypeSelect, ...this.props.cType.map((item) => {
      return { value: item.id, label: item.name }
    })];
    console.log(ct);

    this.setState({ cTypeData: ct });
    // ระดับชั้น
    const categories2 = await axios.get(this.props.mainpoint + '/api/v1/categories/2')
    console.log(categories2);
    const ct2 = await [this.state.cTypeSelect2, ...categories2.data.result.map((item) => {
      return { value: item.id, label: item.name }
    })];
    this.setState({ cTypeData2: ct2 });
    this.setState({ cTypeSelect2: { value: 2, label: 'ทุกกลุ่มสาระการเรียนรู้' }, cTypes2: 2 });
    // กลุ่มสาระการเรียนรู้
    const categories3 = await axios.get(this.props.mainpoint + '/api/v1/categories/3')
    console.log("categories3");
    console.log(categories3);
    const ct3 = await [this.state.cTypeSelect3, ...categories3.data.result.map((item) => {
      return { value: item.id, label: item.name }
    })];
    this.setState({ cTypeData3: ct3 });
    this.setState({ cTypeSelect3: { value: 29, label: 'ทุกระดับชั้นปีการศึกษา' }, cTypes3: 29 });

  }

  async componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.searchWord !== this.props.searchWord && this.props.searchWord.trim() !== '') {
      this.setState({ searchMsg: this.props.searchWord });
      await this.setData(false);
    }
  }

  selectCType2 = (optionSelected) => {
    //if (process.env.REACT_APP_ENV_MODE === "development") console.log(optionSelected.value, optionSelected.label);
    let advanceSearch = this.state.advanceSearch;
    advanceSearch.subjectIds = optionSelected.value
    this.setState({
      advanceSearch,
      cTypeSelect2: optionSelected,
      cTypes2: optionSelected.value
    });
  }
  selectCType = (optionSelected) => {
    //if (process.env.REACT_APP_ENV_MODE === "development") console.log(optionSelected.value, optionSelected.label);
    this.setState({
      cTypeSelect: optionSelected,
      cTypes: optionSelected.value
    });
  }
  selectCType3 = (optionSelected) => {
    console.log("selectCType3");
    console.log(optionSelected);
    //if (process.env.REACT_APP_ENV_MODE === "development") console.log(optionSelected.value, optionSelected.label);
    let advanceSearch = this.state.advanceSearch;
    advanceSearch.classes = optionSelected.value
    this.setState({
      advanceSearch,
      cTypeSelect3: optionSelected,
      cTypes3: optionSelected.value
    });

  }
  selectSortBy = (optionSelected) => {
    //if (process.env.REACT_APP_ENV_MODE === "development") console.log(optionSelected.value, optionSelected.label);
    if (optionSelected.value != this.state.pagination.sortBy) {
      this.setState({ sortBySelect: optionSelected });
      let pagination = this.state.pagination;
      pagination.sortBy = optionSelected.value;
      this.setState({ pagination });
      this.setData(false);
    }
  }

  modelData = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  toLogin = () => {
    this.props.login(true);
  };

  collapseCtrl = () => {
    if (!this.state.collapse) {
      let advanceSearch = this.state.advanceSearch;
      advanceSearch.nameMsg = "";
      advanceSearch.titleMsg = "";
      advanceSearch.authorMsg = "";
      advanceSearch.publisherMsg = "";
      advanceSearch.isbnMsg = "";
      advanceSearch.keywordMsg = "";
      advanceSearch.descriptionMsg = "";
      advanceSearch.classes = "";
      advanceSearch.subjectIds = "";
      this.setState({ advanceSearch, cTypeSelect: { value: 0, label: 'ทั้งหมด' }, cTypes: 0 });
      this.setState({ advanceSearch, cTypeSelect2: { value: 2, label: 'ทุกกลุ่มสาระการเรียนรู้' }, cTypes2: 2 });
      this.setState({ advanceSearch, cTypeSelect3: { value: 29, label: 'ทุกระดับชั้นปีการศึกษา' }, cTypes3: 29 });
    }
    this.setState({ collapse: !this.state.collapse });
  };

  normalSearch = (paging = false) => {
    if (!paging) {
      let pagination = this.state.pagination;
      pagination.page = 0;
      this.setState({ pagination });
    }
    this.setState({ alertShow: false, alertMessage: '' });
    if (this.state.searchMsg == '') {
      this.setState({ alertShow: true, alertMessage: 'กรุณาระบุคำที่ต้องการค้นหา' });
      return;
    }
    let data = {
      searchMsg: this.state.searchMsg
    };
    this.sendData(data);
  }

  checkEmptyValue = () => {
    const { nameMsg, titleMsg, authorMsg, publisherMsg, isbnMsg, keywordMsg, descriptionMsg } = this.state.advanceSearch;
    if (this.state.searchMsg != '') {
      return false;
    } else if (this.state.searchMsg == '' && nameMsg == '' && titleMsg == '' && authorMsg == '' && publisherMsg == '' && isbnMsg == '' && keywordMsg == '' && descriptionMsg == '') {
      return true;
    } else {
      return false;
    }
  }

  setData = async (paging = false) => {
    let items = JSON.parse(await localStorage.getItem('islogin'));
    //if (process.env.REACT_APP_ENV_MODE === "development") console.log('login',items);
    if (items === null) {
      this.setState({ alertShow: true, alertMessage: 'กรุณาล็อกอินเข้าสู่ระบบ', isLogin: false, userToken: "" });
      return;
    } else {
      this.setState({ alertShow: false, isLogin: true, userToken: items.token });
    }
    //if (process.env.REACT_APP_ENV_MODE === "development") console.log('state',this.state);
    if (!paging) {
      let pagination = this.state.pagination;
      pagination.page = 0;
      this.setState({ pagination });
    }

    this.setState({ alertShow: false, alertMessage: '' });
    if (this.checkEmptyValue()) {
      this.setState({ alertShow: true, alertMessage: 'กรุณาระบุคำที่ต้องการค้นหา' });
      return;
    }
    let data = {
      advanceSearch: this.state.advanceSearch,
      searchMsg: this.state.searchMsg
    };
    this.sendData(data, paging);
  }

  sendData = async (value, paging) => {


    const api = axios.create({
      baseURL: this.props.mainpoint,
      headers: {
        "Content-Type": "application/json",
        "token": this.state.userToken
      },
      timeout: 85000
    });
    //if (process.env.REACT_APP_ENV_MODE === "development") console.log('searchValue',value);
    this.setState({ loading: true });
    const apiPath = `/api/v1/contents/search/ctypes/${this.state.cTypes}/ids/0/device/0/page/${this.state.pagination.page}/limit/${this.state.pagination.limit}/asc/${this.state.pagination.asc}/sortBy/${this.state.pagination.sortBy}`;
    api.post(apiPath, value).then(
      success => {
        this.setState({ loading: false });
        const { status, data } = success
        //if (process.env.REACT_APP_ENV_MODE === "development") console.log('success',success);
        if (status === 201 || status === 200) {
          if (data.code == 200) {
            if (process.env.REACT_APP_ENV_MODE === "development") console.log('result', data.result, data.pagination, this.state.pagination);
            if (!this.state.searched) { this.setState({ searched: true }) }
            let pagination = this.state.pagination;
            //pagination.page = data.pagination.page;
            //pagination.limit = data.pagination.limit;
            pagination.totalItem = data.pagination.totalItem;
            pagination.totalPage = data.pagination.totalPage;
            this.setState({
              pagination,
              searchResult: data.result
            });
            if (paging) {
              const elmnt = document.getElementById("searchResult");
              setTimeout(() => {
                elmnt.scrollIntoView();
              }, 500);
            }
          } else if (data.code == 511) {

          }
        } else {

        }
      },
      error => {
        if (process.env.REACT_APP_ENV_MODE === "development") console.log(error);
        this.setState({ loading: false });
      }
    );
  }

  setResult = () => {
    if (this.state.searchResult.length > 0) {
      let items = [];
      //console.log(this.props);
      this.state.searchResult.forEach(element => {
        let cType = this.props.cType.filter(item => {
          return item.id = element.ctypeId;
        }).map(item => {
          return item.en;
        })[0];
        let detailUrl = '/detail/' + cType + '/' + element.cId;
        items.push(
          <Card className="mb-3 w-100" key={element.cId}>
            <Card.Body className="search-body p-0">
              <Link to={detailUrl} className="search-img"><Image src={element.imgUrl} fluid></Image></Link>
              <div className="search-detail pt-2">
                <Link to={"/store/type/" + element.ctypeId}><Tag ctype={element.ctypeId} /></Link>
                <Card.Text>{element.name}</Card.Text>
                <p>{element.description}</p>
              </div>
            </Card.Body>
          </Card>);
      });
      return items;
    }

  }

  clickPage = (p) => {
    //if (process.env.REACT_APP_ENV_MODE === "development") console.log(p);
    let pagination = this.state.pagination;
    pagination.page = p;
    this.setState({ pagination });
    this.setData(true);
  }

  render() {
    return (
      <>
        <MetaTags>
          <title>{'OBEC Content Center'}</title>
        </MetaTags>
        <Loader loading={this.state.loading} />
        <Row as="div" style={{ margin: 0 }} className="content search-panel">
          <Col col={12}>
            <Form>
              <Card>
                <Card.Body>
                  <Row>
                    <Col md={6} sm={8}>
                      <Alert show={this.state.alertShow} variant="warning">
                        {this.state.alertMessage}
                        {!this.state.isLogin && this.state.userToken == "" ?
                          <a onClick={e => this.toLogin()}
                            style={{ color: "#42dfaf", cursor: "pointer", textDecoration: "underline", marginLeft: "1em" }}>เข้าสู่ระบบ </a>
                          :
                          null}
                      </Alert>
                      <Form.Group>
                        <Form.Label className="h4"><strong>ค้นหา</strong></Form.Label>
                        <InputGroup>
                          <Form.Control type="text" className="background-gray" placeholder="พิมพ์คำที่ต้องการค้นหา" id="searchInput" value={this.state.searchMsg}
                            onChange={e => this.setState({ searchMsg: e.target.value.trim() })} />

                        </InputGroup>
                      </Form.Group>
                    </Col>
                  </Row>
                  <h4 className="advsea-md-title" onClick={this.collapseCtrl}>
                    <strong>ค้นหาขั้นสูง</strong>{this.state.collapse ? <FaCaretRight /> : <FaCaretDown />}</h4>
                  {!this.state.collapse && <>
                    <Card className="background-gray advanced-search ">
                      <Card.Body >
                        <Row>
                          {/* <Col md={3} sm={4}>
                          <Form.Group controlId="">
                            <Form.Label>หมวดหมู่</Form.Label>
                            <Select 
                              // placeholder={'-- เลือก --'}
                              styles={
                                {
                                  control: (styles, state) => ({ ...styles,
                                    borderColor: state.isFocused? '#80bdff': '#ced4da',
                                    boxShadow: state.isFocused? '0 0 0 0.2rem rgba(0,123,255,.25)': null
                                  })
                                }
                              }
                              value={this.state.cTypeSelect}
                              options={this.state.cTypeData}
                              onChange={this.selectCType}
                            />
                          </Form.Group>
                        </Col> */}
                          <Col md={3} sm={4}>
                            <Form.Group controlId="">
                              <Form.Label>ประเภท</Form.Label>
                              <Select
                                // placeholder={'-- เลือก --'}
                                styles={
                                  {
                                    control: (styles, state) => ({
                                      ...styles,
                                      borderColor: state.isFocused ? '#80bdff' : '#ced4da',
                                      boxShadow: state.isFocused ? '0 0 0 0.2rem rgba(0,123,255,.25)' : null
                                    })
                                  }
                                }
                                value={this.state.cTypeSelect}
                                options={this.state.cTypeData}
                                onChange={this.selectCType}
                              />
                            </Form.Group>
                          </Col>
                          <Col md={3} sm={4}>
                            <Form.Group controlId="">
                              <Form.Label>ระดับชั้น</Form.Label>
                              <Select
                                // placeholder={'-- เลือก --'}
                                styles={
                                  {
                                    control: (styles, state) => ({
                                      ...styles,
                                      borderColor: state.isFocused ? '#80bdff' : '#ced4da',
                                      boxShadow: state.isFocused ? '0 0 0 0.2rem rgba(0,123,255,.25)' : null
                                    })
                                  }
                                }
                                value={this.state.cTypeSelect3}
                                options={this.state.cTypeData3}
                                onChange={this.selectCType3}
                              />
                            </Form.Group>
                          </Col>
                          <Col md={3} sm={4}>
                            <Form.Group controlId="">
                              <Form.Label>กลุ่มสาระการเรียนรู้</Form.Label>
                              <Select
                                // placeholder={'-- เลือก --'}
                                styles={
                                  {
                                    control: (styles, state) => ({
                                      ...styles,
                                      borderColor: state.isFocused ? '#80bdff' : '#ced4da',
                                      boxShadow: state.isFocused ? '0 0 0 0.2rem rgba(0,123,255,.25)' : null
                                    })
                                  }
                                }
                                value={this.state.cTypeSelect2}
                                options={this.state.cTypeData2}
                                onChange={this.selectCType2}
                              />
                            </Form.Group>
                          </Col>
                          <Col md={3} sm={4}>
                            <Form.Group>
                              <Form.Label>ชื่อเนื้อหา</Form.Label>
                              <Form.Control type="text" className="" placeholder="พิมพ์คำที่ต้องการค้นหา"
                                onChange={(e) => {
                                  let advSearch = this.state.advanceSearch;
                                  advSearch.nameMsg = e.target.value.trim();
                                  this.setState({ advSearch })
                                }
                                } />
                            </Form.Group>
                          </Col>
                          {/* <Col md={3} sm={4}>
                          <Form.Group>
                            <Form.Label>หัวเรื่อง</Form.Label>
                            <Form.Control type="text" className="" placeholder="พิมพ์คำที่ต้องการค้นหา"
                              onChange={(e) => {
                                let advSearch = this.state.advanceSearch;
                                advSearch.titleMsg = e.target.value.trim();
                                this.setState({ advSearch })}
                              }/>
                          </Form.Group>
                        </Col> */}
                          <Col md={3} sm={4}>
                            <Form.Group>
                              <Form.Label>ผู้จัดทำ</Form.Label>
                              <Form.Control type="text" className="" placeholder="พิมพ์คำที่ต้องการค้นหา"
                                onChange={(e) => {
                                  let advSearch = this.state.advanceSearch;
                                  advSearch.authorMsg = e.target.value.trim();
                                  this.setState({ advSearch })
                                }
                                } />
                            </Form.Group>
                          </Col>

                          <Col md={3} sm={4}>
                            <Form.Group>
                              <Form.Label>หน่วยงาน/สำนักพิมพ์</Form.Label>
                              <Form.Control type="text" className="" placeholder="พิมพ์คำที่ต้องการค้นหา"
                                onChange={(e) => {
                                  let advSearch = this.state.advanceSearch;
                                  advSearch.publisherMsg = e.target.value.trim();
                                  this.setState({ advSearch })
                                }
                                } />
                            </Form.Group>
                          </Col>
                          {/* <Col md={3} sm={4}>
                          <Form.Group>
                            <Form.Label>ISBN</Form.Label>
                            <Form.Control type="text" className="" placeholder="พิมพ์คำที่ต้องการค้นหา"
                              onChange={(e) => {
                                let advSearch = this.state.advanceSearch;
                                advSearch.isbnMsg = e.target.value.trim();
                                this.setState({ advSearch })}
                              }/>
                          </Form.Group>
                        </Col> */}
                          <Col md={3} sm={4}>
                            <Form.Group>
                              <Form.Label>คำค้น</Form.Label>
                              <Form.Control type="text" className="" placeholder="พิมพ์คำที่ต้องการค้นหา"
                                onChange={(e) => {
                                  let advSearch = this.state.advanceSearch;
                                  advSearch.keywordMsg = e.target.value.trim();
                                  this.setState({ advSearch })
                                }
                                } />
                            </Form.Group>
                          </Col>
                          {/* <Col md={3} sm={4}>
                          <Form.Group>
                            <Form.Label>รายละเอียด</Form.Label>
                            <Form.Control type="text" className="" placeholder="พิมพ์คำที่ต้องการค้นหา"
                              onChange={(e) => {
                                let advSearch = this.state.advanceSearch;
                                advSearch.descriptionMsg = e.target.value.trim();
                                this.setState({ advSearch })}
                              }/>
                          </Form.Group>
                        </Col> */}
                        </Row>
                      </Card.Body>
                    </Card>
                  </>}
                  <Row className="serach-btn-wrap">
                    <Col md={12}>
                      <Button type="submit" id="search-btn" className="float-sm-right pl-4 pr-4"
                        onSubmit={(e) => { e.preventDefault(); this.setData(false) }} onClick={(e) => { e.preventDefault(); this.setData(false) }}>ค้นหา</Button>
                    </Col>
                  </Row>

                </Card.Body>
              </Card>
            </Form>
          </Col>
        </Row>

        {(this.state.searched) ?
          <Row className="content search-result-header" id="searchResult">
            <div className="border-bottom search-result-header-inline">
              <h5 className="">ค้นพบ {this.state.pagination.totalItem} รายการ</h5>

              <div className="search-sort-block">

                <label sm="5" className="pr-2">เรียงตาม</label>

                <Select
                  styles={
                    {
                      control: (styles, state) => ({
                        ...styles,
                        borderColor: state.isFocused ? '#80bdff' : '#ced4da',
                        boxShadow: state.isFocused ? '0 0 0 0.2rem rgba(0,123,255,.25)' : null,
                        width: '145px'
                      }),
                      singleValue: () => ({
                        color: '#f58523',
                      })
                    }
                  }
                  value={this.state.sortBySelect}
                  options={[
                    { value: 1, label: 'ตัวอักษร' },
                    { value: 2, label: 'ดาวน์โหลด' },
                    { value: 3, label: 'ความนิยม' },
                    { value: 4, label: 'วันที่' }
                  ]}
                  onChange={this.selectSortBy}
                />
              </div>
            </div>

          </Row> : null
        }

        {(this.state.searchResult.length > 0) ?
          <Row className="content search-result pt-0">
            {(this.state.searchResult.length > 0) ? this.setResult() : null

            }
          </Row> : null
        }
        {(this.state.searchResult.length > 0) ?
          <div className="content pt-0 d-flex justify-content-end">
            <div className="pr-3">
              <Pagination page={this.state.pagination.page}
                totalPage={this.state.pagination.totalPage}
                range={2}
                pageNumber={(value) => this.clickPage(value)}
              />
            </div>
          </div>
          : ''
        }
      </>
    );
  }
}

export default Search;