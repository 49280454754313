import React from 'react';
import './register.css';
import { ButtonToolbar, Overlay, Popover, Image, Media, Badge } from 'react-bootstrap';
import axios from 'axios';
import { Link, Redirect } from 'react-router-dom';
import { translate } from 'react-switch-lang';

class Notice extends React.Component {
  
  constructor(props, context) {
    super(props, context);

    this.noticeClose = (e) => {
      if(this.state.show) {
        this.setState({ show: !this.state.show });
      }
    };

    this.openNotice = ({ target }) => {
      this.setState(s => ({ target, show: !s.show }));
    };

    this.enterHandler = (e) => {
      this.getNotifies();
    };
    
    this.state = {
      userToken: "",
      show: false,
      imgIcon: '',
      notifies: [],
      unread: 0
    };
  }

  async componentDidMount (){
    this.setState({
      iconLink: this.props.iconLink
    });

    await this.getNotifies();

    let items = await JSON.parse(localStorage.getItem('islogin'));
    if (items) {
      await this.setState({userToken:items.token});
    }
  }

  componentDidUpdate(prevProps){
    if (!this.props.islogin && this.props.islogin !== prevProps.islogin) {
      this.setState({ userToken: ""});
    }
  }

  checkUnread = () => {
    let i = 0;
    const flgRead = this.state.notifies.filter(item => {
      // if(item.flgRead === 'n'){ i+= 1; };
      return item.flgRead === 'n'
    })
    this.setState({unread: flgRead.length});
  }

  getNotifies =  () => {
    let items = JSON.parse(localStorage.getItem('islogin'));

    // console.log(items)

    const api = axios.create({
      baseURL: this.props.mainpoint,
      headers: {
        "Content-Type": "application/json",
        "token": (!items)? 1000000 : items.token
      },
      timeout: 85000
    });
    api.get(`/api/v1/notifies/page/0/limit/10`).then(
      success => {
        // if (process.env.REACT_APP_ENV_MODE === "production") console.log(success);
        const { status, data } = success
        // console.log(data);
        if (status === 201 || status === 200) {
          // if (process.env.REACT_APP_ENV_MODE === "production") console.log("Get Notifies", data.result);
          if (data.result.length > 0) {
            this.setState({
              notifies: data.result
            });
          } else {
            this.setState({
              notifies: []
            });

          }
          this.checkUnread();
        }
      },
      error => {
        localStorage.removeItem('islogin');
        if (process.env.REACT_APP_ENV_MODE === "development") console.log(error)
      }
    );
  }

  readable = (e, id) => {

    // const x = document.getElementsByClassName('truncate');
    // for (let i = 0; i < x.length; i++) {
    //   x[i].classList.remove('expand');
    // }
    // const y = document.getElementById('n_'+id).classList.add('expand');
    let { unread, notifies  } = this.state;
      notifies.forEach(item=>{
        if (item.notiId == id && item.flgRead == 'n') {
          item.flgRead = 'y';
          unread = unread - 1;
          this.setState({unread, notifies});
        }
      })
    const api = axios.create({
      baseURL: this.props.mainpoint,
      headers: {
        "Content-Type": "application/json",
        "token":this.state.userToken
      },
      timeout: 85000
    });
    api.get(`/api/v1/notifies/${id}/details`).then(
      success => {
        const { status, data } = success
        if (status === 201 || status === 200) {
          //if (process.env.REACT_APP_ENV_MODE === "development") console.log("Readed", data);
        }
      },
      error => {
        if (process.env.REACT_APP_ENV_MODE === "development") console.log(error)
      }
    );
  }

  setNotifies = () => {
    let notifies = [];
    this.state.notifies.forEach(item => {
      notifies.push(
        <Media key={item.notiType+item.notiId}>
            {(item.imgUrl)?
              <Image src={item.imgUrl} className="rounded-circle mr-3 border" style={{width:'3em', height: '3em', objectFit: 'cover'}}></Image>
              :
              <Image src={require("../img/test1.jpg")} className="rounded-circle mr-3 border" style={{width:'3em', height: '3em'}}></Image>
            }
            <Media.Body className="notify-body">
              <h6><strong>{item.name}</strong></h6>
              <Link  to={`/notify/${item.notiId}`} onClick={e=>this.noticeClose()} ><p style={{fontSize:'.9em', color: '#777777', marginBottom: '.7em'}} id={'n_'+item.notiId}
               dangerouslySetInnerHTML={{ __html: item.description }} className={`truncate`} onClick={e=>this.readable(e, item.notiId)}>
              </p>
              </Link>
              <p style={{fontSize:'.9em', color: '#333333', fontWeight: '600'}}>
                {item.createDateStr}
              </p>
            </Media.Body>
        </Media>);
    });
    return notifies;
  }

  renderLink = () => {
    if ( typeof this.state.iconLink == 'object') {
      if (this.state.unread > 0) {
        return (<img src={require("../img/noti_ac.png")}/>)
      } else {
        return this.state.iconLink;
      }
    } else {
      return this.state.iconLink;
    }
  }
    
    render() {
      const { t } = this.props;
      return (
        <ButtonToolbar>
          <a onClick={this.openNotice} data-toggle="popover" data-trigger="focus" id="nav-notify">
            {this.renderLink()}
            {(this.state.unread > 0)?
              <Badge variant="danger" pill className="notify-badge">{this.state.unread}</Badge>
              :
              null
            }
          </a>

          <Overlay
            show={this.state.show}
            target={this.state.target}
            placement="bottom"
            container={this}
            containerPadding={20}
            rootClose={true}
            rootCloseEvent="click"
            onEnter={this.enterHandler}
            onHide={this.noticeClose}>
              
            <Popover id="popover-contained" title={t("header.notification") }>
              <p className="d-flex justify-content-end">
                <Link  to="/notify" onClick={e=>this.noticeClose()} >{t("header.read_all") }</Link>
              
              </p>

            {
              (this.state.show)?
                this.setNotifies()
              :
              ''
            }

            </Popover>
          </Overlay>
        </ButtonToolbar>
      );
    }
  }

export default translate(Notice);